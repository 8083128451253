import React from "react";
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = (auth) => {
    //console.log('inside privateroute' + JSON.stringify(auth.auth))
    return (
        auth.auth ? <Outlet /> : <Navigate to="/login" />
    )
}

export default PrivateRoutes