import React, {useEffect, useState} from 'react';
import connect from "react-redux/es/components/connect";
import {actionToggleFooter, actionToggleNavBar, actionToggleSideBar} from "../store/action/layoutAction";
import { useAuthState } from "react-firebase-hooks/auth";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "./common/Firebase";

const Recovery = (props) => {
    const [email, setEmail] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        console.log(user);
        if (loading) return;
        if(error) alert(error);
        //if (!!user) navigate("/dashboard");
        props.actionToggleNavBar(false);
        props.actionToggleSideBar(false);
        props.actionToggleFooter(false);
    }, [user, loading]);
    return (
        <div className="user-ath-page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 text-center">
                        <div className="user-ath-logo">
                            <a href="#">
                                <img
                                    src={require('../assets/images/logo.PNG')}
                                    srcSet={require('../assets/images/logo.PNG')}
                                    alt="icon"
                                />
                            </a>
                        </div>
                        <div className="user-ath-box">
                            <h4>Request Your Password</h4>
                            <form action="#" className="user-ath-form">
                                {/*
                      <div class="note note-lg note-no-icon note-danger">
                          <p>There is no account with this email.</p>
                      </div>
                      <div class="note note-lg note-no-icon note-success">
                          <p>Password recovery instruction sent to your email, Please check.</p>
                      </div>
*/}
                                <div className="input-item">
                                    <input
                                        type="text"
                                        placeholder="Your Email"
                                        className="input-bordered"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="gaps"/>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Button variant="contained" size='large' onClick={() => sendPasswordReset(email)} className="btn btn-primary">Reset</Button>
                                    <span>
                {" "}
                                        <a href="/login" className="simple-link">
                  <em className="ti ti-arrow-right"/> Login Here
                </a>
              </span>
                                </div>
                            </form>
                        </div>
                        <div className="gaps-2x"/>
                        <div className="form-note">
                            {" "}
                            Not a member? <a href="/signup">Sign up now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};


const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
    };
};
export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter
})(Recovery);
