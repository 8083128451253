import React, { Fragment, useEffect, useState } from 'react';
import connect from "react-redux/es/components/connect";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar, actionChangeLanguage } from "../store/action/layoutAction";
import { TextField, Checkbox, FormGroup, FormControlLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import Button from '@mui/material/Button';
import translate from "../i18n/translate";
import { LOCALES } from '../i18n/constants';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, registerWithEmailAndPassword } from "./common/Firebase";
//import { alignProperty } from '@mui/material/styles/cssUtils';
//import QRCode from 'qrcode';

const Signup = (props) => {
    const getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return ("");
    };
    const [language, setLanguage] = useState("");
    const [email, setEmail] = useState("");
    const [coupon, setCoupon] = useState(getQueryVariable("coupon"));
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [name, setName] = useState("");
    //const [qr, setQR] = useState("");
    //const [url, setURL] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const [checked, setChecked] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const register = () => {
        const regexPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        const regexEmail = /^[a-z0-9_.@]+$/i
        let error_InvalidName = "Please enter name.";
        let error_InvalidEMail = "Please enter valid email.";
        let error_InvalidPassword = "Password should be minimum 8 letters, and include lower case letter, upper case letter, number, and special characters.";
        let error_passwordMismatch = "Password and Repeat password does not match.";
        let error_AgreeTerms = "Please check terms and conditions.";
        if (language === LOCALES.JAPANESE) {
            error_InvalidName = "名前を入力してください。";
            error_InvalidEMail = "有効なEメールを入力してください。";
            error_InvalidPassword = "パスワードは 8 文字以上で、小文字、大文字、数字、特殊文字を含める必要があります。";
            error_passwordMismatch = "パスワードと確認用パスワードが一致しません。";
            error_AgreeTerms = "規約とポリシーを選択してください。";
        }

        if (!name) {
            alert(error_InvalidName);
            return;
        }        
        if (email === '' || !regexEmail.test(email)) {
            alert(error_InvalidEMail);
            return;
        }
        if (password === '' || !regexPassword.test(password)) {
            alert(error_InvalidPassword);
            return;
        }
        if (password !== repeatPassword) {
            alert(error_passwordMismatch);
            return;
        }        
        if (!checked) {
            alert(error_AgreeTerms);
            return;
        }

        setIsButtonClicked(true);
        registerWithEmailAndPassword(name, email, password, coupon).then(async res => {
            if (res === "Success") {
                setIsSuccess(true);
            } else {
                setIsButtonClicked(false);
            }
            /*if (url && url.length) {
                setURL(url);
                let qr = await QRCode.toDataURL(url);
                setQR(qr);
            }*/
        });
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const onChangeLanguage = (event) => {
        props.actionChangeLanguage(event.target.value);
        setLanguage(event.target.value);
    };

    useEffect(() => {
        let sLanguage = localStorage.getItem("locale");
        if (!sLanguage) {
            sLanguage = LOCALES.ENGLISH;
        }
        setLanguage(sLanguage);
    }, []);

    useEffect(() => {
        if (loading) return;
        if (error) alert(error);
        /*if (user && qr) {
            console.log(qr);
            navigate("/");
        }*/
        props.actionToggleNavBar(false);
        props.actionToggleSideBar(false);
        props.actionToggleFooter(false);
    }, [user, loading, isSuccess]);

    return (
        <div className="user-ath-page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 text-center">
                        <div className="user-ath-logo">
                            <a href="/">
                                <img
                                    src={require('../assets/images/logo.PNG')}
                                    srcSet={require('../assets/images/logo.PNG')}
                                    alt="icon"
                                />
                            </a>
                        </div>
                        {isSuccess
                            ?
                            <div className="form-note">
                                {translate('SignUpSuccess-Message1')}
                                <div className="form-note">
                                    {" "}
                                    {translate('SignUpSuccess-Message2')}
                                </div>
                                <br />
                                <a href="/login"><Button variant="contained" size='large'>{translate('Login')}</Button></a>
                            </div>
                            :
                            <div className="user-ath-box">
                                <h2>{translate('Signup-Title')}</h2>
                                <form action="signup-success" className="user-ath-form">
                                    <div className="input-item">
                                        <Select id="languageType" labelId="languageType" value={language} onChange={onChangeLanguage} className="input-item-label" input={<OutlinedInput label={translate('Language')} />}                                    >
                                            <MenuItem value={LOCALES.ENGLISH}>English</MenuItem>
                                            <MenuItem value={LOCALES.JAPANESE}>日本語</MenuItem>
                                        </Select>
                                    </div>

                                    <div className="input-item">
                                        <TextField id="name" label={translate('Name')} className='input-bordered' value={name}
                                            onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="input-item">
                                        <TextField id="email" label={translate('EmailAddress')} className='input-bordered' value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="input-item">
                                        <TextField id="password" type={'password'} label={translate('Password')} className='input-bordered' value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <div className="note note-plane note-info">
                                        <em className="fas fa-info-circle" />
                                        <p>
                                            {translate('password-eligibility-message')}
                                        </p>
                                    </div>
                                    <div className="input-item">
                                        <TextField id="repeat-password" type={'password'} label={translate('RepeatPassword')} className='input-bordered' value={repeatPassword}
                                            onChange={(e) => setRepeatPassword(e.target.value)} />
                                    </div>
                                    <div className="input-item">
                                        <TextField id="coupon" label={translate('PromotionalCode')} className='input-bordered' value={coupon}
                                            onChange={(e) => setCoupon(e.target.value)} />
                                    </div>
                                    <div className="input-item text-start">
                                        <FormGroup>
                                            {language === LOCALES.JAPANESE ?
                                                <FormControlLabel control={<Checkbox className='input-checkbox' onChange={handleChange} />} label={<Fragment><a href="/">規約とポリシー</a>に同意します</Fragment>} />
                                                :
                                                <FormControlLabel control={<Checkbox className='input-checkbox' onChange={handleChange} />} label={<Fragment>I agree to <a href="/">Terms and Policy.</a></Fragment>} />
                                            }
                                        </FormGroup>
                                    </div>

                                    {(isButtonClicked && !isSuccess) &&
                                        <>
                                            <div className="gaps-1x" />
                                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                                        </>
                                    }
                                    <div className="gaps" />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Button variant="contained" size='large' disabled={isButtonClicked ? true : false} onClick={register}>{translate('SignUp')}</Button>
                                    </div>
                                </form>
                                <div className="or-saparator">
                                    <span>or</span>
                                </div>
                                <div className="form-note">
                                    {" "}
                                    {translate('AlreadyMember')} <a href="/login">{translate('Login')}</a>
                                </div>
                            </div>}
                        <div className="gaps-2x" />

                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
    actionChangeLanguage
})(Signup);
