import React, { useEffect, useState } from 'react';
import connect from "react-redux/es/components/connect";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { auth, logInWithEmailAndPassword, getUserDetails } from "./common/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import translate from "../i18n/translate";

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    //const [OTP, setOTP] = useState("");
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const validate = async () => {
        if (!email) {
            alert('Please enter your email');
            return;
        }
        if (!password) {
            alert('Please enter your password');
            return;
        }
        if (user === null || user?.emailVerified) {
            setIsButtonClicked(true);
            const isValid = await logInWithEmailAndPassword(email, password);
            if (isValid) {
                navigate("/");
            } else {
                setIsButtonClicked(false);
            }
        }
    }

    useEffect(() => {
        //console.log("useAuthState: "+JSON.stringify(user));
        if (user !== null && !user?.emailVerified) {
            alert('Kindly verify your email to login');
        }
        if (loading) {
            // maybe trigger a loading screen
            return;
        }
        if (error) {
            alert(error);
        }
        //console.log("Retrieve UID: " + localStorage.getItem("uid"));
        let uid = localStorage.getItem("uid");
        if (user && uid) {
            getUserDetails(user.uid).then((user) => {
                navigate("/");
            });
        }
        //("/");
        props.actionToggleNavBar(false);
        props.actionToggleSideBar(false);
        props.actionToggleFooter(false);
    }, [user, loading, email, error]);

    return (
        <div className="user-ath-page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8  text-center">
                        <div className="user-ath-logo">
                            <a href="#">
                                <img
                                    src={require('../assets/images/logo.PNG')}
                                    srcSet={require('../assets/images/logo.PNG')}
                                    alt="icon"
                                />
                            </a>
                        </div>
                        <div className="user-ath-box">
                            <div className="input-item">
                                <TextField id="email" label={translate('Email-Address')} className='input-bordered' onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="input-item">
                                <TextField id="password" type={'password'} label={translate('Password')} className='input-bordered' onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            {isButtonClicked &&
                                <>
                                    <div className="gaps" />
                                    <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                                </>
                            }
                            <div className="gaps" />
                            <div className="d-flex justify-content-between align-items-center">
                                <Button variant="contained" size='large' disabled={isButtonClicked ? true : false} onClick={() => validate()}>
                                    {(navigator?.userLanguage === "ja") ? 'ログイン' : 'Log In'}</Button>
                                <a href="/recovery" className="simple-link">
                                    {(navigator?.userLanguage === "ja") ? 'パスワードを忘れた場合' : 'Reset Password'}
                                </a>
                            </div>

                            <div className="or-saparator">
                                <span>or</span>
                            </div>
                            <div className="form-note">
                                {" "}
                                {(navigator?.userLanguage === "ja") ? '新規登録は' : 'New Member?'} <a href="/signup">{(navigator?.userLanguage === "ja") ? 'こちら' : 'Sign Up'}</a>
                            </div>
                        </div>
                        <div className="gaps-2x" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
    };
};
export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter
})(Login);
