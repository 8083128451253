import React, { useEffect, useState } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { client } from "../store/action/remoteAction";
import { getMultiLanguageMessage } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";
import { LOCALES } from '../i18n/constants';

let SERVER_URL = process.env.REACT_APP_SERVER_URL;
//let CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

const CreditCardPayment = (props) => {
    let uid = localStorage.getItem("uid");
    const [language, setLanguage] = useState("");
    const [bodyJSON, setBodyJSON] = useState("");
    const [otcFlag, setOtcFlag] = useState(0);
    const [submitted, setSubmitted] = useState(0);
    const [data, setData] = useState({
        'UserID': uid,
        'CardNo': '',
        'CreditCardCVV': '',
        'ExpiryMonth': '2025-03',
        'CardHolderName': '',
        'Amount': 0,
        'ProcessStatus': 'Created',
        'UpdatedByUser': uid
    });

    const submitCreditCardPayment = async () => {
        resetTimout();
        if (data.CardNo === "") {
            if (language === LOCALES.JAPANESE) {
                alert("クレジットカード番号を入力してください。");
            } else {
                alert("Please enter credit card no");
            }
            return;
        }
        if (data.CreditCardCVV === "") {
            if (language === LOCALES.JAPANESE) {
                alert("CVVコードを入力してください。");
            } else {
                alert("Please enter CVV Code");
            }
            return;
        }
        if (data.ExpiryMonth === "") {
            if (language === LOCALES.JAPANESE) {
                alert("カードの有効期限月を選択してください。");
            } else {
                alert("Please select card expiry month");
            }
            return;
        }
        if (data.CardHolderName === "") {
            if (language === LOCALES.JAPANESE) {
                alert("クレジットカード名義人名を入力してください。");
            } else {
                alert("Please enter credit card holder name");
            }
            return;
        }
        if (data.Amount <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            let serverUrl = SERVER_URL + `/demo-buytoken-creditcard`;
            if (otcFlag === 1) {
                serverUrl = SERVER_URL + `/demo-otcbuy-creditcard`;
            }

            let bodyObject = JSON.parse(bodyJSON);
            bodyObject.items.CardNo = data.CardNo;
            bodyObject.items.CreditCardCVV = data.CreditCardCVV;
            bodyObject.items.ExpiryMonth = data.ExpiryMonth;
            bodyObject.items.CardHolderName = data.CardHolderName;

            fetch(serverUrl, {
                method: 'POST',
                redirect: 'follow',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(bodyObject),
            })
                .then(res => {
                    if (res.ok) return res.json()
                    return res.json().then(json => Promise.reject(json))
                })
                .then((body) => {
                    window.location.href = body.url
                })
                .catch((error) => {
                    let errorMessage = "Error: ";
                    if (error && error.message) {
                        errorMessage += error.message;
                    } else {
                        errorMessage += JSON.stringify(error)
                    }
                    alert(errorMessage);
                });
        }
    }

    const handleChange = (e) => {
        resetTimout();
        switch (e.target.id) {
            case "card-no": setData({ ...data, ['CardNo']: e.target.value }); break;
            case "secret-code": setData({ ...data, ['CreditCardCVV']: e.target.value }); break;
            case "card-holder-name": setData({ ...data, ['CardHolderName']: e.target.value }); break;
        }
    };

    const onChangeExpiryMonth = (selectedDate) => {
        resetTimout();
        if (selectedDate && selectedDate != "Invalid Date") {
            let dtDate = new Date(selectedDate);
            let month = (dtDate.getMonth() < 9) ? ('0' + (dtDate.getMonth() + 1).toString()) : (dtDate.getMonth() + 1).toString();
            let expiryMonth = dtDate.getFullYear().toString() + '-' + month;
            setData({ ...data, ['ExpiryMonth']: expiryMonth });
        } else {
            setData({ ...data, ['ExpiryMonth']: '' });
        }
    };

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        let sBodyJSON = localStorage.getItem("aos-creditdemo");
        if (sBodyJSON) {
            setBodyJSON(sBodyJSON);
            let bodyObject = JSON.parse(sBodyJSON);
            let totalAmount = parseFloat(bodyObject.items.amount) + (bodyObject.items.nextTrancheAmount ? parseFloat(bodyObject.items.nextTrancheAmount) : 0);
            let cardHolderName = localStorage.getItem("aos-cardholdername");
            setData({ ...data, ['Amount']: totalAmount, ['CardHolderName']: cardHolderName });
            setOtcFlag(bodyObject.items.otcflag);
            localStorage.removeItem("aos-creditdemo");
        }
    }, []);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('CreditCardPayment')}</h2>
            <div className="gaps-2x" />
            <div className="row">
                {/* Card No */}
                <div className="col-md-6">
                    <div className="input-item input-with-label">
                        <TextField id="card-no" value={data?.CardNo} onChange={handleChange} defaultValue={data?.CardNo} label={translate('CardNo')} className='input-bordered' />
                    </div>
                </div>
                {/* Secret Code */}
                <div className="col-md-6">
                    <div className="input-item input-with-label">
                        <TextField id="secret-code" value={data?.CreditCardCVV} onChange={handleChange} defaultValue={data?.CreditCardCVV} label={translate('CardSecretCode')} className='input-bordered' />
                    </div>
                </div>
                {/* Expiry Year/Month */}
                <div className="col-md-6">
                    <div className="input-item input-with-label">
                        <DatePicker
                            views={['year', 'month']}
                            openTo='month'
                            inputFormat='yyyy-MM'
                            label={translate('ExpiryMonth')}
                            minDate={new Date('2024-01-01')}
                            maxDate={new Date('2030-12-31')}
                            value={data?.ExpiryMonth}
                            onChange={onChangeExpiryMonth}
                            renderInput={(params) => <TextField {...params} helperText={null} className='input-bordered' />}
                        />
                    </div>
                </div>
                {/* Card Holder Name */}
                <div className="col-md-6">
                    <div className="input-item input-with-label">
                        <TextField id="card-holder-name" value={data?.CardHolderName} onChange={handleChange} defaultValue={data?.CardHolderName} label={translate('CardHolderName')} className='input-bordered' />
                    </div>
                </div>
                {/* Amount */}
                <div className="col-md-6">
                    <div className="input-item input-with-label">
                        <TextField id="amount" disabled={true} value={data?.Amount} onChange={handleChange} label={translate('AMOUNT')} className='input-bordered' />
                    </div>
                </div>
            </div>

            {/* Test Cards */}
            <div className="gaps-1x" />
            <div className="d-sm-flex justify-content-between align-items-center">
                {translate('CreditCard-DemoMessage')}
            </div>
            <div className="gaps-1x" />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className="data-table tranx-table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" component="th">{translate('CardNo')}</TableCell>
                            <TableCell align="center" component="th">{translate('CardSecretCode')}</TableCell>
                            <TableCell align="center" component="th">{translate('ExpiryMonth')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align='center'>4444-3333-2222-1001</TableCell>
                            <TableCell align='center'>111</TableCell>
                            <TableCell align='center'>2025-03</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align='center'>4444-3333-2222-1002</TableCell>
                            <TableCell align='center'>222</TableCell>
                            <TableCell align='center'>2025-03</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align='center'>4444-3333-2222-1003</TableCell>
                            <TableCell align='center'>333</TableCell>
                            <TableCell align='center'>2025-03</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <div className="gaps-2x" />
            <div className="d-sm-flex justify-content-between align-items-center">
                <button className="btn btn-primary" onClick={submitCreditCardPayment}>{translate('Submit')}</button>
            </div>

        </div >
    );
};

export default CreditCardPayment;
