import React, { useState }  from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const ConfirmDialog = (dialogTitle, confirmMessage, okButtonLabel, cancelButtonLabel) => {
    const [promise, setPromise] = useState(null);

    const confirm = () => new Promise((resolve, reject) => {
        setPromise({ resolve });
    });

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = () => {
        if (promise) {
            promise.resolve(true);
        }
        handleClose();
    };

    const handleCancel = () => {
        if (promise) {
            promise.resolve(false);
        }
        handleClose();
    };

    const ConfirmationDialog = () => (
        <Dialog open={promise !== null} fullWidth>
            <DialogContent>
                <DialogContentText><b>{confirmMessage}</b></DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} variant="outlined" color="error">{cancelButtonLabel}</Button>
                <Button onClick={handleConfirm} variant="outlined" color="success">{okButtonLabel}</Button>                
            </DialogActions>
        </Dialog>
    );
    return [ConfirmationDialog, confirm];
};

export default ConfirmDialog;