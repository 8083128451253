import { LOCALES } from '../constants';

export default {
    [LOCALES.ENGLISH]: {
        //Menu
        'Menu-Top': 'Top',
        'Menu-BuyTokens': 'Buy Tokens',
        'Menu-IVAFO': 'IVAFO',
        'Menu-OTCBuy-Open': 'OTC Buy',
        'Menu-OTCBuy-Settlement': 'OTC Buy',
        'Menu-OTCSell-Open': 'OTC Sell',
        'Menu-OTCSell-Settlement': 'OTC Sell',
        'Menu-OTCList': 'OTC List',
        'Menu-Transactions': 'Transactions',
        'Menu-DepositWithdraw': 'Deposit/Withdraw',
        'Menu-Deposit': 'Deposit',
        'Menu-Withdraw': 'Withdraw',
        'Menu-Statement': 'Statement',
        'Menu-Account': 'Account',
        'Menu-KYCApplication': 'KYC Application',
        'Menu-AccountDetail': 'Account Details',
        'Menu-WithdrawBankAccount': 'Withdraw Bank Account',
        'Menu-ChangePassword': 'Change Password',
        'Menu-Notification': 'Notification',
        'Menu-WalletAddress': 'Wallet Address',
        'Menu-ReferralCode': 'Referral Code',
        'Menu-Executions': 'Executions',
        'Menu-BitCoinApplication': 'Bitcoin Wallet Application',
        'Menu-Bitcoin-Transfer': 'Bitcoin Transfer',
        'Title-Deposit': 'Deposit Account',
        'Title-WithdrawAccount': 'Withdrawal Bank Account',
        'Title-PromoterApplication': 'Progress Associator Application Form',
        'Title-PromoterCoupon': 'Register Referral Code',
        'Title-SellTokens-Settlement': 'OTC List',

        //Signup
        'Signup-Title': 'Create New Account',
        'Name': 'Your Name',
        'EmailAddress': 'Your Email',
        'Password': 'Password',
        'RepeatPassword': 'Repeat Password',
        'PromotionalCode': 'Promotional Code',
        'SignUp': 'SignUp',
        'Login': 'Login',
        'AlreadyMember': 'Already a member?',
        'SignUpSuccess-Message1': 'Thank you for your application.',
        'SignUpSuccess-Message2': 'We have sent the URL for account registration to the email address you input. Please click on the registration URL to validate your email address.',

        //home.js
        'Current-Transaction-Price': 'CURRENT TRANSACTION PRICE',
        'The-Day-Before': 'THE DAY BEFORE',
        'Last-Value-At': 'LAST VALUE AT',
        'AOS-Token-Balance': 'AOS TOKEN BALANCE',
        'BTC-Token-Balance': 'BITCOIN TOKEN BALANCE',
        'bids-list': 'Bids List',
        'OtcOrder-List': 'OTC Order List',
        'Go-to-main-site': 'Go to main site',
        'Your-Contribution': 'Your Contribution',
        'Confirm-Email': 'Confirm Email',
        'KYC-Pending': 'KYC Pending',
        'KYC-Success': 'KYC Success',
        'KYC-Application': 'KYC Application',
        'Contributions': 'Buy Tokens',
        'Contributions-Sell': 'Sell Tokens',
        'OTC-Sell': 'Sell OTC',
        'Transactions': 'Transactions',
        'Account': 'Account',
        'Security': 'Security',
        'Referral': 'Referral',
        'Account-Security': 'Account Security',
        'How-to-Buy?': 'How to Buy?',
        'FAQs': 'FAQs',
        'Whitepaper': 'Whitepaper',
        'Contact-support': 'Contact support',
        'START-DATE': 'START DATE',
        'END-DATE': 'END DATE',
        'max-aos': 'Max AOS\'s Alloted',
        'AOS-Crypto-Projects-message': 'Thank you for your interest towards to our AOS Crypto Projects',
        'Contributions-section-message': 'You can purchase AOS tokens from ',
        'Contributions-section-message-1': '"Buy Tokens" ',
        'Contributions-section-message-2': 'section.',
        'details-message': 'You can get a quick response to any ',
        'details-message-1': 'questions',
        'marketing-message': ' ',
        'THE-AOS-ICO-ENDS-IN': 'THE AOS ICO ENDS IN',
        'Token-Sale-Progress': 'Token Sale Progress',
        'Bids': 'OTC List',
        'Balance': 'Balance',
        'My-Profile': 'My Profile',
        'activity': 'activity',
        'Logout': 'Logout',

        //kyc.js
        'Identify-Verification-KYC': 'Identify Verification - KYC',
        'Identify-Verification-KYC-description': 'To comply with regulation each participant will have to go through indentity verification (KYC). So please complete our fast and secure verification process to participate in our token sale. You can proceed from here to verify your indentity and also you can check your application status if you submit already.',
        'Not-submitted-message': 'You have not submitted your KYC Application',
        'CLICK-TO-PROCEED': 'CLICK TO PROCEED',
        'footer-message': 'Some of contries and regions will not able to pass KYC process and therefore are restricted from token sale.',

        //KycApplication.js
        'Kyc-PersonalDetails-Edit': 'Edit Account Details',
        'Kyc-PersonalDetails': 'Personal Data',
        'UserID': 'User ID',
        'First-Name': 'First Name',
        'Last-Name': 'Last Name',
        'Kanji-First-Name': 'Kanji First Name',
        'Kanji-Last-Name': 'Kanji Last Name',
        'Phone-Number': 'Phone Number',
        'Bank-Account': 'Bank Account',
        'Bank-Name': 'Bank Name',
        'Date-of-birth': 'Date of birth',
        'Nationality': 'Nationality',
        'Address-Line-1': 'Address(Prefecture, City, Ward)',
        'Address-Line-2': 'Address(Street, building, room)',
        'Kanji-Address-Line-1': 'Address in Kanji(optional if outside Japan)',
        'Kanji-Address-Line-2': 'Address in Kanji(optional if outside Japan)',
        'Zip-Code': 'Zip Code',
        'country-of-residence': 'Country of Residence',
        'Kyc-VerificationID-Edit': 'Edit Indentity Document',
        'documents-details': 'Please upload any of the following personal document.',
        'Passport': 'Passport',
        'Passport-Back': 'Passport Back',
        'National-Card': 'National Card',
        'National-Card-Back': 'National Card Back',
        'Drivers-License': 'Driver\'s License',
        'Drivers-License-Back': 'Driver\'s License Back',
        'Photo-1': 'Applicant Photo',
        'Photo-2': 'Profile Photo',
        'Photo-message': 'Please upload applicant photo (facing) AND profile photo (from diagonary front).',
        'documents-details-1': 'To avoid delays when verifying account, Please make sure bellow:',
        'documents-details-2': 'Chosen credential must not be expired.',
        'documents-details-3': 'Document should be good condition and clearly visible.',
        'documents-details-4': 'Make sure that there is no light glare on the card.',
        'documents-details-5': 'Upload your National id Front Side',
        'documents-details-6': 'Upload your National id Back Side',
        'documents-details-7': 'Please upload your pictures.  One from front, and another from diagonally forward. Both should be within 6 months, no hat/cap, no background, and no shadow of glasses or hair around your eyes.',
        'submit-details': 'Submit Details',
        'Change-Request': 'Change Requet',
        'Change-Request-Message1': 'After changing your personal details, you will need to go through the identity verification process again.',
        'Change-Request-Message2': 'Please note that all transaction functions will be suspended until the review is completed.',
        'Agree-Message': 'I agree to the above.',

        //Buttons
        'kyc-button-next': 'Next',
        'kyc-button-prev': 'Prev',
        'kyc-button-revise': 'Revise',
        'button-All': 'All',
        'button-Clear': 'Clear',
        'button-AddAccount': 'Add Account',
        'button-DepositRequest': 'Deposit',
        'button-Deposit': 'Deposit',
        'button-Cancel': 'Cancel',
        'button-Apply': 'Apply',
        'button-Register': 'Register',
        'button-Ok': 'Ok',

        //transactions.js
        'TransactionNo': 'Transaction No',
        'TransactionStatus': 'Status',
        'Buy-Sell': 'Buy/Sell',
        'Tokens': 'Tokens',
        'UnitPrice': 'Rate',
        'Amount': 'Amount',
        'Transaction-Buy': 'Buy',
        'Transaction-Sell': 'Sell',
        'Transaction-Gift': 'Gift',
        'Transaction-Bonus': 'Bonus',
        'From': 'From',
        'To': 'To',
        'FROM-bids': 'From',
        'Txn-Completed': 'Txn Completed',
        'Txn-Pending': 'Txn Pending',
        'Txn-Progress': 'Txn Progress',
        'Txn-Cancelled': 'Txn Cancelled',
        'Txn-Declined': 'Txn Declined',
        'Txn-Expired': 'Txn Expired',
        'Txn-Error': 'Txn Error',
        'Quantity': 'Quantity',
        'buy-quantity': 'Quantity',
        'buy-price': 'price',
        'Buy-Order': 'Buy Order',
        'Sell-Order': 'Sell Order',
        'Buy': 'Buy',
        'Sell': 'Sell',
        'DateTime': 'Date(MLT)',
        'Date': 'Date',
        'TransferNote': 'Note',
        'Euro': 'EUR',
        'Bitcoin': 'BTC',
        'Euro-Aos': 'EUR/AOS',
        'Bitcoin-Aos': 'BTC/AOS',
        'Sell-Amount': 'Amount',
        'Sell-Quantity': 'Tokens',
        'Commission-Amount': 'Transaction Fee',
        'Commission': 'Commission',
        'GasFee': 'Gas Fee',
        'Currency-Euro': 'Euro',
        'Currency-Bitcoin': 'Bitcoin',
        'Commission-Payment-Type': 'Transaction Fee Payment Method',

        //Account.js
        'Account-Information': 'Account Information',
        'Confirm-message': 'Confirm your email adddress',
        'Personal-Data': 'Personal Data',
        'Full-Name': 'Full Name',
        'Surname': 'Surname',
        'Email-Address': 'Email Address',
        'Mobile-Number': 'Mobile Number',
        'Date-of-Birth': 'Date of Birth',
        'Resend-Email': 'Resend Email',
        'Update': 'Update',
        'Copy-to-clipboard': 'Copy',
        'Wallet-Address': 'Wallet Address',
        'Notification': 'Notification',
        'notification-header': 'You can manage notification from here.',
        'Notify-latest': 'Notify me by email for latest news',
        'notification-footer-note': 'Security related notification will be sent to you regardless of the above setting.',
        'wallet-copied-message': 'Wallet address copied to clipboard',
        'update-notification-settings': 'Update Notification Settings',
        'wallet-address': 'AOS Wallet address',
        'wallet-address-message': 'This is your wallet address to AOS Tokens.',
        'bitcoin-wallet-address': 'Bitcoin Wallet address',
        'bitcoin-wallet-address-message': 'This is your wallet address to Bitcoin.',
        'coupon-address': 'Referral URL',
        'coupon-code': 'Referral Code',
        'coupon-code-copied-message': 'Referral Code copied to clipboard',
        'coupon-address-copied-message': 'Referral URL copied to clipboard',
        'wallet-message': 'In order to receive your ICOX Tokens, please select your wallet addres and you have to put the address below input box. You will receive ICOX tokens to this address after the Token Sale end.',
        'wallet-warning': 'DO NOT USE your exchange wallet address such as Kraken, Bitfinex, Bithumb, Binance etc. You can use MetaMask, MayEtherWallet, Mist wallets etc. Do not use the address if you don’t have a private key of the your address. You WILL NOT receive ICOX Tokens and WILL LOSE YOUR FUNDS if you do.',
        'passport-expirydate': 'Passport Date',
        'national-expirydate': 'National Date',
        'license-expirydate': 'License Date',
        'passport-Number': 'Passport Number',
        'national-Number': 'National Number',
        'license-Number': 'License Number',
        'referralcode-message': 'Share the referral code or referral URL with the person you are referring.',
        'referralcode-title': '[Notes on the Referral Programme]',
        'referralcode-details': '...To Be Determined...',
        'referralbonus-title': '[Notes on the Referral Bonus]',
        'referralbonus-details': '...To Be Determined...',

        //HowTo.js
        'How-to-Buy-token': 'How to Buy token',
        'how-to-step-1': 'Step 1 : First, please complete the KYC process via providing personal information through “KYC Application” screen.',
        'how-to-step-2': 'Step 2 : When KYC process is completed on our side, Status button (Button of the left top of PC screen. Status button displayed via right top menu for Android) will change from “KYC Pending” to “KYC Completed”. It will normally take 60 days.',
        'how-to-step-3': 'Step 3 : As soon as KYC Process is completed, we will notify you via e-mail, to your registered e-mail address. When KYC Process is completed, details of the bank remittance will be displayed on the “Contribution” screen.',
        'how-to-step-4': 'Step 4 : Please input approximate AOS quantity you wish to contribute. Approximate EUR equivalent amount will be calculated. Exact amount will not reach to the recipient account due to foreign exchange, and the bank charges of remitting bank, intermediary bank, beneficiary bank etc.. Hence, please note that AOS Quantity displayed is just an estimate. Actual AOS contribution will be the equivalent to EUR fund actually credited to the account of Gatsby Malta.',
        'how-to-step-5': 'Step 5 : Please remit the displayed EUR amount via the foreign remittance transaction from your bank. Please follow the format of your bank for the debiting account etc. After submitting the foreign remittance application to your bank, please upload the “Applicant’s copy” returned from your bank, in PDF or image file into the dedicated box in the “contribution” screen.',
        'how-to-step-6': 'Step 6 : If you prefer payment via credit card, please press “Purchase Tokens via Credit Cart” button, and you will be directed to Credit Card Settlement screen.',
        'how-to-step-7': 'Step 7 : We will conduct contribution process as soon as the fund reaches to us. Contribution progress can be checked via “Dashboard” screen. We will notify you when contribution process is completed.',

        //SecuritySettings
        'password-eligibility-message': 'Password should be minimum 8 letter, and include lower case letter, upper case letter, number, and special characters.',
        'settings-message': 'You can change your password to protect your account.',
        'Change-Password': 'Change Password',
        'Old-Password': 'Old Password',
        'New-Password': 'New Password',
        'Confirm-Password': 'Confirm Password',
        'Password': 'Password',
        'OTP': 'OTP',

        //token
        'howtomessage': 'Please remit the displayed EUR amount via the foreign remittance transaction from your bank. Please fill the application for foreign remittance as per below.',
        'token-line-1': 'Applicant’s name:',
        'token-line-2': 'Applicant’s address:',
        'token-line-3': 'Beneficiary’s name: Gatsby Malta, Ltd.',
        'token-line-4': 'Beneficiary’s address: Piketree Business Centre, Suite 3, Industry Street, Zone 5,',
        'token-line-5': 'Central Business District, Santa Vanera CBD 5030, Malta',
        'token-line-6': 'Beneficiary’s A/c Nbr and IBAN: XXXXXXXX',
        'token-line-7': 'A/C with Bank and branch: XXX Bank xxx branch',
        'token-line-8': 'A/C with Bank address: Address XXXX',
        'token-line-9': 'A/C with Bank SWIFT Code: XXXXXXXX',
        'token-line-10': 'Purpose of remittance: ICO contribution of Crypto-asset',
        'token-line-11': 'Currency; EUR',
        'token-line-12': 'Amount:',
        'token-line-13': 'Message to beneficiary: ＜Auto generated reference number＞',
        'token-line-14': 'Paying bank charge: Beneficiary',
        'Buy-Tokens': 'Buy Tokens',
        'Sell-Tokens': 'Sell Tokens',
        'Gift-Tokens': 'Send Tokens',
        'token-step-01': '01. Select the payment method and calculate token price',
        'token-step-02': '02. Set amount of AOS tokens you would like to purchase',
        'token-step-02-sell': '02. Set amount of AOS tokens you would like to Sell',
        'token-step-02-desc': 'To become a part of the AOS Crypto project and purchase of AOS token will only be possible after payment made and receving an approval. As you like to participate our project, please select payment method and enter the amount of AOS tokens you wish to purchase. You can buy AOS tokens using EURO.',
        'token-step-02-desc-1': 'The calculator helps you to convert required currency to AOS tokens.',
        'token-step-03': '03. Select payment type.',
        'Gift': 'Gift',
        'Tokens-to-Purchase': 'Tokens to Purchase',
        'Order-Quantity': 'Number of Order',
        'Payment-Amount': 'Payment Amount',
        'Select-Wallet': 'Select Wallet',
        'Enter-Address': 'Enter address for gifting',
        'Enter-Bitcoin-Address': 'Enter Bitcoin Address',
        'Payment-Qty': 'Payment Qty',
        'insufficient-aos-balance': 'Requested AOS tokens are more than the remaining AOS tokens available in current IVFAO. Only AOS tokens remaining on this IVFAO will be granted immediately rest will be granted after the next AOS token IVFAO.',
        'Partial-Order': 'Partial Order',
        'public-purchase-price': 'Public Purchase Price',
        'To-Confirm-Page': 'Confirm',
        'Rate': 'Rate',
        'Payment-Type': 'Payment Method',
        'WalletAddress': 'Wallet Address',
        'Back-Btn': 'Back',
        'SenderAccount': 'Account Of Sender',
        'Select-SenderAccount': 'Select Account Of Sender',

        //Cash Balance
        'Payment-By-WalletBalance': 'Wallet',
        'Payment-By-Cash': 'Cash',
        'Payment-By-CreditCard': 'Credit Card',
        'Cash-Balance': 'Cash Balance',
        'Bitcoin-Balance': 'Bitcoin Balance',
        'WithdrawRequest': 'Withdraw',
        'WithdrawSuccess': 'Withdraw request successfully accepted',
        'CashTransactions': 'Cash Transactions',
        'TransactionType': 'Type',
        'CreatedDate': 'Entry Date',
        'ProcessedDate': 'Processed Date',
        'Status': 'Status',
        'Created': 'Created',
        'Cancelled': 'Cancelled',
        'Processed': 'Processed',
        'Deposit': 'Deposit',
        'Withdraw': 'Withdraw',
        'Filters': 'Filters',
        'Search': 'Submit',
        'Currency': 'Currency',
        'WithdrawAccount': 'Withdrawal Account',
        'WithdrawAmount': 'Withdrawal Amount',
        'MinimumAmount': 'Min. Amount',
        'WithdrawPossibleAmount': 'Balance',
        'WithdrawBankAccount': 'Withdrawal Bank Account',
        'Select-BankAccount-Message': 'Select a bank account for withdrawal',
        'WithdrawDeclaration': 'Declaration',
        'WithdrawDeclareButton': 'I declarat that',
        'WithdrawDeclare-Message': 'The funds are not subject to withdraw to sanctions under EU laws and regulations',
        'DepositRequest': 'Deposit Request',
        'DepositAmount': 'Deposit Amount',

        //Bank Account
        'BankAccount': 'Bank Account',
        'BankName': 'Bank Name',
        'BranchName': 'Branch Name',
        'IBAN': 'IBAN',
        'BankAddress': 'Bank Address',
        'AccountNo': 'Account No',
        'AccountName': 'Account Name',
        'WithdrawAccountName': 'Account Name',
        'AccountAddress': 'Account Address',
        'SwiftCode': 'SWIFT',
        'SenderName': 'Sender Name',
        'SenderName-Note': 'Please provide a financial institution account in your own name.',
        'Deposit-Message1': 'Please ensure that the transfer is made in the name of the client of the account registered with the Wallet.',
        'Deposit-Message2': 'We cannot accept deposits from financial institution accounts not in your name.',
        'Deposit-Message3': 'Please be aware that the money transfer fees will have to be covered by the user.',
        'Submit': 'Submit',
        'LastDeposit': 'Last Deposit',
        'WithdrawBankAccount_Message1': 'Wallet balances can only be withdrawn to accounts with a deposit history.',
        'WithdrawBankAccount_Message2': 'The accounts to which deposits have been made are listed and the user can select an account to withdraw deposit.',
        'WithdrawBankAccount_Message3': 'Those who have purchased tokens by credit card are also not able to withdraw to a financial institution unless they have made a deposit of at least 1.00 EUR.',

        'PartialOrder-Question': 'Do you want to allow partial order?',
        'Radio-Option-Yes': 'Yes',
        'Radio-Option-No': 'No',
        'order-options-note': 'Requested AOS tokens are more than the remaining AOS tokens available in current IVFAO. Please select the any one of the following options.',
        'cancel-order': 'Cancel Order',

        //Credit-Card        
        'CreditCardPayment': 'Credit Card Payment',
        'CardNo': 'Card No',
        'CardSecretCode': 'CVV Code',
        'ExpiryMonth': 'Expiry Month',
        'CardHolderName': 'Card Holder Name',

        //Promoter
        'Recommender': 'Recommender',
        'Promoter-Agree-Message': 'I agree with the Terms & Conditions',
        'Promoter-Application-Message1': 'Please read the following terms and conditions before using this programme.',
        'Promoter-Application-Message2': '[Terms of Use for the Programme]',
        'Promoter-Application-Message3': 'To be determined.',
        'Promoter-Application-Submitted-Message': 'Progress Associator application successfully submitted.',
        'Promoter-Application-AlreadySubmitted-Message': 'Progress Associator application already submitted.',
        'Promoter-Coupon-Rule': 'The Referral code must be 4 to 6 characters and contains both alphabetic and numeric characters',
        'Promoter-Application-Approved-Message': 'Your Progress Associator application is Approved.',
        'Promoter-Application-Rejected-Message': 'Your Progress Associator application is Rejected.',
        'Promoter-Coupon-Setup-Message': 'Please click to set the Progress Associator Referral code.',
        'Promoter-Coupon-Registered-Message': 'Referral code successfully registered.',
        'DonotShowAgain-Message': 'Don’t show again',
        'Promoter-DontShowAgain-Message': 'This page will no longer be shown when you log in. Progress Associator code can be set from the Menu "Referral code".',

        //BitCoin
        'BitCoin-Agree-Message': 'I agree with the Terms & Conditions',
        'BitCoin-Application-Message1': 'Please read the following terms and conditions before using Bitcoin Wallet.',
        'BitCoin-Application-Submitted-Message': 'Bitcoin Wallet application successfully submitted.',
        'BitCoin-Application-AlreadySubmitted-Message': 'Bitcoin Wallet application already submitted.',
        'BitCoin-Wallet-Created-Message': 'Bitcoin Wallet successfully registered.',

        //Demo
        'Demo-KYC-Application': 'KYC Application',
        'kyc-demo-message1': 'This input screen is for demonstration purposes only.',
        'kyc-demo-message2': 'All fields must be filled in for the actual transaction.',
        'CreditCard-DemoMessage': 'Please use anyone of the following card details.',
        'Demo-DepositAccount-message': 'This account is a demo. It is not the transfer account.',

        //Error
        'ServerError': 'Error! Contact Support'
    },
};