import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import translate from "../../i18n/translate";
import { LOCALES } from '../../i18n';
import { actionChangeLanguage } from "../../store/action/layoutAction";
import connect from "react-redux/es/components/connect";
import ConfirmDialog from "../../components/ConfirmDialog";
import { getMultiLanguageMessage, getMultiLanguageLabel } from '../../i18n/multiLanguage';

const Sidebar = (props) => {
    const [language, setLanguage] = useState("");
    const [userMenuStatus, setUserMenuStatus] = useState(""); //Success, SUSPENDED, In-Progress, Revise, Rejected
    const [userKycStatus, setUserKycStatus] = useState("");
    const useMatchMedia = (mediaQuery, initialValue) => {
        const [isMatching, setIsMatching] = useState(initialValue)
        useEffect(() => {
            const watcher = window.matchMedia(mediaQuery)
            setIsMatching(watcher.matches)
            const listener = (matches) => {
                setIsMatching(matches.matches)
            }
            if (watcher.addEventListener) {
                watcher.addEventListener('change', listener)
            } else {
                watcher.addListener(listener)
            }
            return () => {
                if (watcher.removeEventListener) {
                    return watcher.removeEventListener('change', listener)
                } else {
                    return watcher.removeListener(listener)
                }
            }
        }, [mediaQuery])
        return isMatching
    };
    const isDesktop = useMatchMedia('(min-width:992px)', true);
    const [uniqueId, setUniqueId] = useState("");
    const [email, setEmail] = useState("");
    const [kycStatus, setKycStatus] = useState("/kyc");
    const [sideBarMobile, setSideBarMobile] = useState(false);
    const [isBuyTokenExpand, setIsBuyTokenExpand] = useState(false);
    const [isSellTokenExpand, setIsSellTokenExpand] = useState(false);
    const [isDepositWithdrawExpand, setDepositWithdrawExpand] = useState(false);
    const [isAccountExpand, setAccountExpand] = useState(false);

    const OnClickBuyTokenMenu = (e) => {
        setIsBuyTokenExpand(!isBuyTokenExpand);
        SaveMenuState(!isBuyTokenExpand, isSellTokenExpand, isDepositWithdrawExpand, isAccountExpand);
    };

    //const OnClickSellTokenMenu = (e) => {
    //    setIsSellTokenExpand(!isSellTokenExpand);
    //    SaveMenuState(isBuyTokenExpand, !isSellTokenExpand, isDepositWithdrawExpand, isAccountExpand);
    //};

    const OnClickDepositWithdrawMenu = (e) => {
        setDepositWithdrawExpand(!isDepositWithdrawExpand);
        SaveMenuState(isBuyTokenExpand, isSellTokenExpand, !isDepositWithdrawExpand, isAccountExpand);
    };

    const OnClickAccountMenu = (e) => {
        setAccountExpand(!isAccountExpand);
        SaveMenuState(isBuyTokenExpand, isSellTokenExpand, isDepositWithdrawExpand, !isAccountExpand);
    };

    const SaveMenuState = (isBuyMenu, isSellMenu, isDepositWithdrawMenu, isAccountMenu) => {
        const AOSMenuState = {
            "isBuyTokenExpand": isBuyMenu,
            "isSellTokenExpand": isSellMenu,
            "isDepositWithdrawExpand": isDepositWithdrawMenu,
            "isAccountExpand": isAccountMenu
        };
        localStorage.setItem("AOSMenuState", JSON.stringify(AOSMenuState));
    };

    const [Dialog, showConfirmDialog] = ConfirmDialog(
        getMultiLanguageLabel('lbl_ConfirmDialog_Title', language),
        getMultiLanguageMessage('msg_KYCNotCompleted', language),
        getMultiLanguageLabel('lbl_GoToWebPage', language),
        getMultiLanguageLabel('lbl_Close', language)
    );

    const ShowKycNotVerifiedMessage = async () => {
        if (userMenuStatus === "SUSPENDED") {
            alert(getMultiLanguageMessage('msg_UserSuspended_NoAccess', language));
        } else if (userMenuStatus === "In-Progress") {
            alert(getMultiLanguageMessage('msg_KycInProgress_NoAccess', language));
        } else {
            const isOk = await showConfirmDialog();
            if (isOk) {
                window.location.href = kycStatus;
            }
        }
    };

    const ShowNoAccessError = () => {
        alert(getMultiLanguageMessage('msg_UserSuspended_NoAccess', language));
    };

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        //actionGetUserProfile request send from Navbar
        let savedMenuState = localStorage.getItem("AOSMenuState");
        if (savedMenuState) {
            let objMenuState = JSON.parse(savedMenuState);
            setIsBuyTokenExpand(objMenuState.isBuyTokenExpand);
            //setIsSellTokenExpand(objMenuState.isSellTokenExpand);
            setDepositWithdrawExpand(objMenuState.isDepositWithdrawExpand);
            setAccountExpand(objMenuState.isAccountExpand);
        }
    }, []);

    useEffect(() => {
    }, [props.usersError]);

    useEffect(() => {
        setSideBarMobile(props.sideBarMobile);
    }, [props.sideBarMobile]);

    useEffect(() => {
        if (props?.users?.length > 0) {
            let userStatus = '';
            setUniqueId(props?.users[0]?.ClientID);
            (props?.users[0]?.UserNameFirst === null || props?.users[0]?.UserNameFirst.length === 0) ? setEmail(props?.users[0]?.MailID) : setEmail(props?.users[0]?.UserNameFirst + ' ' + props?.users[0]?.UserNameLast);
            (props?.users[0]?.KycStatus === null || props?.users[0]?.KycStatus.length === 0) ? setKycStatus("/kyc") : setKycStatus("/kyc-details");
            if (!(props?.users[0]?.KycStatus === null || props?.users[0]?.KycStatus === undefined || props?.users[0]?.KycStatus.length === 0)) {
                if (props?.users[0]?.KycStatus === "Success" || props?.users[0]?.KycStatus === "Revise") {
                    if (props?.users[0]?.Status === "SUSPENDED") {
                        userStatus = "SUSPENDED";
                    } else {
                        userStatus = props?.users[0]?.KycStatus;
                    }
                } else {
                    userStatus = props?.users[0]?.KycStatus;
                }
                setUserKycStatus(props?.users[0]?.KycStatus);
            } else {
                setUserKycStatus("");
            }
            setUserMenuStatus(userStatus);
        }
    }, [props.users]);

    return (props?.users?.length > 0) ? (
        <div className={"user-sidebar " + (!isDesktop ? 'user-sidebar-mobile' : '') + (sideBarMobile ? ' active' : '')}>
            <div className="user-sidebar-overlay" />
            <div className="user-box d-none d-lg-block">
                <div className="user-image">
                    <img src={require('../../assets/images/user-thumb-sm.png')} alt="thumb" />
                </div>
                <h6 className="user-name">{email}</h6>
                <div className="user-uid">
                    Unique ID: <span>{uniqueId}</span>
                </div>
                {(userKycStatus !== "") ?
                    <ButtonGroup disableElevation variant="contained" color='warning'>
                        {(userKycStatus === "Success" || userKycStatus === "Revise") ?
                            <Button sx={{ textTransform: 'none' }} color='success' size='small' variant="contained">{translate('KYC-Success')}</Button>
                            :
                            <Button sx={{ textTransform: 'none' }} color='warning' size='small' variant="contained">{translate('KYC-Pending')}</Button>
                        }
                    </ButtonGroup>
                    : ""
                }
            </div>
            <ul className="user-icon-nav">
                <li>
                    <a href="/">
                        <em className="ti ti-home" />
                        {translate('Menu-Top')}
                    </a>
                </li>

                <li>
                    <a href="#" onClick={event => OnClickBuyTokenMenu(event)}>
                        <em className="ti ti-import" />
                        {translate('Menu-BuyTokens')}
                        <div className="TopMenuIcon">{isBuyTokenExpand ? <em className="ti ti-angle-down" /> : <em className="ti ti-angle-right" />}</div>
                    </a>
                </li>
                {isBuyTokenExpand &&
                    <div className="SubMenu">
                        <li>
                            {(userMenuStatus === "Success" || userMenuStatus === "Revise") ?
                                <a href="/tokens">
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-IVAFO')}</div>
                                </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-IVAFO')}</div>
                                </a>
                            }
                        </li>
                        <li>
                            {(userMenuStatus === "Success" || userMenuStatus === "Revise") ?
                                <a href="/otcbuy">
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-OTCBuy-Open')}</div>
                                </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-OTCBuy-Open')}</div>
                                </a>
                            }
                        </li>
                    </div>
                }

                <li>
                    {(userMenuStatus === "Success" || userMenuStatus === "Revise") ?
                        <a href="/tokens-sell">
                            <em className="ti ti-export" />
                            {translate('Menu-OTCSell-Open')}
                        </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                            <em className="ti ti-export" />
                            {translate('Menu-OTCSell-Open')}
                        </a>
                    }
                </li>

                <li>
                    {(userMenuStatus === "Success" || userMenuStatus === "Revise") ?
                        <a href="/otcorderlist">
                            <em className="ti ti-export" />
                            {translate('Menu-OTCList')}
                        </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                            <em className="ti ti-export" />
                            {translate('Menu-OTCList')}
                        </a>
                    }
                </li>

                {props?.users[0]?.BtcWalletStatus === 2 &&
                    <li>
                        {(userMenuStatus === "Success" || userMenuStatus === "Revise") ?
                            <a href="/btctransfer">
                                <em className="ti ti-export" />
                                {translate('Menu-Bitcoin-Transfer')}
                            </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                <em className="ti ti-export" />
                                {translate('Menu-Bitcoin-Transfer')}
                            </a>
                        }
                    </li>
                }

                <li>
                    {(userMenuStatus === "Success" || userMenuStatus === "Revise" || userMenuStatus === "SUSPENDED") ?
                        <a href="/transactions">
                            <em className="ti ti-receipt" />
                            {translate('Menu-Transactions')}
                        </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                            <em className="ti ti-receipt" />
                            {translate('Menu-Transactions')}
                        </a>
                    }
                </li>

                <li>
                    {(userMenuStatus === "Success" || userMenuStatus === "Revise" || userMenuStatus === "SUSPENDED") ?
                        <a href="/executions">
                            <em className="ti ti-receipt" />
                            {translate('Menu-Executions')}
                        </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                            <em className="ti ti-receipt" />
                            {translate('Menu-Executions')}
                        </a>
                    }
                </li>

                <li>
                    <a href="#" onClick={event => OnClickDepositWithdrawMenu()}>
                        <em className="ti ti-money" />
                        {translate('Menu-DepositWithdraw')}
                        <div className="TopMenuIcon">{isDepositWithdrawExpand ? <em className="ti ti-angle-down" /> : <em className="ti ti-angle-right" />}</div>
                    </a>
                </li>
                {isDepositWithdrawExpand &&
                    <div className="SubMenu">
                        <li>
                            {(userMenuStatus === "Success" || userMenuStatus === "Revise") ?
                                <a href="/deposit">
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-Deposit')}</div>
                                </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-Deposit')}</div>
                                </a>
                            }
                        </li>
                        <li>
                            {(userMenuStatus === "Success" || userMenuStatus === "Revise") ?
                                <a href="/withdraw">
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-Withdraw')}</div>
                                </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-Withdraw')}</div>
                                </a>
                            }
                        </li>
                        <li>
                            {(userMenuStatus === "Success" || userMenuStatus === "Revise" || userMenuStatus === "SUSPENDED") ?
                                <a href="/cashtransaction">
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-Statement')}</div>
                                </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-Statement')}</div>
                                </a>
                            }
                        </li>
                    </div>
                }

                <li>
                    <a href="#" onClick={event => OnClickAccountMenu()}>
                        <em className="ti ti-settings" />
                        {translate('Menu-Account')}
                        <div className="TopMenuIcon">{isAccountExpand ? <em className="ti ti-angle-down" /> : <em className="ti ti-angle-right" />}</div>
                    </a>
                </li>
                {isAccountExpand &&
                    <div className="SubMenu">
                        <li>
                            {(userMenuStatus === "Success" || userMenuStatus === "Revise" || userMenuStatus === "Rejected" || userMenuStatus === "SUSPENDED") ?
                                <a href={kycStatus}>
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{(userKycStatus !== "") ? translate('Menu-AccountDetail') : translate('Menu-KYCApplication')}</div>
                                </a> :
                                <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-AccountDetail')}</div>
                                </a>
                            }
                        </li>
                        <li>
                            {(userMenuStatus === "Success" || userMenuStatus === "Revise" || userMenuStatus === "SUSPENDED") ?
                                <a href="/bankaccount">
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-WithdrawBankAccount')}</div>
                                </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-WithdrawBankAccount')}</div>
                                </a>
                            }
                        </li>
                        <li>
                            <a href="/security">
                                <em className="ti ti-angle-right" />
                                <div className="SubMenuText">{translate('Menu-ChangePassword')}</div>
                            </a>
                        </li>
                        <li>
                            <a href="/notificationsetting">
                                <em className="ti ti-angle-right" />
                                <div className="SubMenuText">{translate('Menu-Notification')}</div>
                            </a>
                        </li>
                        {props?.users[0]?.BtcWalletStatus === 0 &&
                            <li>
                                {(userMenuStatus === "Success" || userMenuStatus === "Revise") ?
                                    <a href="/createbtc">
                                        <em className="ti ti-angle-right" />
                                        <div className="SubMenuText">{translate('Menu-BitCoinApplication')}</div>
                                    </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                        <em className="ti ti-angle-right" />
                                        <div className="SubMenuText">{translate('Menu-BitCoinApplication')}</div>
                                    </a>
                                }
                            </li>
                        }
                        <li>
                            {(userMenuStatus === "Success" || userMenuStatus === "Revise") ?
                                <a href="/walletaddress">
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-WalletAddress')}</div>
                                </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                    <em className="ti ti-angle-right" />
                                    <div className="SubMenuText">{translate('Menu-WalletAddress')}</div>
                                </a>
                            }
                        </li>

                        {props?.users[0]?.Coupon &&
                            <li>
                                {(userMenuStatus === "Success" || userMenuStatus === "Revise") ?
                                    <a href="/referralcode">
                                        <em className="ti ti-angle-right" />
                                        <div className="SubMenuText">{translate('Menu-ReferralCode')}</div>
                                    </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                        <em className="ti ti-angle-right" />
                                        <div className="SubMenuText">{translate('Menu-ReferralCode')}</div>
                                    </a>
                                }
                            </li>
                        }

                        {(props?.users[0]?.UserType === 0 && props?.users[0]?.PromoterProcessStatus === 'Approved' && !props?.users[0]?.PromoterCoupon) &&
                            <li>
                                {(userMenuStatus === "Success" || userMenuStatus === "Revise") ?
                                    <a href="/promotercoupon">
                                        <em className="ti ti-angle-right" />
                                        <div className="SubMenuText">{translate('Menu-ReferralCode')}</div>
                                    </a> : <a href="#" onClick={event => ShowKycNotVerifiedMessage()}>
                                        <em className="ti ti-angle-right" />
                                        <div className="SubMenuText">{translate('Menu-ReferralCode')}</div>
                                    </a>
                                }
                            </li>
                        }
                    </div>
                }

            </ul>
            {/* .user-icon-nav */}
            <div className="user-sidebar-sap" />
            {/* .user-nav */}
            <div className="d-lg-none">
                <div className="user-sidebar-sap" />
                <div className="gaps-1x" />
                <ul className="topbar-action-list">
                    <li className="topbar-action-item topbar-action-link">
                        <a href="#">
                            <em className="ti ti-home" /> {translate('Go-to-main-site')}
                        </a>
                    </li>
                    {/* .topbar-action-item */}
                    <li className="dropup topbar-action-item topbar-action-language">
                        <a href="#" data-bs-toggle="dropdown" aria-haspopup="true">
                            {" "}
                            {props.locale === 'en-us' ? 'EN' : 'JP'} <em className="ti ti-angle-up" />{" "}
                        </a>
                        <ul className="dropdown-menu">
                            <li>
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    props.actionChangeLanguage(LOCALES.JAPANESE);
                                }}>JP</a>
                            </li>
                            <li>
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    props.actionChangeLanguage(LOCALES.ENGLISH);
                                }}>EN</a>
                            </li>
                        </ul>
                    </li>
                    {/* .topbar-action-item */}
                </ul>
                {/* .topbar-action-list */}
            </div>
            {/* .user-box */}
            {/* .user-sidebar-sap */}
            <ul className="user-nav">
                <li>
                    <a href="/how-to">{translate('How-to-Buy?')}</a>
                </li>
                <li>
                    <a href="/faq">{translate('FAQs')}</a>
                </li>
                <li>
                    <a href="#">{translate('Whitepaper')}</a>
                </li>
                <li>
                    {translate('Contact-support')}<span>info@aoscrypto.com</span>
                </li>
            </ul>

            {/* Confirmaiton dialog */}
            <Dialog />
        </div>
    ) : "";
};

const mapStateToProps = state => {
    return {
        locale: state.siteLayout.locale,
        users: state.remote.users,
        sideBarMobile: state.siteLayout.sideBarMobile,
        usersError: state.remote.usersError
    };
};

export default connect(mapStateToProps, {
    actionChangeLanguage
})(Sidebar);