import React, { useEffect,useState } from 'react';
import connect from "react-redux/es/components/connect";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { auth, validateOTP, logout } from "./common/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import translate from "../i18n/translate";

const OTP = (props) => {
    const [OTPText, setOTP] = useState("");
    //const [validUser, setValid] = useState(false);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const validate = async () => {
        navigate("/");
        /*const isValid = await validateOTP(OTPText);
        if(isValid){
            navigate("/");
        }else{
            logout();
            navigate("/login");
            console.log("Invalid OTP");
        }*/
    }

    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return;
        }
        if(error){
            alert(error);
        }
        /*if (user) {
            getUserDetails(user.uid).then((user)=>{
                navigate("/");
            });
        }*/
        //("/");
        props.actionToggleNavBar(false);
        props.actionToggleSideBar(false);
        props.actionToggleFooter(false);
    }, [user, loading, error]);
    return (
        <div className="user-ath-page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8  text-center">
                        <div className="user-ath-logo">
                            <a href="#">
                                <img
                                    src={require('../assets/images/logo.PNG')}
                                    srcSet={require('../assets/images/logo.PNG')}
                                    alt="icon"
                                />
                            </a>
                        </div>
                        <div className="user-ath-box">
                                {/*
                      <div class="note note-lg note-no-icon note-danger">
                          <p>Your email and password is invalid.</p>
                      </div>
*/}
                                <div className="input-item">
                                    <TextField id="otp" type={'password'} label={translate('OTP')} className='input-bordered' onChange={(e) => setOTP(e.target.value)}/>
                                </div>
                                <div className="gaps" />
                                <div className="d-flex justify-content-between align-items-center">
                                    <Button variant="contained" size='large' onClick={() => validate()}>
                                        Log in</Button>
                                </div>
                            
                        </div>
                        <div className="gaps-2x" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
    };
};
export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter
})(OTP);
