import * as actionTypes from '../action';

export const actionToggleNavBar = (payload) => async (dispatch, getState) => dispatch({
  type: actionTypes.TOGGLE_NAVBAR,
  payload
});

export const actionToggleSideBar = (payload) => async (dispatch, getState) => dispatch({
  type: actionTypes.TOGGLE_SIDEBAR,
  payload
});

export const actionToggleSideBarMobile = (payload) => async (dispatch, getState) => dispatch({
  type: actionTypes.TOGGLE_SIDEBAR_MOBILE,
  payload
});

export const actionToggleFooter = (payload) => async (dispatch, getState) => dispatch({
  type: actionTypes.TOGGLE_FOOTER,
  payload
});

export const actionChangeLanguage = payload => async (dispatch, getState) => {
  localStorage.setItem("locale", payload);
  dispatch({
    type: actionTypes.CHANGE_LANGUAGE,
    payload,
  });
};


