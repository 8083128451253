import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import { Stack } from '@mui/material';
import Box from '@mui/system/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
//import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
//import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { actionGetUserExecution } from "../store/action/remoteAction";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { client } from "../store/action/remoteAction";
import { getMultiLanguageMessage } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;

const Executions = (props) => {
    const [message, setMessage] = useState("");
    const [language, setLanguage] = useState("");
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        props.actionGetUserExecution('');
    }, []);

    useEffect(() => {
    }, []);

    const Message = ({ message }) => (
        <section>
            <p style={{ color: isError ? "red" : "blue" }}>{message}</p>
        </section>
    );

    const [state, setState] = React.useState({
        buy: false,
        sell: false
    });

    const submitChange = () => {
        resetTimout();
        props.actionGetUserExecution(formFilter());
    };

    const handleChange = (event) => {
        resetTimout();
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const formFilter = () => {
        let filterText = "";
        if (buy) {
            filterText += ',"Buy":' + 1;
        }
        if (sell) {
            filterText += ',"Sell":' + 1;
        }
        return filterText;
    };

    const { buy, sell } = state;
    const error = [buy, sell].filter((v) => v).length !== 2;

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-Executions')}</h2>
            {/* if there is no transaction you can use this code */}
            {message ? (
                <Message message={message} />
            ) : ''}

            <Box sx={{ display: 'flex' }}>
                <Accordion sx={{ display: 'nowrap', marginBottom: '10' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>{translate('Filters')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl required error={error} component="fieldset" sx={{ m: 3 }} variant="standard">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={buy} onChange={handleChange} name="buy" />
                                    }
                                    label={translate('Buy')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={sell} onChange={handleChange} name="sell" />
                                    }
                                    label={translate('Sell')}
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <Button variant="contained" onClick={() => submitChange()}>{translate('Search')}</Button>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <div className="gaps-2x" />
            {/* NEW TABLE */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="simple table" className="data-table tranx-table">
                    <TableHead>
                        <TableRow>
                            <TableCell width='16%' align="center" component="th" className="tranx-date">{translate('DateTime')}</TableCell>
                            <TableCell width='10%' align="center" component="th" className="tranx-buysell">{translate('Buy-Sell')}</TableCell>
                            <TableCell width='10%' align="center" component="th" className="tranx-token">{translate('Tokens')}</TableCell>
                            <TableCell width='14%' align="center" component="th" className="tranx-amount">{translate('UnitPrice')}</TableCell>
                            <TableCell width='14%' align="center" component="th" className="tranx-amount">{translate('Amount')}</TableCell>
                            <TableCell width='14%' align="center" component="th" className="tranx-amount">{translate('Commission')}</TableCell>
                            <TableCell width='22%' align="center" component="th" className="tranx-no">{translate('TransactionNo')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props?.executions?.map((txn, index) => {
                            return <TableRow key={txn.AUTORECID}>
                                <TableCell width='16%' align='center'>
                                    <span>{txn.DateCreated}</span>
                                </TableCell>
                                <TableCell width='10%' align='center'>
                                    <div className={(txn.BuySellFlag === 2 || txn.BuySellFlag === 3 || txn.BuySellFlag === 8) ? 'Message-Red' : 'Message-Blue'}>{txn.BuySellFlag === 5 ? translate('Transaction-Bonus') : (txn.BuySellFlag === 4 || txn.BuySellFlag === 3) ? translate('Transaction-Gift') : (txn.BuySellFlag === 2 || txn.BuySellFlag === 8) ? translate('Transaction-Sell') : translate('Transaction-Buy')}</div>
                                </TableCell>
                                <TableCell width='10%' align='center'>
                                    <span>{(txn.BuySellFlag === 2 || txn.BuySellFlag === 3 || txn.BuySellFlag === 8) ? '-' : '+'}{txn.Quantity}</span> {txn.CryptoAsset}
                                </TableCell>
                                <TableCell width='14%' align='center'>
                                    <span>{txn.UnitPrice}</span>{translate('Euro')}
                                </TableCell>
                                <TableCell width='14%' align='center'>
                                    <span>{txn.Amount}</span>{translate('Euro')}
                                </TableCell>
                                <TableCell width='14%' align='center'>
                                    <span>{txn.CommissionAmount}</span>{translate('Euro')}
                                </TableCell>
                                <TableCell width='22%' align='center'>
                                    <span>{txn.TransactionID.length > 12 ? txn.TransactionID.substring(txn.TransactionID.length - 12) : txn.TransactionID}</span>
                                </TableCell>
                            </TableRow>
                        })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {/* NEW TABLE ENDS */}
        </div>

    );
};
const mapStateToProps = state => {
    return {
        executions: state.remote.executions
    };
};
export default connect(mapStateToProps, {
    actionGetUserExecution
})(Executions);
