import React, { useEffect } from 'react';
import connect from "react-redux/es/components/connect";

const Footer = (props) => {

    useEffect(() => {
        if (props?.users.length > 0) {
        }
    }, [props.users]);

    return (props?.users?.length > 0) ? (
        <div className="footer-bar">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <span className="footer-copyright">
                            Copyright 2022, <a href="/">AOS Crypto</a>. All Rights Reserved.
                        </span>
                    </div>
                    {/* .col */}
                    <div className="col-md-5 text-md-end">
                        <ul className="footer-links">
                            <li>
                                <a href="policy.html">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="policy.html">Terms of Sales</a>
                            </li>
                        </ul>
                    </div>
                    {/* .col */}
                </div>
                {/* .row */}
            </div>
            {/* .container */}
        </div>
    ) : "";
};

const mapStateToProps = state => {
    return {
        users: state.remote.users
    };
};

export default connect(mapStateToProps, {
})(Footer);
