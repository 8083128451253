import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import translate from "../i18n/translate";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { actionGetBitcoinBalance } from "../store/action/remoteAction";
import connect from "react-redux/es/components/connect";
import { client } from "../store/action/remoteAction";
import { getMultiLanguageMessage, getMultiLanguageLabel } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;
let BASE_URL = process.env.REACT_APP_SERVER_URL_API;
let BTC_DECIMALCOUNT = process.env.REACT_APP_BTC_DECIMALCOUNT;

const BitcoinTransfer = (props) => {
    const [btcWalletStatus, setBtcWalletStatus] = useState(0);
    const [currency, setCurrency] = useState('BTC');
    const [btcTransfer, setBtcTransfer] = useState(0);
    const [btcBalance, setBtcBalance] = useState(0);
    const [btcAddress, setBtcAddress] = useState('');
    const [btcGasFee, setBtcGasFee] = useState(0);
    const [btcTransactionHash, setBtcTransactionHash] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [language, setLanguage] = useState("");
    const [pageId, setPageId] = useState(0);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const goToConfirmationPage = async () => {
        resetTimout();

        if (!btcTransfer || !btcTransfer.match(/^\d{1,}(\.\d{0,8})?$/) || btcTransfer <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(btcBalance) < parseFloat(btcTransfer)) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (!btcAddress) {
            alert(getMultiLanguageMessage('msg_EmptyWalletAddress', language));
            return;
        }

        // Calculate Gas Fee
        let bodyJSON = {
            items: {
                BuySellFlag: 9,
                ToWalletID: btcAddress,
                Amount: 0,
                UnitPrice: 0,
                Quantity: btcTransfer,
                UserId: localStorage.getItem("uid"),
                Tid: '',
                OtcFlag: 0,
                CommissionID: 0,
                CommissionAmount: 0,
                PaymentType: 0
            }
        };
        setIsButtonClicked(true);
        await client(localStorage.getItem("@token")).post(`${BASE_URL}/transactions/btc/gas`, bodyJSON).then((response) => {
            setIsButtonClicked(false);
            if (response.data) {
                if (response.data.ErrorCode == 1) {
                    alert(response.data.ErrorMessage);
                } else {
                    setTransactionId(response.data.TransactionID);
                    setBtcGasFee(response.data.GasFee);
                    setBtcTransactionHash(response.data.TransactionHash);
                    setPageId(1);
                }
            } else {
                alert("Error! Please contact customer support");
            }
        }).catch(error => {
            setIsButtonClicked(false);
            alert("Error! Please contact customer support");
            console.log(error);
        });

    };

    const goToPrevPage = () => {
        resetTimout();
        setPageId(0);
    };

    const submitBitcoinTransfer = async () => {
        resetTimout();
        let uid = localStorage.getItem("uid");

        if (!btcTransfer || !btcTransfer.match(/^\d{1,}(\.\d{0,8})?$/) || btcTransfer <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(btcBalance) < (parseFloat(btcTransfer) + parseFloat(btcGasFee))) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (!btcAddress) {
            alert(getMultiLanguageMessage('msg_EmptyWalletAddress', language));
            return;
        }

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            const bodyJSON = {
                UserId: uid,
                CryptoAsset: "BTC",
                BuySellFlag: 9,
                Currency: "BTC",
                FromWalletId: uid,
                ToWalletID: btcAddress,
                Quantity: btcTransfer,
                UnitPrice: 0,
                Amount: 0,
                CommissionID: 0,
                CommissionAmount: 0,
                PaymentType: 0,
                OtcFlag: 0,
                PartialOrderFlag: 0,
                TransactionId: transactionId,
                TransactionHash: btcTransactionHash
            };

            setIsButtonClicked(true);

            let serverUrl = SERVER_URL + `/btc-btctransfer`;
            fetch(serverUrl, {
                method: 'POST',
                redirect: 'follow',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(bodyJSON),
            })
                .then(res => {
                    if (res.ok) return res.json();
                    return res.json().then(json => Promise.reject(json));
                })
                .then((body) => {
                    window.location.href = body.url;
                })
                .catch((error) => {
                    let errorMessage = "Error: ";
                    if (error && error.message) {
                        errorMessage += error.message;
                    } else {
                        errorMessage += JSON.stringify(error)
                    }
                    alert(errorMessage);
                    setIsButtonClicked(false);
                });
        }
    };

    const handleBtcChange = (e) => {
        resetTimout();
        let requestedBtc = e.target.value.replace(/[^0-9.]/g, "");
        setBtcTransfer(requestedBtc);
    };

    const onChangeAddress = (e) => {
        resetTimout();
        setBtcAddress(e.target.value);
    };

    useEffect(() => {
        props.actionToggleNavBar(true);
        props.actionToggleSideBar(true);
        props.actionToggleFooter(true);
        props.actionGetBitcoinBalance();
    }, []);

    useEffect(() => {
        if (props?.users?.length > 0) {
            setBtcWalletStatus(props.users[0].BtcWalletStatus);
        }
    }, [props.users]);

    useEffect(() => {
        if (props?.bitcoinBalances && props?.bitcoinBalances.length > 0) {
            setBtcBalance(props?.bitcoinBalances[0].Balance.toFixed(8));
        }
    }, [props.bitcoinBalances]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-Bitcoin-Transfer')}</h2>
            <h2 className="input-item-label">{translate('Bitcoin-Balance')} = {btcBalance}</h2>

            {pageId === 0 &&
                <div>
                    <div className="gaps-1x" />
                    <div className="row">
                        <div className="col-md-8" style={{ padding: 0 }}>
                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <div className="gaps-1x" />
                                <div>
                                    <TextField
                                        size="small"
                                        label={translate('Order-Quantity')}
                                        id="outlined-start-adornment-aos"
                                        sx={{ width: '24.5ch' }}
                                        value={btcTransfer}
                                        defaultValue={btcTransfer}
                                        onChange={handleBtcChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{translate('Bitcoin')}</InputAdornment>,
                                        }}
                                    />
                                </div>

                                <div className="gaps-1x" />
                                <div class="input-item input-with-label">
                                    <label for="token-address" class="input-item-label">{translate('Enter-Bitcoin-Address')}:</label>
                                    <input class="input-bordered" type="text" id="token-address" name="token-address" onChange={onChangeAddress} value={btcAddress} defaultValue={btcAddress} />
                                </div>

                            </div>
                        </div>
                    </div>

                    {isButtonClicked &&
                        <>
                            <div className="gaps-1x" />
                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                        </>
                    }
                    <div className="gaps-1x" />
                    <button className="btn btn-primary" onClick={goToConfirmationPage} disabled={isButtonClicked ? true : false}>{translate('To-Confirm-Page')}</button>
                    <div className="gaps-1x" />
                </div>
            }

            {pageId === 1 &&
                <div>
                    <table width='70%'>
                        <tr>
                            <td align='left' >{translate('Order-Quantity')} :</td>
                            <td align='right' >{btcTransfer} {translate('Bitcoin')}</td>
                        </tr>
                        <tr>
                            <td align='left' >{translate('GasFee')} :</td>
                            <td align='right'>{parseFloat(btcGasFee)} {translate('Bitcoin')}</td>
                        </tr>
                        <tr>
                            <td align='left' >{translate('Payment-Amount')} :</td>
                            <td align='right'>{parseFloat((parseFloat(btcTransfer) + parseFloat(btcGasFee)).toFixed(BTC_DECIMALCOUNT))} {translate('Bitcoin')}</td>
                        </tr>
                        <tr>
                            <td align='left' colspan={2}>{translate('WalletAddress')} :</td>
                        </tr>
                        <tr>
                            <td align='left' colspan={2} className='td-wrap-text' >{btcAddress} </td>
                        </tr>
                    </table>

                    {isButtonClicked &&
                        <>
                            <div className="gaps-1x" />
                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                        </>
                    }
                    <div className="gaps-1x" />
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={goToPrevPage}>{translate('Back-Btn')}</button>&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={submitBitcoinTransfer}>{translate('Submit')}</button>
                    <div className="gaps-1x" />
                </div>
            }
            {/* form */}
        </div >

    );
};

const mapStateToProps = state => {
    return {
        users: state.remote.users,
        navBar: state.siteLayout.navBar,
        bitcoinBalances: state.remote.userBtcBalances
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
    actionGetBitcoinBalance
})(BitcoinTransfer);
