import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from '@mui/material';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { client } from "../store/action/remoteAction";
import { actionGetPromoterApplication } from "../store/action/remoteAction";
import { resetTimout } from "./common/Firebase";
import { LOCALES } from '../i18n/constants';
import { getMultiLanguageMessage } from '../i18n/multiLanguage';

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;

const PromoterCoupon = (props) => {
    const [data, setData] = useState({
        'UserNameFirst': '',
        'UserNameLast': '',
        'UserNameLast_DByte': '',
        'UserNameFirst_DByte': '',
        'MailID': ''
    });
    const [promoterCoupon, setPromoterCoupon] = useState('');
    const [isPromoterApplicationExist, setIsPromoterApplicationExist] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
    const [language, setLanguage] = useState("");

    const handleChange = (e) => {
        resetTimout();
        switch (e.target.id) {
            case "promoter-coupon":
                setPromoterCoupon(e.target.value);
                break;
            default: console.log('err');
        }
    };

    const submitPromoterCoupon = async () => {
        resetTimout();
        const regexCoupon = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
        let invalidReferralCode = 'Please enter valid referral code.';
        let couponAlreadyUsed = 'The referral code is already used.';
        if (language === LOCALES.JAPANESE) {
            invalidReferralCode = '正しい紹介コードを入力してください。';
            couponAlreadyUsed = '紹介コードはすでに利用されています。';
        }

        if (!promoterCoupon || promoterCoupon.length < 4 || promoterCoupon.length > 6 || !regexCoupon.test(promoterCoupon)) {
            alert(invalidReferralCode);
            return;
        }

        let uid = localStorage.getItem("uid");
        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            setIsButtonClicked(true);
            const request = {
                "UserID": uid,
                "Coupon": promoterCoupon
            };
            await client(localStorage.getItem("@token")).post(`${BASE_URL}/promoterrequest/coupon`, request).then((response) => {
                if (response && response.data && response.data.ErrorCode === 10001) {
                    alert(couponAlreadyUsed);
                    setIsButtonClicked(false);
                } else {
                    setIsSubmitSuccess(true);
                }
            }).catch(error => {
                let errorMessage = "Error: ";
                if (error && error.message) {
                    errorMessage += error.message;
                } else {
                    errorMessage += JSON.stringify(error)
                }
                alert(errorMessage);
                setIsButtonClicked(false);
            });
        }
    };

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        //actionGetUserProfile request send from Navbar
        props.actionGetPromoterApplication();
    }, []);

    useEffect(() => {
        if (props?.promoter && props?.promoter?.length > 0) {
            setIsPromoterApplicationExist(true);
            if (props?.promoter[0].Coupon && props?.promoter[0].Coupon.length > 0) {
                window.location.href = '/';
            }
        }
    }, [props.promoter]);

    useEffect(() => {
        if (props?.users?.length > 0) {
            setData(props?.users[0]);
        }
    }, [props.users]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Title-PromoterCoupon')}</h2>

            {isPromoterApplicationExist &&
                <div id="promoter-application">
                    <div>
                        <div className="gaps-1x" />
                        <div className="input-item input-with-label">
                            <TextField id='promoter-coupon' defaultValue={promoterCoupon} value={promoterCoupon} onChange={handleChange} label={translate('coupon-code')} className='input-bordered' />
                        </div>
                        <div className="note note-plane note-info">
                            <em className="fas fa-info-circle" />
                            <p>
                                {translate('Promoter-Coupon-Rule')}
                            </p>
                        </div>

                        <div className="gaps-1x" />
                        {!isSubmitSuccess &&
                            <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => submitPromoterCoupon(event)}>{translate('button-Register')}</button>
                        }
                    </div>

                    {/* .input-item */}
                    <div className="gaps-1x" />
                    {/* 10px gap */}
                    <div className="d-sm-flex justify-content-between align-items-center">
                        <div className="gaps-2x d-sm-none" />
                        <span className="color-success">
                            {isSubmitSuccess &&
                                <div>
                                    <em className="ti ti-check-box" /> {translate('Promoter-Coupon-Registered-Message')}
                                </div>
                            }
                        </span>
                    </div>
                </div>
            }

        </div >
    );
};

const mapStateToProps = state => {
    return {
        users: state.remote.users,
        promoter: state.remote.promoter
    };
};

export default connect(mapStateToProps, {
    actionGetPromoterApplication
})(PromoterCoupon);
