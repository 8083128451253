import React from 'react';
import DataTable from "react-data-table-component";

const Referral = () => {
    return (
        <div className="user-panel">
            <h2 className="user-panel-title">Referral</h2>
            <h5>Invite your friends and family and recive free tokens</h5>
            <p>
                <strong>
                    Each member recives a unique referral link to share with friends and
                    family and recive a bonus - 5% of the value of their contribution.
                </strong>
            </p>
            <p>
                The referral link may be used during a token contribution, in the pre-sale
                and the ICO.
            </p>
            <p>
                Imagine giving your unique referral link to your crypto-friend and he or she
                contributes tokens using your link, the bonus will be sent to your account
                automatically. The strategy is simple: the more links you send to your
                collagues, family and friends - the more tokens you may earn!
            </p>
            <h6>My unique referral link</h6>
            <div className="refferal-info">
                <span className="refferal-copy-feedback copy-feedback"/>
                <em className="fas fa-link"/>
                <input
                    type="text"
                    className="refferal-address"
                    defaultValue="https://demo.themenio.com/ico?ref=7d264f90653733592"
                    disabled=""
                />
                <button
                    className="refferal-copy copy-clipboard"
                    data-clipboard-text="https://demo.themenio.com/ico?ref=7d264f90653733592"
                >
                    <em className="ti ti-files"/>
                </button>
            </div>
            {/* .refferal-info */}
            {/* @updated on v1.0.1 */}
            <div className="gaps-2x"/>
            <ul className="share-links">
                <li>Share with :</li>
                <li>
                    <a href="#">
                        <em className="fas fa-at"/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <em className="fab fa-twitter"/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <em className="fab fa-facebook-f"/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <em className="fab fa-google"/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <em className="fab fa-linkedin-in"/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <em className="fab fa-whatsapp"/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <em className="fab fa-viber"/>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <em className="fab fa-vk"/>
                    </a>
                </li>
            </ul>
            {/* .share-links */}
            <div className="gaps-1x"/>
            <h4>Refferal Statistics</h4>
            <div className="refferal-statistics">
                <div className="row">
                    <div className="col-md-4">
                        <div className="refferal-statistics-item">
                            <h6>Visit Count</h6>
                            <span>420</span>
                        </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-4">
                        <div className="refferal-statistics-item">
                            <h6>Signin Count</h6>
                            <span>31</span>
                        </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-4">
                        <div className="refferal-statistics-item">
                            <h6>Total Bonus</h6>
                            <span>155</span>
                        </div>
                    </div>
                    {/* .col */}
                </div>
                {/* .row */}
            </div>
            {/* .refferal-statistics */}
            <h4>Refferal Lists</h4>
            <DataTable
                className="data-table refferal-table"
                striped
                highlightOnHover
                pagination
                paginationComponentOptions={{
                    rowsPerPageText: false,
                    rangeSeparatorText: '',
                    noRowsPerPage: false,
                    selectAllRowsItem: true,
                    selectAllRowsItemText: 'All'
                }}
                columns={[
                    {
                        name: 'Referee',
                        selector: row => row.referee,
                    },
                    {
                        name: 'Bought Token',
                        selector: row => row.boughtToken,
                    },
                    {
                        name: 'Bonus',
                        selector: row => row.bonus,
                    }, {
                        name: 'Date',
                        selector: row => row.date,
                    }, {
                        name: 'Channel',
                        selector: row => row.channel,
                    },
                ]}
                data={[
                    {
                        id: 1,
                        referee: 'Cynthia Foster',
                        boughtToken: '800',
                        bonus: '8',
                        date: '08-02-2022',
                        channel: 'Facebook'
                    }, {
                        id: 1,
                        referee: 'Cynthia Foster',
                        boughtToken: '800',
                        bonus: '8',
                        date: '08-02-2022',
                        channel: 'Facebook'
                    }, {
                        id: 1,
                        referee: 'Cynthia Foster',
                        boughtToken: '800',
                        bonus: '8',
                        date: '08-02-2022',
                        channel: 'Facebook'
                    }, {
                        id: 1,
                        referee: 'Cynthia Foster',
                        boughtToken: '800',
                        bonus: '8',
                        date: '08-02-2022',
                        channel: 'Facebook'
                    }, {
                        id: 1,
                        referee: 'Cynthia Foster',
                        boughtToken: '800',
                        bonus: '8',
                        date: '08-02-2022',
                        channel: 'Facebook'
                    },
                ]}
            />
        </div>
    );
};

export default Referral;
