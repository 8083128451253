import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { actionGetOtcTransaction, actionGetCashBalance, actionGetPromoterApplication } from "../store/action/remoteAction";
import IconButton from '@mui/material/IconButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
//import { getMultiLanguageMessage } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";
//import { client } from "../store/action/remoteAction";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;
let BASE_URL = process.env.REACT_APP_SERVER_URL_API;
//const App_Environment = process.env.REACT_APP_ENVIRONMENT;
const App_Environment = "0"; //To use Paymix Gateway for Demo

const OtcOrderList = (props) => {
    const [pageId, setPageId] = useState(0);
    const [isPromoterApplicationExist, setIsPromoterApplicationExist] = useState(false);
    //const [language, setLanguage] = useState("");

    const goToPromoterApplyPage = () => {
        window.location.href = '/promoterapply';
    };

    const goToPaymentPage = (e) => {
        resetTimout();
        let selectedTransaction = props.transactions[e];
        if (!selectedTransaction) {
            alert('Invalid Selection');
            return;
        }
        if (selectedTransaction.BuySellFlag === 2) {
            window.location.href = '/bids?tid=' + selectedTransaction.TransactionID;
        } else if (selectedTransaction.BuySellFlag === 7) {
            window.location.href = '/tokens-sell-settlement?tid=' + selectedTransaction.TransactionID;
        }
    };

    useEffect(() => {
        //setLanguage(localStorage.getItem("locale"));
        props.actionGetOtcTransaction();
    }, []);

    useEffect(() => {
        if (props?.users && props.users.length > 0) {
            if (props.users[0].UserType === 0) {
                props.actionGetPromoterApplication();
            }
        }
    }, [props.users]);

    return (
        <div className="user-panel">
            <table width='100%'>
                <tbody>
                    <tr>
                        <td align='left'><h2 className="user-panel-title">{translate('Menu-OTCList')}</h2></td>
                        <td align='right'>
                            {(props?.users[0]?.UserType === 0 && !isPromoterApplicationExist) &&
                                <a href='#' onClick={() => goToPromoterApplyPage()}><img src={require('../assets/images/PromoterApply.png')} width={40} alt="Promoter Application" /></a>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="gaps-1x" />

            {pageId === 0 &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" className="data-table tranx-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" component="th" className="tranx-no">{translate('DateTime')}</TableCell>
                                <TableCell align="center" component="th" className="tranx-buysell">{translate('Buy-Sell')}</TableCell>
                                <TableCell align="center" component="th" className="tranx-token">{translate('Tokens')}</TableCell>
                                <TableCell align="center" component="th" className="tranx-amount">{translate('UnitPrice')}</TableCell>
                                <TableCell align="center" component="th" className="tranx-amount">{translate('Amount')}</TableCell>
                                <TableCell align="center" component="th" className="tranx-to">{translate('Partial-Order')}</TableCell>
                                <TableCell align="center" component="th" className="tranx-action" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props?.transactions?.map((txn, index) => {
                                return <TableRow key={txn.TransactionID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align='center'>
                                        <span>{txn.DateCreated}</span>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <div className={(txn.BuySellFlag === 2) ? 'Message-Red' : 'Message-Blue'}>{txn.BuySellFlag === 2 ? translate('Transaction-Sell') : translate('Transaction-Buy')}</div>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <span>{txn.RemainingQuantity}</span>{txn.CryptoAsset}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <span>{txn.UnitPrice}</span>{txn.Currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <span>{parseFloat((parseFloat(txn.RemainingQuantity) * parseFloat(txn.UnitPrice)).toFixed(txn.DecimalCount))}</span>{txn.Currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <span>{txn.PartialOrderFlag === 1 ? 'OK' : 'NG'}</span>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <IconButton color="primary" aria-label="add to shopping cart" onClick={() => goToPaymentPage(index)}>
                                            <AddShoppingCartIcon data-index={index} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }

        </div>

    );
};

const mapStateToProps = state => {
    return {
        transactions: state.remote.otctransactions,
        users: state.remote.users,
        promoter: state.remote.promoter
    };
};

export default connect(mapStateToProps, {
    actionGetOtcTransaction,
    actionGetCashBalance,
    actionGetPromoterApplication
})(OtcOrderList);
