import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { client } from "../store/action/remoteAction";
import { resetTimout } from "./common/Firebase";
import { LOCALES } from '../i18n/constants';
import { getMultiLanguageMessage } from '../i18n/multiLanguage';

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;

const BitCoinApplication = (props) => {
    const [data, setData] = useState({
        'UserNameFirst': '',
        'UserNameLast': '',
        'UserNameLast_DByte': '',
        'UserNameFirst_DByte': '',
        'MailID': ''
    });
    const [isAgree, setIsAgree] = useState(false);
    const [isBitCoinApplicationExist, setIsBitCoinApplicationExist] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
    const [language, setLanguage] = useState("");

    const handleIsAgreeChange = () => {
        resetTimout();
        setIsAgree(!isAgree);
    };

    const submitBitCoinApplication = async () => {
        resetTimout();
        let agreeNoSelected = 'Please check Agree.';
        if (language === LOCALES.JAPANESE) {
            agreeNoSelected = '同意するにチェックを入れてください。';
        }

        if (!isAgree) {
            alert(agreeNoSelected);
            return;
        }

        let uid = localStorage.getItem("uid");
        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            setIsButtonClicked(true);
            const request = {
                "UserID": uid
            };
            await client(localStorage.getItem("@token")).post(`${BASE_URL}/wallets/Btc`, request).then((response) => {
                setIsSubmitSuccess(true);
            }).catch(error => {
                let errorMessage = "Error: ";
                if (error && error.message) {
                    errorMessage += error.message;
                } else {
                    errorMessage += JSON.stringify(error)
                }
                alert(errorMessage);
                setIsButtonClicked(false);
            });
        }
    };

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        //actionGetUserProfile request send from Navbar
    }, []);

    useEffect(() => {
        if (props?.users?.length > 0) {
            if (props?.users[0]?.KycStatus === null || props?.users[0]?.KycStatus.length === 0) {
                props.users[0].KycStatus = "In-Progress";
            }
            if (props?.users[0]?.BtcWalletStatus !== 0) {
                setIsBitCoinApplicationExist(true);
            }
            setData(props?.users[0]);
        }
    }, [props.users]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-BitCoinApplication')}</h2>

            {isBitCoinApplicationExist &&
                <div className='Message-Red'>{translate('BitCoin-Application-AlreadySubmitted-Message')}</div>
            }

            {!isBitCoinApplicationExist &&
                <div id="promoter-application">
                    <div>
                        <div className="gaps-1x" />
                        <div className="input-item input-with-label">
                            <TextField defaultValue={data?.UserNameLast} value={data?.UserNameLast} label={translate('Last-Name')} className='input-bordered' />
                        </div>
                        <div className="gaps-1x" />
                        <div className="input-item input-with-label">
                            <TextField defaultValue={data?.UserNameFirst} value={data?.UserNameFirst} label={translate('First-Name')} className='input-bordered' />
                        </div>
                        <div className="gaps-1x" />
                        <div className="input-item input-with-label">
                            <TextField defaultValue={data?.MailID} value={data?.MailID} label={translate('Email-Address')} className='input-bordered' />
                        </div>
                        <div className="gaps-1x" />

                        <p>
                            {translate('BitCoin-Application-Message1')}
                        </p>

                        <FormControlLabel control={<Checkbox
                            onChange={handleIsAgreeChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={isAgree ? true : false}
                        />} label={translate('BitCoin-Agree-Message')} />

                        {(isButtonClicked && !isSubmitSuccess) &&
                            <>
                                <div className="gaps-1x" />
                                <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                            </>
                        }

                        <div className="gaps-1x" />
                        {!isSubmitSuccess &&
                            <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => submitBitCoinApplication(event)}>{translate('button-Apply')}</button>
                        }
                    </div>

                    {/* .input-item */}
                    <div className="gaps-1x" />
                    <div className="d-sm-flex justify-content-between align-items-center">
                        <div className="gaps-2x d-sm-none" />
                        <span className="color-success">
                            {isSubmitSuccess &&
                                <div>
                                    <em className="ti ti-check-box" /> {translate('BitCoin-Application-Submitted-Message')}
                                </div>
                            }
                        </span>
                    </div>
                </div>
            }

        </div >
    );
};

const mapStateToProps = state => {
    return {
        users: state.remote.users
    };
};

export default connect(mapStateToProps, {
})(BitCoinApplication);
