import React from 'react';
import translate from "../i18n/translate";

const Faq = () => {
    return (
        <div className="user-panel">
            <h2 className="user-panel-title">Frequently Asked Questions</h2>
            <div id="faqList">
                <h4 className="color-dark">General</h4>
                <div className="accordion-simple" id="faqList-1">
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-1"
                        >
                            {" "}
                            What is ICO Crypto?{" "}
                        </h6>
                        <div id="collapse-1-1" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                AOS Crypto refers to AOS Crypto Currency being issued in form of Coins.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-2"
                        >
                            Who are the issuers of AOS Coins
                        </h6>
                        <div id="collapse-1-2" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                Progress Malta Limited are the issuers of the AOS Coins 
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-3"
                        >
                            Can I buy AOS Coins with fiat currency?
                        </h6>
                        <div id="collapse-1-3" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                At the moment, one can certainly buy AOS coins using fiat currency – namely, Euro & JPY 
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-4"
                        >
                            What cryptocurrencies can I use to purchase AOS Coins? 
                        </h6>
                        <div id="collapse-1-4" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                At present, this option is available only with KDA.  Conversion from other Crypto currencies would be possible through the OTC Buy/Sell function.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-5"
                        >
                            {" "}
                            How can I participate in the AOS Coin sale?  {" "}
                        </h6>
                        <div id="collapse-1-5" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                One can participate through the AOS Website by registering with few clicks. 
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-6"
                        >
                            What is the minimum number of Coins I can buy
                        </h6>
                        <div id="collapse-1-6" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                10 AOS coins
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-7"
                        >
                            How do I benefit from the AOS Coins? What are the advantages? 
                        </h6>
                        <div id="collapse-1-7" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                AOS coins are released with solutions and on a platform that deals with ‘Trilemma of Bitcoin”. It is much robust in terms of Security; it has high speed in Crypto world and is decentralised with PoW consensus. Dealing in AOS coins are much easy through your preferred platform.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-8"
                        >
                            Can I use AOS Coins to pay for day-to-day transactions?
                        </h6>
                        <div id="collapse-1-8" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                It is definitely possible for certain type of transaction, however at present, AOS Coins may not be used for day-to-day transactions.   (due to time taken for Transaction confirmation)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-9"
                        >
                            What currencies and commodities are supported by AOS Coins?
                        </h6>
                        <div id="collapse-1-9" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                At present, Japaneese Yen and Euro are supported by AOS coins. Later other coins / commodities would be added on.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-10"
                        >
                            What consensus mechanism does AOS Coins use?
                        </h6>
                        <div id="collapse-1-10" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                AOS Coins uses Proof of Work (PoW) Consensus 
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-11"
                        >
                            How does AOS Coins deal with congestion?
                        </h6>
                        <div id="collapse-1-11" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                AOS Coins are housed on Kadena blockchains – which are blocks of 20 braided chains, whereby one block is created every 30 seconds – which are able to handle the high rate of transactions. Moreover in future, as & when required - these braided blockchains have further capacity of expansion as it did from 10 to 20 in year 2020.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1-12"
                        >
                            How do I benefit from the ICO Token?
                        </h6>
                        <div id="collapse-1-12" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                    ICO Crypto - is unique platform; that is secure, smart and
                                    easy-to-use platform, and completely disrupting the way businesses
                                    raise capital.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                </div>
                {/* .accordion */}
                <h4 className="color-dark">Sell</h4>
                <div className="accordion-simple" id="faqList-2">
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-2-1"
                        >
                            How do I sell AOS Coins?
                        </h6>
                        <div id="collapse-2-1" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                Go to “Sell” tab and follow few simple and secured process to sell AOS coins
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-2-2"
                        >
                            When I ‘sell’ AOS coins – who I am selling it to / who is buys it?
                        </h6>
                        <div id="collapse-2-2" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                As of now you are selling it to “Gatsby Malta” / Gatsby Malta Buys it
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-2-3"
                        >
                            Can I gift AOS Coins ?
                        </h6>
                        <div id="collapse-2-3" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                Yes one can certainly gift AOS coins. You will get the option of gifting under “Sell” tab. Check the “want to Gift” check box and proceed as prompted.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-2-4"
                        >
                            What do I need to know/ do to “Gift” someone AOS Coins?
                        </h6>
                        <div id="collapse-2-4" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                In order to gift AOS Coins to someone, you need to know the beneficiary’s public wallet address.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                </div>
                {/* .accordion */}
                <h4 className="color-dark">Crypto Currency Exchange, Exchange rate</h4>
                <div className="accordion-simple" id="faqList-3">
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-3-1"
                        >
                            Which Crypto Exchange can I use to transact in AOS Coins?
                        </h6>
                        <div id="collapse-3-1" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                There are exchanges where later AOS coins could be listed and traded for other similar tokens and /or fiat money.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-3-2"
                        >
                            Can I Buy AOS coins from any Crypto Exchange?
                        </h6>
                        <div id="collapse-3-2" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                As of now AOS coins can be bought from AOS platform only.  There are exchanges where later AOS coins could be listed and traded for other similar tokens and /or fiat money.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-3-3"
                        >
                            What is Crypto Currency exchange rate?
                        </h6>
                        <div id="collapse-3-3" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                Crypto currency exchange rate is the amount of one type of Crypto currency you need to exchange another one, example number of ETH you need to buy 10 AOS Coins
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-3-4"
                        >
                            How is Crypto currency exchange rate calculated
                        </h6>
                        <div id="collapse-3-4" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                Crypto currency exchange rate is dependent on several factors like normal currency, however Crypto currency market being young and developing – the rates can vary one instant to another and even place to place for many different reasons.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-3-5"
                        >
                            Can I convert my Crypto Currency to Fiat Currency?
                        </h6>
                        <div id="collapse-3-5" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                Yes, Crypto to Fiat conversion is possible. Presently it can be done through Sell Option on AOS wallet. 
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-3-6"
                        >
                            How do I know about the exchange rate?
                        </h6>
                        <div id="collapse-3-6" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                As of now, rates will be displayed in AOS online wallets.  At a later stage, it would be available on select Exchanges. 
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                </div>
                {/* .accordion */}
                <h4 className="color-dark">KYC</h4>
                <div className="accordion-simple" id="faqList-4">
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-4-1"
                        >
                            Why do I need to Upload my KYC details
                        </h6>
                        <div id="collapse-4-1" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                KYC process is a data driven process by which an agency verified the identity of their Customers/ Clients. The process is mandatory for any “financial” organisation to ensure that only “clean’ assets are circulated.  And to avoid any malicious or criminal activity associated with using the AOS Coins and can be traced back, if required.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-4-2"
                        >
                            How is my data given for KYC is safeguarded
                        </h6>
                        <div id="collapse-4-2" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                All data shared on AOS platform are maintained following European GDPR rules and they are stored in strict compliance with European and Japaneese Privacy laws. 
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                </div>
                {/* .accordion */}
                <h4 className="color-dark">Dashboard</h4>
                <div className="accordion-simple" id="faqList-5">
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-5-1"
                        >
                            Where can we find information of AOS ICO status?
                        </h6>
                        <div id="collapse-5-1" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                We can check the same through AOS website Dashboard / Home tab.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                </div>
                {/* .accordion */}
                <h4 className="color-dark">Transactions</h4>
                <div className="accordion-simple" id="faqList-6">
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-6-1"
                        >
                            Can I see my transaction history?
                        </h6>
                        <div id="collapse-6-1" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                Yes, you can certainly see transaction from Transaction history from Transaction Tab. It shows number of AOS Coins, Amount, currency, date and time stamp and Transaction Status.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                </div>
                {/* .accordion */}
                <h4 className="color-dark">Wallet</h4>
                <div className="accordion-simple" id="faqList-7">
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-7-1"
                        >
                            What is Crypto wallet?
                        </h6>
                        <div id="collapse-7-1" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                A Crypto wallet is actually a misnomer. Rather than holding your cryptocurrencies – it holds your public and private key which in turn allows you to store/access/ transact in cryptocurrencies.  In short wallets serve as a mean to access them.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-7-2"
                        >
                            Why do I need a Wallet?
                        </h6>
                        <div id="collapse-7-2" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                Crypto currencies are not physical currencies rather they are assets which are present as records in digital ledger on respective blockchains. And to transact with these crypto currency OR to access these crypto currency one need to have a private key (akin to PIN of your normal account) “wallet address” where to receive Crypto Currencies and “wallet address” from where you can sell/gift/buy/transact with Crypto currency. 
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-7-3"
                        >
                            What is Public Key and what is it used for ?
                        </h6>
                        <div id="collapse-7-3" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                Public key is used to receive funds. It identifies your account on the blockchain and it can be searched in ledger enabling transactions.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-7-4"
                        >
                            What is Private Key and what is it used for ?
                        </h6>
                        <div id="collapse-7-4" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                Private key is used to sign transactions and prove your ownership to the public key. This key should never be shared.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                    <div className="accordion-item">
                        <h6
                            className="accordion-heading collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-7-5"
                        >
                            How to access my wallet?
                        </h6>
                        <div id="collapse-7-5" className="collapse" data-bs-parent="#faqList">
                            <div className="accordion-content">
                                <p>
                                You can access using chainweaver.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* .accordion-item */}
                </div>
                {/* .accordion */}
            </div>
            {/* #faqList */}
        </div>
    );
};

export default Faq;
