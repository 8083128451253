import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { actionGetBankAccount } from "../store/action/remoteAction";
import { resetTimout } from "./common/Firebase";
import ConfirmDialog from "./ConfirmDialog";
import { getMultiLanguageMessage, getMultiLanguageLabel } from '../i18n/multiLanguage';

let SERVER_URL_API = process.env.REACT_APP_SERVER_URL_API;
let CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const BankAccount = (props) => {
    let uid = localStorage.getItem("uid");
    const [language, setLanguage] = useState("");
    const [submitted, setSubmitted] = useState(0);
    const [seletedBankIndex, setSeletedBankIndex] = useState(-1);
    const [seletedBank, setSeletedBank] = useState('');

    const [data, setData] = useState({
        'AUTORECID': 0,
        'UserID': uid,
        'BankName': '',
        'BranchName': '',
        'IBAN': '',
        'BankAddress': '',
        'AccountNo': '',
        'AccountName': '',
        'AccountAddress': '',
        'SwiftCode': '',
        'ProcessStatus': 'Created',
        'UpdatedByUser': uid
    });

    /*
    const submitBankAccount = async () => {
        resetTimout();
        if (data.BankName === "") {
            alert(getMultiLanguageMessage('msg_emptybankname_Error', language));
            return;
        }
        if (data.BranchName === "") {
            alert(getMultiLanguageMessage('msg_emptybranchname_Error', language));
            return;
        }
        if (data.IBAN === "") {
            alert(getMultiLanguageMessage('msg_emptyiban_Error', language));
            return;
        }
        if (data.BankAddress === "") {
            alert(getMultiLanguageMessage('msg_emptybankaddress_Error', language));
            return;
        }
        if (data.AccountNo === "") {
            alert(getMultiLanguageMessage('msg_emptyaccountno_Error', language));
            return;
        }
        if (data.AccountName === "") {
            alert(getMultiLanguageMessage('msg_emptyaccountname_Error', language));
            return;
        }
        if (data.SwiftCode === "") {
            alert(getMultiLanguageMessage('msg_emptyswiftcode_Error', language));
            return;
        }

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            if (data.AUTORECID > 0) {
                data.UpdatedByUser = uid;
                data.ProcessStatus = "Created";
                await client(localStorage.getItem("@token")).put(`${SERVER_URL_API}/bankaccount/` + data.AUTORECID, data).then((response) => {
                    setSubmitted(parseInt(response.status));
                }).catch(error => {
                    setSubmitted(500);
                    console.log(error);
                });
            } else {
                await client(localStorage.getItem("@token")).post(`${SERVER_URL_API}/bankaccount`, data).then((response) => {
                    setSubmitted(parseInt(response.status));
                }).catch(error => {
                    setSubmitted(500);
                    console.log(error);
                });
            }
        }
    }
    */

    const handleChange = (e) => {
        resetTimout();
        switch (e.target.id) {
            case "bank-name": setData({ ...data, ['BankName']: e.target.value }); break;
            case "branch-name": setData({ ...data, ['BranchName']: e.target.value }); break;
            case "iban": setData({ ...data, ['IBAN']: e.target.value }); break;
            case "bank-address": setData({ ...data, ['BankAddress']: e.target.value }); break;
            case "account-no": setData({ ...data, ['AccountNo']: e.target.value }); break;
            case "account-name": setData({ ...data, ['AccountName']: e.target.value }); break;
            case "account-address": setData({ ...data, ['AccountAddress']: e.target.value }); break;
            case "swift-code": setData({ ...data, ['SwiftCode']: e.target.value }); break;
        }
    };

    const onChangeBankList = (e, index) => {
        resetTimout();
        if (index >= 0) {
            setSeletedBank(props.userBankAccounts[index])
            setSeletedBankIndex(index);
        } else {
            setSeletedBank('');
            setSeletedBankIndex(-1);
        }
    };

    const [Dialog, showConfirmDialog] = ConfirmDialog(
        getMultiLanguageLabel('lbl_ConfirmDialog_Title', language),
        getMultiLanguageMessage('msg_GoToDepositPage_Confirm', language),
        getMultiLanguageLabel('lbl_MakeDeposit', language),        
        getMultiLanguageLabel('lbl_Cancel', language)
    );

    const onClickAddAccount = async () => {
        const isOk = await showConfirmDialog();
        if (isOk) {
            window.location.href = '/deposit';
        }
    };

    useEffect(() => {
        props.actionGetBankAccount();
    }, []);

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
    }, [props.userBankAccounts]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Title-WithdrawAccount')}</h2>
            <div className="gaps-2x" />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="simple table" className="data-table tranx-table">
                    <TableHead>
                        <TableRow>
                            <TableCell width='10%' align="center" component="th"></TableCell>
                            <TableCell width='40%' align="center" component="th">{translate('BankName')}</TableCell>
                            <TableCell width='25%' align="center" component="th">{translate('AccountNo')}</TableCell>
                            <TableCell width='25%' align="center" component="th">{translate('LastDeposit')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props?.userBankAccounts?.map((bankAccount, index) => {
                            return <TableRow key={bankAccount.AUTORECID}>
                                <TableCell width='10%' align='center'>
                                    <input type="radio" onChange={event => onChangeBankList(event, index)} value={index} name="bankList" />
                                </TableCell>
                                <TableCell width='40%' align='center'>
                                    <span>{bankAccount.BankName}</span>
                                </TableCell>
                                <TableCell width='25%' align='center'>
                                    <span>{bankAccount.AccountNo}</span>
                                </TableCell>
                                <TableCell width='25%' align='center'>
                                    <span>{bankAccount.LastDepositDate ? bankAccount.LastDepositDate.substring(0, 10) : ''}</span>
                                </TableCell>
                            </TableRow>
                        })
                        }
                    </TableBody>
                </Table>
            </TableContainer>


            {seletedBankIndex >= 0 &&
                <div>
                    <div className="gaps-2x" />
                    <table width={600}>
                        <tr className='tr-type1'>
                            <td className='td-type1-col1'>{translate('WithdrawAccountName')}</td>
                            <td className='td-type1-col2'>{seletedBank?.AccountName}</td>
                        </tr>
                        <tr className='tr-type1'>
                            <td className='td-type1-col1'>{translate('IBAN')}</td>
                            <td className='td-type1-col2'>{seletedBank?.IBAN}</td>
                        </tr>
                        <tr className='tr-type1'>
                            <td className='td-type1-col1'>{translate('AccountNo')}</td>
                            <td className='td-type1-col2'>{seletedBank?.AccountNo}</td>
                        </tr>
                        <tr className='tr-type1'>
                            <td className='td-type1-col1'>{translate('BankName')}</td>
                            <td className='td-type1-col2'>{seletedBank?.BankName}</td>
                        </tr>
                        <tr className='tr-type1'>
                            <td className='td-type1-col1'>{translate('BankAddress')}</td>
                            <td className='td-type1-col2'>{seletedBank?.BankAddress}</td>
                        </tr>
                        <tr className='tr-type1'>
                            <td className='td-type1-col1'>{translate('SwiftCode')}</td>
                            <td className='td-type1-col2'>{seletedBank?.SwiftCode}</td>
                        </tr>
                    </table>
                </div>
            }

            <div className="gaps-2x" />
            <div className='Right-Align'>
                <button className="btn btn-primary" onClick={onClickAddAccount}>{translate('button-AddAccount')}</button>
                <Dialog />
            </div>

            <div className="gaps-1x" />
            <div>{translate('WithdrawBankAccount_Message1')}</div>
            <div>{translate('WithdrawBankAccount_Message2')}</div>
            <div>{translate('WithdrawBankAccount_Message3')}</div>

            <div className="gaps-2x" />
        </div >
    );
};

const mapStateToProps = state => {
    return {
        userBankAccounts: state.remote.userBankAccounts
    };
};

export default connect(mapStateToProps, {
    actionGetBankAccount
})(BankAccount);
