import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signOut,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import { authenticator } from 'otplib';
import QRCode from 'qrcode';
import { client } from "../../store/action/remoteAction";
import ReactObserver from 'react-event-observer';
import { getAnalytics, logEvent } from "firebase/analytics";

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;

/*const firebaseConfig = {
  databaseURL: "https://vijayan-test1.firebaseio.com",
  apiKey: "AIzaSyC6Anb2ynhAI144GXO26wKGLLjwOFka3ig",
  authDomain: "vijayan-test1.firebaseapp.com",
  projectId: "vijayan-test1",
  storageBucket: "vijayan-test1.appspot.com",
  messagingSenderId: "1016132120090",
  appId: "1:1016132120090:web:01d1a0172d7e417bcbaba3",
  measurementId: "G-SKBM20BQ5Y"
};
const secret = 'KVKFKRCPNZQUYMLXOVYDSQKJKZDTSRLD';
*/
const firebaseConfig = {
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};
const secret = process.env.REACT_APP_FIREBASE_SECRET;
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
export const firebaseObserver = ReactObserver();
const analytics = getAnalytics();

const getUserDetails = async (uid) => {
  const q = await query(collection(db, "users"), where("uid", "==", uid));
  const data = await getDocs(q);
  return data;
};

auth.onAuthStateChanged(function (user) {
  /*logEvent(analytics, user.id, {
    content_type: 'login',
    content_value: loggedIn()
  });*/
  firebaseObserver.publish("authStateChanged", loggedIn())
});

function loggedIn() {
  return !!auth.currentUser;
}

const validateOTP = async (OTP) => {
  try {
    var isValid = await authenticator.check(OTP, secret);
    //console.log("OTP:" + isValid);
    return isValid;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    let isValidLogin = false;
    await signInWithEmailAndPassword(auth, email, password).then(async (result) => {
      //console.log('signInWithEmailAndPassword: ' + JSON.stringify(result));
      if (!result?.user?.emailVerified) {
        await sendEmailVerification(result?.user)
          .then(() => {
            logout();
            console.log("Email verification sent!");
          });
        return false;
      }

      const token = await auth?.currentUser?.getIdToken(true);
      const uid = result?.user?.uid;
      if (token && uid) {
        //Verify user registered in AOS DB
        let response = await client(token).post(`${BASE_URL}/users/login`, {
          userId: uid,
        });
        if (response.status != 200) {
          alert("Server Error");
          return false;
        }
        if (!response.data || response.data.length == 0 || !response.data[0] || response.data[0].UserID != uid) {
          alert("User not registered");
        } else {
          localStorage.setItem("@token", token);
          localStorage.setItem("uid", uid);
          localStorage.setItem("promoterNotification", "0");
          isValidLogin = true;
          setTimeout(() => logout(), 300000);
        }
      } else {
        logout();
      }
    });

    return isValidLogin;
  } catch (err) {
    console.error(err);
    logout();
    alert(err.message);
    return false;
  }
};

const registerWithEmailAndPassword = async (name, email, password, coupon) => {
  try {
    if (coupon) {
      let response = await client('').get(`${BASE_URL}/users/coupon/` + coupon, {
      });
      if (response.status != 200) {
        alert("Server Error");
        return;
      }
      if (!response.data || response.data.length == 0 || !response.data[0] || response.data[0].Status != 'ACTIVE') {
        alert("Invalid Promotional code");
        return;
      }
    }

    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await sendEmailVerification(user)
      .then(() => {
        console.log("Email verification sent!");
      });

    //const secret = authenticator.generateSecret();

    let data = await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      secret: secret,
      email,
    });

    const token = await auth?.currentUser?.getIdToken(true);
    if (token) {
      localStorage.setItem("@token", token);
      let result = await client(token).post(`${BASE_URL}/users`, {
        uid: user.uid,
        coupon: coupon,
        email,
      })
      let resultWallet = await client(token).post(`${BASE_URL}/wallets`, {
        UserID: user.uid,
      })
    }

    //let url = authenticator.keyuri(email, 'firebase-otp', secret)
    //return url;
    return "Success";
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const updateUserPassword = async (newPassword) => {
  await updatePassword(auth?.currentUser, newPassword)
    .then(() => {
      alert("Update successful.");
    }).catch((error) => {
      alert("Error");
      console.log(error);
      // An error ocurred
      // ...
    });
}

const userLogout = async(logoutType) => {
  let token = localStorage.getItem("@token");
  let uid = localStorage.getItem("uid");
  if (token && uid) {
    await client(token).post(`${BASE_URL}/users/logout`, {
      userId: uid,
      logoutType: logoutType,
    });
  }
  logout();
};

const logout = () => {
  console.log('logout called');
  localStorage.removeItem("@token");
  localStorage.removeItem("uid");
  localStorage.removeItem("AOSMenuState");
  localStorage.removeItem("promoterNotification");
  signOut(auth);
};

const resetTimout = () => {
  let timerId = localStorage.getItem("logouttimerid");
  if (timerId) {
    clearTimeout(timerId);
    const retVal = setTimeout(() => logout(), 300000);
    localStorage.setItem("logouttimerid", retVal);
  }
};

export {
  auth,
  db,
  storage,
  loggedIn,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateUserPassword,
  logout,
  userLogout,
  resetTimout,
  validateOTP,
  getUserDetails
};