export const TOGGLE_NAVBAR = 'TOGGLE_NAVBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_SIDEBAR_MOBILE = 'TOGGLE_SIDEBAR_MOBILE';
export const TOGGLE_FOOTER = 'TOGGLE_FOOTER';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
//user
export const USER_DATA_LOADING = 'USER_DATA_LOADING';
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS';
export const USER_DATA_ERROR = 'USER_DATA_ERROR';
export const USER_CREATE_LOADING = 'USER_CREATE_LOADING';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_BALANCE_LOADING = 'USER_BALANCE_LOADING';
export const USER_BALANCE_SUCCESS = 'USER_BALANCE_SUCCESS';
export const USER_BALANCE_ERROR = 'USER_BALANCE_ERROR';
export const USER_CASH_BALANCE_LOADING = 'USER_CASH_BALANCE_LOADING';
export const USER_CASH_BALANCE_SUCCESS = 'USER_CASH_BALANCE_SUCCESS';
export const USER_CASH_BALANCE_ERROR = 'USER_CASH_BALANCE_ERROR';
export const USER_BITCOIN_BALANCE_LOADING = 'USER_BITCOIN_BALANCE_LOADING';
export const USER_BITCOIN_BALANCE_SUCCESS = 'USER_BITCOIN_BALANCE_SUCCESS';
export const USER_BITCOIN_BALANCE_ERROR = 'USER_BITCOIN_BALANCE_ERROR';
export const USER_BANKACCOUNT_LOADING = 'USER_BANKACCOUNT_LOADING';
export const USER_BANKACCOUNT_SUCCESS = 'USER_BANKACCOUNT_SUCCESS';
export const USER_BANKACCOUNT_ERROR = 'USER_BANKACCOUNT_ERROR';

//transaction
export const TRANSACTION_DATA_LOADING = 'TRANSACTION_DATA_LOADING';
export const TRANSACTION_DATA_SUCCESS = 'TRANSACTION_DATA_SUCCESS';
export const TRANSACTION_OTC_DATA_LOADING = 'TRANSACTION_OTC_DATA_LOADING';
export const TRANSACTION_OTC_DATA_SUCCESS = 'TRANSACTION_OTC_DATA_SUCCESS';
export const TRANSACTION_CREATE_LOADING = 'TRANSACTION_CREATE_LOADING';
export const TRANSACTION_CREATE_SUCCESS = 'TRANSACTION_CREATE_SUCCESS';
export const TRANSACTION_PRICE_LOADING = 'TRANSACTION_PRICE_LOADING';
export const TRANSACTION_PRICE_SUCCESS = 'TRANSACTION_PRICE_SUCCESS';

//execution
export const EXECUTION_DATA_LOADING = 'EXECUTION_DATA_LOADING';
export const EXECUTION_DATA_SUCCESS = 'EXECUTION_DATA_SUCCESS';

//currency
export const CURRENCY_DATA_LOADING = 'CURRENCY_DATA_LOADING';
export const CURRENCY_DATA_LOADING_AOS = 'CURRENCY_DATA_LOADING_AOS';
export const CURRENCY_DATA_LOADING_EURO = 'CURRENCY_DATA_LOADING_EURO';
export const CURRENCY_DATA_SUCCESS = 'CURRENCY_DATA_SUCCESS';
export const CURRENCY_DATA_SUCCESS_AOS = 'CURRENCY_DATA_SUCCESS_AOS';
export const CURRENCY_DATA_SUCCESS_EURO = 'CURRENCY_DATA_SUCCESS_EURO';

//Tranche
export const TRANCHE_DATA_LOADING = 'TRANCHE_DATA_LOADING';
export const TRANCHE_DATA_SUCCESS = 'TRANCHE_DATA_SUCCESS';

//Promoter Application
export const PROMOTER_APPLICATION_DATA_LOADING = 'PROMOTER_APPLICATION_DATA_LOADING';
export const PROMOTER_APPLICATION_DATA_SUCCESS = 'PROMOTER_APPLICATION_DATA_SUCCESS';
export const PROMOTER_APPLICATION_DATA_ERROR = 'PROMOTER_APPLICATION_DATA_ERROR';

//Commission
export const COMMISSION_RATE_LOADING = 'COMMISSION_RATE_LOADING';
export const COMMISSION_RATE_SUCCESS = 'COMMISSION_RATE_SUCCESS';
export const COMMISSION_RATE_ERROR = 'COMMISSION_RATE_ERROR';

//Bitcoin Gas Fee
export const BITCOIN_GASFEE_LOADING = 'BITCOIN_GASFEE_LOADING';
export const BITCOIN_GASFEE_SUCCESS = 'BITCOIN_GASFEE_SUCCESS';
export const BITCOIN_GASFEE_ERROR = 'BITCOIN_GASFEE_ERROR';