import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TextField, Select, MenuItem, FormControl, OutlinedInput, InputLabel } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Switch from '@mui/material/Switch';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { formatDate } from "../store/action/remoteAction";
import { client } from "../store/action/remoteAction";
import { resetTimout } from "./common/Firebase";

import Table from '@mui/material/Table';
//import Box from '@mui/system/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

let SERVER_URL_API = process.env.REACT_APP_SERVER_URL_API;
let CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const Account = (props) => {
    let uid = localStorage.getItem("uid");
    //const [kycStatus, setKycStatus] = useState(false);
    const [submitted, setSubmitted] = useState(0);
    const [emailPref, setEmailPref] = useState(false);
    const [isWalletAddressCopied, setIsWalletAddressCopied] = useState(false);
    const [isReferralCodeCopied, setIsReferralCodeCopied] = useState(false);
    const [isReferralUrlCopied, setIsReferralUrlCopied] = useState(false);

    const [data, setData] = useState({
        'UserNameFirst': '',
        'UserNameLast': '',
        'UserNameLast_DByte': '',
        'UserNameFirst_DByte': '',
        'MailID': '',
        'DOB': '',
        'Nationality': '',
        'PhoneNumber1': '',
    });

    const [dtDOB, setDOB] = useState(null);

    const submitUserProfile = async () => {
        resetTimout();
        data.DOB = dtDOB ? formatDate(dtDOB) : null;
        await client(localStorage.getItem("@token")).put(`${SERVER_URL_API}/userdetails/` + uid, data).then((response) => {
            //console.log('Result: '+response);
            setSubmitted(parseInt(response.status));
        }).catch(error => {
            setSubmitted(500);
            console.log(error)
        });
    }

    const handleDate = (e) => {
        //setData({ ...data, ['DOB']: new Date(e?e:'1970/01/01').toISOString().split('T')[0] });
        resetTimout();
        setDOB(e);
    }

    const handleCheck = (e) => {
        resetTimout();
        setEmailPref(e.target.checked);
        localStorage.setItem("emailPref", e.target.checked);
    }

    const handleNationalityEdit = (e) => {
        resetTimout();
        if (!e.target.id && !!e.target.value) {
            setData({ ...data, ['Nationality']: e.target.value });
        }
    }

    const handleChange = (e) => {
        resetTimout();
        //console.log(e.target);
        /*const regex = /^[a-z0-9.,@]+$/i
        if (e.target.value === '' || regex.test(e.target.value)) {
            switch (e.target.id) {
                case "last-name": setData({ ...data, ['UserNameLast']: e.target.value }); break;
                case "first-name": setData({ ...data, ['UserNameFirst']: e.target.value }); break;
                case "mobile-number": setData({ ...data, ['PhoneNumber1']: e.target.value }); break;
                case "email": setData({ ...data, ['MailID']: e.target.value }); break;
            }
        }
        switch (e.target.id) {
            case "kanji-last-name": setData({ ...data, ['UserNameLast_DByte']: e.target.value }); break;
            case "kanji-first-name": setData({ ...data, ['UserNameFirst_DByte']: e.target.value }); break;
        }*/
    };

    const copyWalletAddress = () => {
        setIsWalletAddressCopied(false);
        setIsReferralCodeCopied(false);
        setIsReferralUrlCopied(false);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(data?.WalletID);
            setIsWalletAddressCopied(true);
        }
        else {
            alert(data?.WalletID);
        }
    };

    const copyReferralCode = () => {
        setIsWalletAddressCopied(false);
        setIsReferralCodeCopied(false);
        setIsReferralUrlCopied(false);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(data?.Coupon);
            setIsReferralCodeCopied(true);
        }
        else {
            alert(data?.Coupon);
        }
    };

    const copyReferralUrl = () => {
        setIsWalletAddressCopied(false);
        setIsReferralCodeCopied(false);
        setIsReferralUrlCopied(false);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(CLIENT_URL + "/signup?coupon=" + data?.Coupon);
            setIsReferralUrlCopied(true);
        }
        else {
            alert(CLIENT_URL + "/signup?coupon=" + data?.Coupon);
        }
    };

    useEffect(() => {
        let pref = localStorage.getItem("emailPref");
        setEmailPref(pref);
        //actionGetUserProfile request send from Navbar
    }, []);

    useEffect(() => {
        //console.log('Hello v '+props?.users[0]?.WalletId);
        if (props?.users[0]?.KycStatus === null || props?.users[0]?.KycStatus.length === 0) {
            props.users[0].KycStatus = "In-Progress";
            //props.users[0].DOB = new Date().toISOString().split('T')[0];
        }
        if (props?.users[0]?.DOB) {
            setDOB(new Date(props?.users[0]?.DOB));
        }
        setData(props?.users[0]);
    }, [props.users]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Account-Information')}</h2>
            {/* .alert-box */}
            <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#personal-data">
                        {translate('Personal-Data')}
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#wallet-address">
                        {translate('Wallet-Address')}
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#notification">
                        {translate('Notification')}
                    </a>
                </li>
            </ul>
            {/* .nav-tabs-line */}
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="personal-data">
                    <form action="#">
                        <div className="input-item input-with-label">
                            <TextField id="last-name" value={data?.UserNameLast} onChange={handleChange} defaultValue={data?.UserNameLast} label={translate('Last-Name')} className='input-bordered' />
                        </div>
                        <div className="input-item input-with-label">
                            <TextField id="first-name" value={data?.UserNameFirst} onChange={handleChange} defaultValue={data?.UserNameFirst} label={translate('First-Name')} className='input-bordered' />
                        </div>
                        <div className="input-item input-with-label">
                            <TextField id="kanji-last-name" value={data?.UserNameLast_DByte} onChange={handleChange} defaultValue={data?.UserNameLast_DByte} label={translate('Kanji-Last-Name')} className='input-bordered' />
                        </div>
                        <div className="input-item input-with-label">
                            <TextField id="kanji-first-name" value={data?.UserNameFirst_DByte} onChange={handleChange} defaultValue={data?.UserNameFirst_DByte} label={translate('Kanji-First-Name')} className='input-bordered' />
                        </div>
                        {/* .input-item */}
                        <div className="input-item input-with-label">
                            <TextField id="email" value={data?.MailID} onChange={handleChange} defaultValue={data?.MailID} label={translate('Email-Address')} className='input-bordered' />
                        </div>
                        {/* .input-item */}
                        <div className="input-item input-with-label">
                            <TextField id="mobile-number" value={data?.PhoneNumber1} onChange={handleChange} defaultValue={data?.PhoneNumber1} label={translate('Mobile-Number')} className='input-bordered' />
                        </div>
                        {/* .input-item */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-item input-with-label">
                                    <DesktopDatePicker
                                        label={translate('Date-of-birth')}
                                        disableFuture="true"
                                        id="date-of-birth"
                                        inputFormat="yyyy/MM/dd"
                                        value={dtDOB}
                                        onChange={handleDate}
                                        defaultValue={dtDOB}
                                        renderInput={(params) => <TextField id="dob" className='input-bordered' {...params} />}
                                    />
                                </div>
                                {/* .input-item */}
                            </div>
                            {/* .col */}
                            <div className="col-md-6">
                                <div className="input-item input-with-label">
                                    <FormControl fullWidth>
                                        <InputLabel id="nationality-label">{translate('Nationality')}</InputLabel>
                                        <Select
                                            id="nationality"
                                            labelId="nationality-label"
                                            //value={age}
                                            value={data?.Nationality}
                                            onChange={handleNationalityEdit}
                                            className="input-item-label"
                                            input={<OutlinedInput label={translate('Nationality')} />}
                                        >
                                            <MenuItem value="">
                                                <em>Select Nationality</em>
                                            </MenuItem>
                                            <MenuItem value="AF">Afghanistan</MenuItem>
                                            <MenuItem value="AX">Aland Islands</MenuItem>
                                            <MenuItem value="AL">Albania</MenuItem>
                                            <MenuItem value="DZ">Algeria</MenuItem>
                                            <MenuItem value="AS">American Samoa</MenuItem>
                                            <MenuItem value="AD">Andorra</MenuItem>
                                            <MenuItem value="AO">Angola</MenuItem>
                                            <MenuItem value="AI">Anguilla</MenuItem>
                                            <MenuItem value="AQ">Antarctica</MenuItem>
                                            <MenuItem value="AG">Antigua and Barbuda</MenuItem>
                                            <MenuItem value="AR">Argentina</MenuItem>
                                            <MenuItem value="AM">Armenia</MenuItem>
                                            <MenuItem value="AW">Aruba</MenuItem>
                                            <MenuItem value="AU">Australia</MenuItem>
                                            <MenuItem value="AT">Austria</MenuItem>
                                            <MenuItem value="AZ">Azerbaijan</MenuItem>
                                            <MenuItem value="BS">Bahamas</MenuItem>
                                            <MenuItem value="BH">Bahrain</MenuItem>
                                            <MenuItem value="BD">Bangladesh</MenuItem>
                                            <MenuItem value="BB">Barbados</MenuItem>
                                            <MenuItem value="BY">Belarus</MenuItem>
                                            <MenuItem value="BE">Belgium</MenuItem>
                                            <MenuItem value="BZ">Belize</MenuItem>
                                            <MenuItem value="BJ">Benin</MenuItem>
                                            <MenuItem value="BM">Bermuda</MenuItem>
                                            <MenuItem value="BT">Bhutan</MenuItem>
                                            <MenuItem value="BO">Bolivia</MenuItem>
                                            <MenuItem value="BQ">Bonaire, Sint Eustatius and Saba</MenuItem>
                                            <MenuItem value="BA">Bosnia and Herzegovina</MenuItem>
                                            <MenuItem value="BW">Botswana</MenuItem>
                                            <MenuItem value="BV">Bouvet Island</MenuItem>
                                            <MenuItem value="BR">Brazil</MenuItem>
                                            <MenuItem value="IO">British Indian Ocean Territory</MenuItem>
                                            <MenuItem value="BN">Brunei Darussalam</MenuItem>
                                            <MenuItem value="BG">Bulgaria</MenuItem>
                                            <MenuItem value="BF">Burkina Faso</MenuItem>
                                            <MenuItem value="BI">Burundi</MenuItem>
                                            <MenuItem value="KH">Cambodia</MenuItem>
                                            <MenuItem value="CM">Cameroon</MenuItem>
                                            <MenuItem value="CA">Canada</MenuItem>
                                            <MenuItem value="CV">Cape Verde</MenuItem>
                                            <MenuItem value="KY">Cayman Islands</MenuItem>
                                            <MenuItem value="CF">Central African Republic</MenuItem>
                                            <MenuItem value="TD">Chad</MenuItem>
                                            <MenuItem value="CL">Chile</MenuItem>
                                            <MenuItem value="CN">China</MenuItem>
                                            <MenuItem value="CX">Christmas Island</MenuItem>
                                            <MenuItem value="CC">Cocos (Keeling) Islands</MenuItem>
                                            <MenuItem value="CO">Colombia</MenuItem>
                                            <MenuItem value="KM">Comoros</MenuItem>
                                            <MenuItem value="CG">Congo</MenuItem>
                                            <MenuItem value="CD">Congo, Democratic Republic of the Congo</MenuItem>
                                            <MenuItem value="CK">Cook Islands</MenuItem>
                                            <MenuItem value="CR">Costa Rica</MenuItem>
                                            <MenuItem value="CI">Cote D'Ivoire</MenuItem>
                                            <MenuItem value="HR">Croatia</MenuItem>
                                            <MenuItem value="CU">Cuba</MenuItem>
                                            <MenuItem value="CW">Curacao</MenuItem>
                                            <MenuItem value="CY">Cyprus</MenuItem>
                                            <MenuItem value="CZ">Czech Republic</MenuItem>
                                            <MenuItem value="DK">Denmark</MenuItem>
                                            <MenuItem value="DJ">Djibouti</MenuItem>
                                            <MenuItem value="DM">Dominica</MenuItem>
                                            <MenuItem value="DO">Dominican Republic</MenuItem>
                                            <MenuItem value="EC">Ecuador</MenuItem>
                                            <MenuItem value="EG">Egypt</MenuItem>
                                            <MenuItem value="SV">El Salvador</MenuItem>
                                            <MenuItem value="GQ">Equatorial Guinea</MenuItem>
                                            <MenuItem value="ER">Eritrea</MenuItem>
                                            <MenuItem value="EE">Estonia</MenuItem>
                                            <MenuItem value="ET">Ethiopia</MenuItem>
                                            <MenuItem value="FK">Falkland Islands (Malvinas)</MenuItem>
                                            <MenuItem value="FO">Faroe Islands</MenuItem>
                                            <MenuItem value="FJ">Fiji</MenuItem>
                                            <MenuItem value="FI">Finland</MenuItem>
                                            <MenuItem value="FR">France</MenuItem>
                                            <MenuItem value="GF">French Guiana</MenuItem>
                                            <MenuItem value="PF">French Polynesia</MenuItem>
                                            <MenuItem value="TF">French Southern Territories</MenuItem>
                                            <MenuItem value="GA">Gabon</MenuItem>
                                            <MenuItem value="GM">Gambia</MenuItem>
                                            <MenuItem value="GE">Georgia</MenuItem>
                                            <MenuItem value="DE">Germany</MenuItem>
                                            <MenuItem value="GH">Ghana</MenuItem>
                                            <MenuItem value="GI">Gibraltar</MenuItem>
                                            <MenuItem value="GR">Greece</MenuItem>
                                            <MenuItem value="GL">Greenland</MenuItem>
                                            <MenuItem value="GD">Grenada</MenuItem>
                                            <MenuItem value="GP">Guadeloupe</MenuItem>
                                            <MenuItem value="GU">Guam</MenuItem>
                                            <MenuItem value="GT">Guatemala</MenuItem>
                                            <MenuItem value="GG">Guernsey</MenuItem>
                                            <MenuItem value="GN">Guinea</MenuItem>
                                            <MenuItem value="GW">Guinea-Bissau</MenuItem>
                                            <MenuItem value="GY">Guyana</MenuItem>
                                            <MenuItem value="HT">Haiti</MenuItem>
                                            <MenuItem value="HM">Heard Island and Mcdonald Islands</MenuItem>
                                            <MenuItem value="VA">Holy See (Vatican City State)</MenuItem>
                                            <MenuItem value="HN">Honduras</MenuItem>
                                            <MenuItem value="HK">Hong Kong</MenuItem>
                                            <MenuItem value="HU">Hungary</MenuItem>
                                            <MenuItem value="IS">Iceland</MenuItem>
                                            <MenuItem value="IN">India</MenuItem>
                                            <MenuItem value="ID">Indonesia</MenuItem>
                                            <MenuItem value="IR">Iran, Islamic Republic of</MenuItem>
                                            <MenuItem value="IQ">Iraq</MenuItem>
                                            <MenuItem value="IE">Ireland</MenuItem>
                                            <MenuItem value="IM">Isle of Man</MenuItem>
                                            <MenuItem value="IL">Israel</MenuItem>
                                            <MenuItem value="IT">Italy</MenuItem>
                                            <MenuItem value="JM">Jamaica</MenuItem>
                                            <MenuItem value="JP">Japan</MenuItem>
                                            <MenuItem value="JE">Jersey</MenuItem>
                                            <MenuItem value="JO">Jordan</MenuItem>
                                            <MenuItem value="KZ">Kazakhstan</MenuItem>
                                            <MenuItem value="KE">Kenya</MenuItem>
                                            <MenuItem value="KI">Kiribati</MenuItem>
                                            <MenuItem value="KP">Korea, Democratic People's Republic of</MenuItem>
                                            <MenuItem value="KR">Korea, Republic of</MenuItem>
                                            <MenuItem value="XK">Kosovo</MenuItem>
                                            <MenuItem value="KW">Kuwait</MenuItem>
                                            <MenuItem value="KG">Kyrgyzstan</MenuItem>
                                            <MenuItem value="LA">Lao People's Democratic Republic</MenuItem>
                                            <MenuItem value="LV">Latvia</MenuItem>
                                            <MenuItem value="LB">Lebanon</MenuItem>
                                            <MenuItem value="LS">Lesotho</MenuItem>
                                            <MenuItem value="LR">Liberia</MenuItem>
                                            <MenuItem value="LY">Libyan Arab Jamahiriya</MenuItem>
                                            <MenuItem value="LI">Liechtenstein</MenuItem>
                                            <MenuItem value="LT">Lithuania</MenuItem>
                                            <MenuItem value="LU">Luxembourg</MenuItem>
                                            <MenuItem value="MO">Macao</MenuItem>
                                            <MenuItem value="MK">Macedonia, the Former Yugoslav Republic of</MenuItem>
                                            <MenuItem value="MG">Madagascar</MenuItem>
                                            <MenuItem value="MW">Malawi</MenuItem>
                                            <MenuItem value="MY">Malaysia</MenuItem>
                                            <MenuItem value="MV">Maldives</MenuItem>
                                            <MenuItem value="ML">Mali</MenuItem>
                                            <MenuItem value="MT">Malta</MenuItem>
                                            <MenuItem value="MH">Marshall Islands</MenuItem>
                                            <MenuItem value="MQ">Martinique</MenuItem>
                                            <MenuItem value="MR">Mauritania</MenuItem>
                                            <MenuItem value="MU">Mauritius</MenuItem>
                                            <MenuItem value="YT">Mayotte</MenuItem>
                                            <MenuItem value="MX">Mexico</MenuItem>
                                            <MenuItem value="FM">Micronesia, Federated States of</MenuItem>
                                            <MenuItem value="MD">Moldova, Republic of</MenuItem>
                                            <MenuItem value="MC">Monaco</MenuItem>
                                            <MenuItem value="MN">Mongolia</MenuItem>
                                            <MenuItem value="ME">Montenegro</MenuItem>
                                            <MenuItem value="MS">Montserrat</MenuItem>
                                            <MenuItem value="MA">Morocco</MenuItem>
                                            <MenuItem value="MZ">Mozambique</MenuItem>
                                            <MenuItem value="MM">Myanmar</MenuItem>
                                            <MenuItem value="NA">Namibia</MenuItem>
                                            <MenuItem value="NR">Nauru</MenuItem>
                                            <MenuItem value="NP">Nepal</MenuItem>
                                            <MenuItem value="NL">Netherlands</MenuItem>
                                            <MenuItem value="AN">Netherlands Antilles</MenuItem>
                                            <MenuItem value="NC">New Caledonia</MenuItem>
                                            <MenuItem value="NZ">New Zealand</MenuItem>
                                            <MenuItem value="NI">Nicaragua</MenuItem>
                                            <MenuItem value="NE">Niger</MenuItem>
                                            <MenuItem value="NG">Nigeria</MenuItem>
                                            <MenuItem value="NU">Niue</MenuItem>
                                            <MenuItem value="NF">Norfolk Island</MenuItem>
                                            <MenuItem value="MP">Northern Mariana Islands</MenuItem>
                                            <MenuItem value="NO">Norway</MenuItem>
                                            <MenuItem value="OM">Oman</MenuItem>
                                            <MenuItem value="PK">Pakistan</MenuItem>
                                            <MenuItem value="PW">Palau</MenuItem>
                                            <MenuItem value="PS">Palestinian Territory, Occupied</MenuItem>
                                            <MenuItem value="PA">Panama</MenuItem>
                                            <MenuItem value="PG">Papua New Guinea</MenuItem>
                                            <MenuItem value="PY">Paraguay</MenuItem>
                                            <MenuItem value="PE">Peru</MenuItem>
                                            <MenuItem value="PH">Philippines</MenuItem>
                                            <MenuItem value="PN">Pitcairn</MenuItem>
                                            <MenuItem value="PL">Poland</MenuItem>
                                            <MenuItem value="PT">Portugal</MenuItem>
                                            <MenuItem value="PR">Puerto Rico</MenuItem>
                                            <MenuItem value="QA">Qatar</MenuItem>
                                            <MenuItem value="RE">Reunion</MenuItem>
                                            <MenuItem value="RO">Romania</MenuItem>
                                            <MenuItem value="RU">Russian Federation</MenuItem>
                                            <MenuItem value="RW">Rwanda</MenuItem>
                                            <MenuItem value="BL">Saint Barthelemy</MenuItem>
                                            <MenuItem value="SH">Saint Helena</MenuItem>
                                            <MenuItem value="KN">Saint Kitts and Nevis</MenuItem>
                                            <MenuItem value="LC">Saint Lucia</MenuItem>
                                            <MenuItem value="MF">Saint Martin</MenuItem>
                                            <MenuItem value="PM">Saint Pierre and Miquelon</MenuItem>
                                            <MenuItem value="VC">Saint Vincent and the Grenadines</MenuItem>
                                            <MenuItem value="WS">Samoa</MenuItem>
                                            <MenuItem value="SM">San Marino</MenuItem>
                                            <MenuItem value="ST">Sao Tome and Principe</MenuItem>
                                            <MenuItem value="SA">Saudi Arabia</MenuItem>
                                            <MenuItem value="SN">Senegal</MenuItem>
                                            <MenuItem value="RS">Serbia</MenuItem>
                                            <MenuItem value="CS">Serbia and Montenegro</MenuItem>
                                            <MenuItem value="SC">Seychelles</MenuItem>
                                            <MenuItem value="SL">Sierra Leone</MenuItem>
                                            <MenuItem value="SG">Singapore</MenuItem>
                                            <MenuItem value="SX">Sint Maarten</MenuItem>
                                            <MenuItem value="SK">Slovakia</MenuItem>
                                            <MenuItem value="SI">Slovenia</MenuItem>
                                            <MenuItem value="SB">Solomon Islands</MenuItem>
                                            <MenuItem value="SO">Somalia</MenuItem>
                                            <MenuItem value="ZA">South Africa</MenuItem>
                                            <MenuItem value="GS">South Georgia and the South Sandwich Islands</MenuItem>
                                            <MenuItem value="SS">South Sudan</MenuItem>
                                            <MenuItem value="ES">Spain</MenuItem>
                                            <MenuItem value="LK">Sri Lanka</MenuItem>
                                            <MenuItem value="SD">Sudan</MenuItem>
                                            <MenuItem value="SR">Suriname</MenuItem>
                                            <MenuItem value="SJ">Svalbard and Jan Mayen</MenuItem>
                                            <MenuItem value="SZ">Swaziland</MenuItem>
                                            <MenuItem value="SE">Sweden</MenuItem>
                                            <MenuItem value="CH">Switzerland</MenuItem>
                                            <MenuItem value="SY">Syrian Arab Republic</MenuItem>
                                            <MenuItem value="TW">Taiwan, Province of China</MenuItem>
                                            <MenuItem value="TJ">Tajikistan</MenuItem>
                                            <MenuItem value="TZ">Tanzania, United Republic of</MenuItem>
                                            <MenuItem value="TH">Thailand</MenuItem>
                                            <MenuItem value="TL">Timor-Leste</MenuItem>
                                            <MenuItem value="TG">Togo</MenuItem>
                                            <MenuItem value="TK">Tokelau</MenuItem>
                                            <MenuItem value="TO">Tonga</MenuItem>
                                            <MenuItem value="TT">Trinidad and Tobago</MenuItem>
                                            <MenuItem value="TN">Tunisia</MenuItem>
                                            <MenuItem value="TR">Turkey</MenuItem>
                                            <MenuItem value="TM">Turkmenistan</MenuItem>
                                            <MenuItem value="TC">Turks and Caicos Islands</MenuItem>
                                            <MenuItem value="TV">Tuvalu</MenuItem>
                                            <MenuItem value="UG">Uganda</MenuItem>
                                            <MenuItem value="UA">Ukraine</MenuItem>
                                            <MenuItem value="AE">United Arab Emirates</MenuItem>
                                            <MenuItem value="GB">United Kingdom</MenuItem>
                                            <MenuItem value="US">United States</MenuItem>
                                            <MenuItem value="UM">United States Minor Outlying Islands</MenuItem>
                                            <MenuItem value="UY">Uruguay</MenuItem>
                                            <MenuItem value="UZ">Uzbekistan</MenuItem>
                                            <MenuItem value="VU">Vanuatu</MenuItem>
                                            <MenuItem value="VE">Venezuela</MenuItem>
                                            <MenuItem value="VN">Viet Nam</MenuItem>
                                            <MenuItem value="VG">Virgin Islands, British</MenuItem>
                                            <MenuItem value="VI">Virgin Islands, U.s.</MenuItem>
                                            <MenuItem value="WF">Wallis and Futuna</MenuItem>
                                            <MenuItem value="EH">Western Sahara</MenuItem>
                                            <MenuItem value="YE">Yemen</MenuItem>
                                            <MenuItem value="ZM">Zambia</MenuItem>
                                            <MenuItem value="ZW">Zimbabwe</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {/* .input-item */}
                            </div>
                            {/* .col */}
                        </div>
                        {/* .row */}
                        <div className="gaps-1x" />
                        {/* 10px gap */}
                        <div className="d-sm-flex justify-content-between align-items-center">
                            <div className="gaps-2x d-sm-none" />
                            {submitted === 200 ? <span className="color-success">
                                <em className="ti ti-check-box" /> All Changes are saved
                            </span> : ''}
                        </div>
                    </form>
                    {/* form */}
                </div>

                {/* .tab-panel wallet */}
                <div className="tab-pane fade" id="wallet-address">
                    <form action="#">
                        {/* .row */}
                        <div className="input-item input-with-label">
                            <TextField defaultValue={data?.WalletID} value={data?.WalletID} id="tocken-address" label={translate('wallet-address')} className='input-bordered' />
                        </div>
                        <div align='right'>
                            <button className="btn-primary" onClick={() => copyWalletAddress()}>{translate('Copy-to-clipboard')}</button>
                        </div>
                        <p>
                            {translate('wallet-address-message')}
                        </p>
                        <div className="gaps-2x" />

                        {/* .row */}
                        {data?.Coupon &&
                            <div>
                                <div className="input-item input-with-label">
                                    <TextField defaultValue={data?.Coupon} value={data?.Coupon} id="coupon-code" label={translate('coupon-code')} className='input-bordered' />
                                </div>
                                <div align='right'>
                                    <button className="btn-primary" onClick={() => copyReferralCode()}>{translate('Copy-to-clipboard')}</button>
                                </div>
                                <div className="gaps-1x" />

                                <div className="input-item input-with-label">
                                    <TextField defaultValue={data?.Coupon} value={CLIENT_URL + "/signup?coupon=" + data?.Coupon} id="coupon-address" label={translate('coupon-address')} className='input-bordered' />
                                </div>
                                <div align='right'>
                                    <button className="btn-primary" onClick={() => copyReferralUrl()}>{translate('Copy-to-clipboard')}</button>
                                </div>
                                <div className="gaps-1x" />
                                <p>
                                    {translate('referralcode-message')}
                                </p>
                                <p>
                                    <b>{translate('referralcode-title')}</b>
                                </p>
                                <p>
                                    {translate('referralcode-details')}
                                </p>
                                <p>
                                    <b>{translate('referralbonus-title')}</b>
                                </p>
                                <p>
                                    {translate('referralbonus-details')}
                                </p>
                            </div>
                        }
                        {/* .input-item */}
                        <div className="gaps-1x" />
                        {/* 10px gap */}
                        <div className="d-sm-flex justify-content-between align-items-center">
                            <div className="gaps-2x d-sm-none" />
                            <span className="color-success">
                                {isWalletAddressCopied &&
                                    <div>
                                        <em className="ti ti-check-box" /> {translate('wallet-copied-message')}
                                    </div>
                                }
                                {isReferralCodeCopied &&
                                    <div>
                                        <em className="ti ti-check-box" /> {translate('coupon-code-copied-message')}
                                    </div>
                                }
                                {isReferralUrlCopied &&
                                    <div>
                                        <em className="ti ti-check-box" /> {translate('coupon-address-copied-message')}
                                    </div>
                                }                                                                
                            </span>
                        </div>
                    </form>
                    {/* form */}
                </div>

                {/* .tab-pane notification*/}
                <div className="tab-pane fade" id="notification">
                    <p>{translate('notification-header')}</p>
                    <div className="gaps-1x" />
                    <ul className="notification-list">
                        <li>
                            <span>{translate('Notify-latest')}</span>
                            <div className="padl-2x">
                                <Switch {...label} onChange={handleCheck} checked={emailPref} inputProps={{ 'aria-label': 'controlled' }} />
                            </div>
                        </li>
                    </ul>
                    <ul>
                        {translate('notification-footer-note')}
                    </ul>
                    <div className="gaps-4x" />
                    <div className="d-flex justify-content-between align-items-center">
                        <span />
                        <span className="color-success">
                            <em className="ti ti-check-box" /> {translate('update-notification-settings')}
                        </span>
                    </div>
                </div>
                {/* .tab-pane */}
            </div >
            {/* .tab-content */}
        </div >
    );
};

const mapStateToProps = state => {
    return {
        users: state.remote.users
    };
};

export default connect(mapStateToProps, {    
})(Account);
