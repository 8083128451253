import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { actionGetAOSBalance, actionGetCommissionRate, actionGetCashBalance } from "../store/action/remoteAction";
import { getMultiLanguageMessage } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";
import { client } from "../store/action/remoteAction";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;
let BASE_URL = process.env.REACT_APP_SERVER_URL_API;
//const App_Environment = process.env.REACT_APP_ENVIRONMENT;
const App_Environment = "0"; //To use Paymix Gateway for Demo

const OTCSellSettlement = (props) => {
    const [pageId, setPageId] = useState(0);
    const [aosBalance, setAosBalance] = useState(0);
    const [aosRemaining, setAosRemaining] = useState(0);
    const [aos, setAos] = useState(0);
    const [currency, setCurrency] = useState('');
    const [decimalCount, setDecimalCount] = useState(2);
    const [aosSellAmount, setAosSellAmount] = useState(0);
    const [aosRate, setAosRate] = useState(0);
    const [commissionID, setCommissionID] = useState(0);
    const [commissionAmount, setCommissionAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [paymentType, setPaymentType] = useState("");
    const [euroBalance, setEuroBalance] = useState(0);
    const [btcTransactionHash, setBtcTransactionHash] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [settlementTransactionId, setSettlementTransactionID] = useState("");
    const [isPartialOrderFlag, setIsPartialOrderFlag] = useState(false);
    const [language, setLanguage] = useState("");
    const [btcGasFee, setBtcGasFee] = useState(0);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return ("");
    };

    const handleTokenChange = (e) => {
        resetTimout();
        let requestedAos = e.target.value.replace(/[^0-9.]/g, "");

        let nAmount = parseFloat((parseFloat(requestedAos) * aosRate).toFixed(decimalCount));
        setAos(requestedAos);
        if (requestedAos) {
            setAosSellAmount(nAmount);
        }
        calculateCommissionAmount(requestedAos, nAmount, currency);
    }

    const calculateCommissionAmount = (sellQuantity, sellAmount, sellCurrency) => {
        if (!props.commissionRates || props.commissionRates.length <= 0) {
            return;
        }
        let commissionRate = props.commissionRates[0];
        setCommissionID(commissionRate.CommissionID);
        let nCommissionAmount = 0;
        if (commissionRate.CommissionCalcType === 1) {
            nCommissionAmount = parseFloat((parseFloat(sellQuantity) * parseFloat(commissionRate.CommissionValue)).toFixed(2));
        } else if (commissionRate.CommissionCalcType === 2) {
            nCommissionAmount = parseFloat((parseFloat(sellAmount) * (parseFloat(commissionRate.CommissionValue) / 100)).toFixed(2));
        }
        if (!nCommissionAmount) {
            nCommissionAmount = 0;
        }
        setCommissionAmount(nCommissionAmount);
        let nTotalAmount = 0;
        if (sellCurrency === 'BTC') {
            nTotalAmount = sellAmount;
        } else {
            if (parseFloat(sellAmount) > 0) {
                nTotalAmount = (parseFloat(sellAmount) - parseFloat(nCommissionAmount)).toFixed(2);
            }
        }
        setTotalAmount(nTotalAmount);
    };

    const onChangePaymentType = (e) => {
        resetTimout();
        setPaymentType(e.target.value);
    };

    const goToConfirmationPage = async (e) => {
        resetTimout();
        if (!transactionId) {
            alert('Invalid Selection');
            return;
        }
        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0 || aosSellAmount <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aos) > aosRemaining) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (parseFloat(aosBalance) < parseFloat(aos)) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (currency === 'BTC' && paymentType === 'Cash' && parseFloat(euroBalance) < parseFloat(commissionAmount)) {
            alert(getMultiLanguageMessage('msg_InsufficientBalance_Error', language));
            return;
        }

        if (currency === 'BTC') {
            //Get Bitcoin Gas Fee
            let bodyJSON = {
                items: {
                    BuySellFlag: 8,
                    Amount: aosSellAmount,
                    UnitPrice: aosRate,
                    Quantity: aos,
                    UserId: localStorage.getItem("uid"),
                    Tid: transactionId,
                    OtcFlag: 1,
                    CommissionID: commissionID,
                    CommissionAmount: commissionAmount,
                    PaymentType: paymentType === 'Cash' ? 1 : 2,
                    ToWalletID: ''
                }
            };
            setIsButtonClicked(true);
            await client(localStorage.getItem("@token")).post(`${BASE_URL}/transactions/btc/gas`, bodyJSON).then((response) => {
                setIsButtonClicked(false);
                if (response.data) {
                    if (response.data.ErrorCode == 1) {
                        alert(response.data.ErrorMessage);
                    } else {
                        setSettlementTransactionID(response.data.TransactionID);
                        setBtcGasFee(response.data.GasFee);
                        setBtcTransactionHash(response.data.TransactionHash);
                        setPageId(2);
                    }
                } else {
                    alert("Error! Please contact customer support");
                }
            }).catch(error => {
                setIsButtonClicked(false);
                alert("Error! Please contact customer support");
                console.log(error);
            });
        } else {
            setPageId(2);
        }
    };

    const goToPrevPage = (e) => {
        resetTimout();
        setPageId(1);
    };


    const goToCheckout = async (e) => {
        resetTimout();
        e.preventDefault();
        let nPaymentType = 0;

        if (!transactionId) {
            alert('Invalid Selection');
            return;
        }
        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0 || aosSellAmount <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aos) > aosRemaining) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (parseFloat(aosBalance) < parseFloat(aos)) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }

        if (currency === 'BTC') {
            if (paymentType === 'Cash') {
                nPaymentType = 1;
            } else if (paymentType === 'CreditCard') {
                nPaymentType = 2;
            }
        }

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            let userId = localStorage.getItem("uid");
            setIsButtonClicked(true);
            const bodyJSON = {
                UserId: userId,
                CryptoAsset: "AOS",
                BuySellFlag: 8,
                Currency: currency,
                FromWalletId: userId,
                ToWalletID: "",
                Quantity: "" + aos + "",
                UnitPrice: aosRate,
                Amount: aosSellAmount,
                OtherGasFee: btcGasFee,
                PaymentType: nPaymentType,
                CommissionID: commissionID,
                CommissionAmount: commissionAmount,
                OtcFlag: 1,
                Tid: transactionId,
                PartialOrderFlag: 0,
                TransactionHash: btcTransactionHash,
                SettlementTransactionID: settlementTransactionId
            };

            if (currency === 'BTC') {
                let serverUrl = '';
                if (paymentType === 'CreditCard') {
                    serverUrl = SERVER_URL + `/btc-sellsettlement-otc-checkout`;
                } else if (paymentType === 'Cash') {
                    serverUrl = SERVER_URL + `/btc-sellsettlement-otc-cashbalance`;
                }

                if (serverUrl) {
                    setIsButtonClicked(true);
                    fetch(serverUrl, {
                        method: 'POST',
                        redirect: 'follow',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(bodyJSON),
                    })
                        .then(async res => {
                            if (res.ok) {
                                return res.json();
                            } else {
                                const json = await res.json();
                                return await Promise.reject(json);
                            }
                        })
                        .then((body) => {
                            window.location.href = body.url;
                        })
                        .catch((error) => {
                            let errorMessage = "Error: ";
                            if (error && error.message) {
                                errorMessage += error.message;
                            } else {
                                errorMessage += JSON.stringify(error);
                            }
                            alert(errorMessage);
                            setIsButtonClicked(false);
                        });
                }
            } else {
                await client(localStorage.getItem("@token")).post(`${BASE_URL}/transactions`, bodyJSON).then((response) => {
                    setAos(0);
                    setAosSellAmount(0);
                    window.location.href = '/transactions';
                }).catch(error => {
                    if (error.message) {
                        alert(error.message);
                    } else {
                        alert("Error! Please contact customer support");
                    }
                    console.log(error);
                    setIsButtonClicked(false);
                });
            }
        }

    };

    const getOrderDetails = async (tid) => {
        if (!tid) {
            return;
        }
        client(localStorage.getItem("@token")).get(`${BASE_URL}/transactions/otc/${tid}`).then((response) => {
            if (response.status == 200 && response.data.length === 1) {
                let txnSell = response.data[0];
                if (parseFloat(txnSell.RemainingQuantity) > 0) {
                    setAos(parseFloat(txnSell.RemainingQuantity).toFixed(2));
                    setAosRemaining(parseFloat(txnSell.RemainingQuantity).toFixed(2));
                    setCurrency(txnSell.Currency);
                    setDecimalCount(txnSell.DecimalCount);
                    setAosRate(parseFloat(parseFloat(txnSell.UnitPrice).toFixed(txnSell.DecimalCount)));
                    let nAmount = parseFloat((parseFloat(txnSell.UnitPrice) * parseFloat(txnSell.RemainingQuantity)).toFixed(txnSell.DecimalCount));
                    setAosSellAmount(nAmount);
                    setTransactionId(txnSell.TransactionID);
                    calculateCommissionAmount(parseFloat(txnSell.RemainingQuantity).toFixed(2), nAmount, txnSell.Currency);
                    if (txnSell.PartialOrderFlag === 1) {
                        setIsPartialOrderFlag(true);
                    } else {
                        setIsPartialOrderFlag(false);
                    }
                    if (txnSell.Currency === 'BTC') {
                        props.actionGetCashBalance();
                    }
                    setPageId(1);
                } else {
                    handleOrderError();
                }
            } else {
                handleOrderError();
            }
        }).catch(error => {
            handleOrderError();
            console.log(error);
        });
    };

    const handleOrderError = () => {
        let sLanguage = localStorage.getItem("locale");
        alert(getMultiLanguageMessage('msg_InvalidSellOrder', sLanguage));
    };

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        //actionGetUserProfile request send from Navbar
        let tid = getQueryVariable("tid");
        if (tid && tid.length > 0) {
            setTransactionId(tid);
            props.actionGetAOSBalance();
        } else {
            alert('Invalid Selection');
        }
    }, []);

    useEffect(() => {
        if (props?.users && props.users.length > 0) {
        }
    }, [props.users]);

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        if (props?.userBalances?.length > 0) {
            props.userBalances[0]?.Balance ? setAosBalance((props.userBalances[0]?.Balance).toFixed(2)) : setAosBalance(0);
        }
    }, [props.userBalances]);

    useEffect(() => {
        if (props.cashBalances?.length > 0) {
            let userBalance = props.cashBalances[0];
            setEuroBalance(userBalance.Balance);
        }
    }, [props.cashBalances]);

    useEffect(() => {
        if (transactionId) {
            props.actionGetCommissionRate(8);
        }
    }, [transactionId]);

    useEffect(() => {
        if (transactionId) {
            getOrderDetails(transactionId);
        }
    }, [props.commissionRates]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-OTCSell-Settlement')}</h2>
            {pageId === 1 &&
                <h2 className="input-item-label">{translate('AOS-Token-Balance')} = {aosBalance}</h2>
            }
            <div className="gaps-1x" />

            {pageId === 1 &&
                <div>
                    <div className="gaps-1x" />
                    <div>
                        <TextField
                            size="small"
                            label={translate('Order-Quantity')}
                            id="outlined-start-adornment-aos"
                            sx={{ width: '24.5ch' }}
                            value={aos}
                            onChange={handleTokenChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">AOS</InputAdornment>,
                            }}
                            disabled={isPartialOrderFlag ? false : true}
                        />
                    </div>

                    <div className="gaps-2x" />
                    <div>
                        <TextField
                            size="small"
                            label={translate('Rate')}
                            id="outlined-start-adornment-rate"
                            sx={{ width: '24.5ch' }}
                            value={aosRate}
                            defaultValue={aosRate}
                            disabled={true}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</InputAdornment>,
                            }}
                        />
                    </div>

                    <div className="gaps-1x" />
                    <div>
                        <TextField
                            size="small"
                            label={translate('Commission-Amount')}
                            id="commission-amount"
                            sx={{ width: '24.5ch' }}
                            value={commissionAmount}
                            defaultValue={commissionAmount}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{translate('Euro')}</InputAdornment>,
                            }}
                            disabled={true}
                        />
                    </div>

                    <div className="gaps-1x" />
                    <div>
                        <TextField
                            size="small"
                            label={translate('Payment-Amount')}
                            id="outlined-start-adornment-euro"
                            sx={{ width: '24.5ch' }}
                            value={totalAmount}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</InputAdornment>,
                            }}
                            disabled={true}
                        />
                    </div>

                    <div className="gaps-1x" />
                    {currency === 'BTC' &&
                        <>
                            <label className="input-item-label">{translate('Commission-Payment-Type')}</label>
                            <div>
                                <input className="payment-Type" type="radio" value="Cash" name="paymentType" onChange={onChangePaymentType} checked={paymentType === 'Cash' ? true : false} /> {translate('Payment-By-Cash')}
                                <input className="payment-Type" type="radio" value="CreditCard" name="paymentType" onChange={onChangePaymentType} checked={paymentType === 'CreditCard' ? true : false} /> {translate('Payment-By-CreditCard')}
                            </div>
                        </>
                    }

                    {paymentType === 'Cash' &&
                        <div>
                            <div className="col-md-8">
                                <label className="input-item-label">{translate('Cash-Balance')}&nbsp;:&nbsp;{euroBalance} {translate('Euro')}</label>
                            </div>
                        </div>
                    }

                    <div className="gaps-2x" />
                    <a
                        href="#"
                        className="btn btn-primary payment-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#tranxETH"
                        onClick={event => goToConfirmationPage(event)}
                    >
                        {translate('To-Confirm-Page')}
                    </a>
                </div>
            }

            {pageId === 2 &&
                <div>
                    <table width='70%'>
                        <tbody>
                            <tr>
                                <td align='left' >{translate('Order-Quantity')} :</td>
                                <td align='right' >{aos} AOS</td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Currency')} :</td>
                                <td align='right'>{currency === 'BTC' ? translate('Currency-Bitcoin') : translate('Currency-Euro')} </td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Rate')} :</td>
                                <td align='right'>{aosRate} {currency === 'BTC' ? translate('Bitcoin-Aos') : translate('Euro-Aos')} </td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Amount')} :</td>
                                <td align='right'>{aosSellAmount} {currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</td>
                            </tr>
                            <tr>
                                <td align='left'>{translate('Commission-Amount')} :</td>
                                <td align='right'>{commissionAmount} {translate('Euro')}</td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Payment-Amount')} :</td>
                                <td align='right'>{totalAmount} {currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</td>
                            </tr>
                            {currency === 'BTC' &&
                                <tr>
                                    <td align='left'>{currency === 'BTC' ? translate('Commission-Payment-Type') : translate('Payment-Type')} :</td>
                                    <td align='right'>{paymentType === 'CreditCard' ? translate('Payment-By-CreditCard') : translate('Payment-By-WalletBalance')} </td>
                                </tr>
                            }
                        </tbody>
                    </table>

                    {isButtonClicked &&
                        <>
                            <div className="gaps-1x" />
                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                        </>
                    }
                    <div className="gaps-1x" />
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => goToPrevPage(event)}>{translate('Back-Btn')}</button>&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => goToCheckout(event)}>{translate('Submit')}</button>
                    <div className="gaps-1x" />
                </div>
            }
        </div>

    );
};

const mapStateToProps = state => {
    return {
        transactions: state.remote.otctransactions,
        userBalances: state.remote.userBalances,
        cashBalances: state.remote.cashBalances,
        users: state.remote.users,
        promoter: state.remote.promoter,
        commissionRates: state.remote.commissionRates,
    };
};

export default connect(mapStateToProps, {
    actionGetAOSBalance,
    actionGetCommissionRate,
    actionGetCashBalance
})(OTCSellSettlement);
