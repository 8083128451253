import {combineReducers} from "redux";
import layoutReducer from "./layoutReducer";
import remoteReducer from "./remoteReducer";

const rootReducer = combineReducers({
    siteLayout: layoutReducer,
    remote: remoteReducer,
});

export default rootReducer;
