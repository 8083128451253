import React, {useEffect} from 'react';
import {actionToggleFooter, actionToggleNavBar, actionToggleSideBar} from "../store/action/layoutAction";
import connect from "react-redux/es/components/connect";

const EmailConfirmation = (props) => {

    useEffect(() => {
        props.actionToggleNavBar(false);
        props.actionToggleSideBar(false);
        props.actionToggleFooter(false);
    }, [props]);

    return (
        <div className="user-ath-page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 text-center">
                        <div className="user-ath-logo">
                            <a href="/">
                                <img
                                    src={require('../assets/images/logo.PNG')}
                                    srcSet={require('../assets/images/logo.PNG')}
                                    alt="icon"
                                />
                            </a>
                        </div>
                        <div className="user-ath-box">
                            <div className="note note-no-icon note-success">
                                <p>Your email is successfull verified.</p>
                            </div>
                            <div className="gaps-2x"/>
                            <span className="small-heading">You can Login Now</span>
                            <a href="/login" className="btn btn-primary">
                                Login
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
    };
};
export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter
})(EmailConfirmation);
