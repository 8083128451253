import React, { useState, useEffect } from 'react';
import translate from "../i18n/translate";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import connect from "react-redux/es/components/connect";

const App_Environment = process.env.REACT_APP_ENVIRONMENT;

const DepositDetails = (props) => {
 
    const goToDepositEntryPage = (e) => {
        window.location.href = '/demodeposit';
    };

    useEffect(() => {
        props.actionToggleNavBar(true);
        props.actionToggleSideBar(true);
        props.actionToggleFooter(true);
    }, []);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Title-Deposit')}</h2>
            <div className="gaps-1x" />

            {App_Environment === "2" &&
                <div className='Message-Red'>
                    {translate('Demo-DepositAccount-message')}
                </div>
            }

            <table>
                <tr className='tr-type1'>
                    <td className='td-type1-col1'>{translate('IBAN')}</td>
                    <td className='td-type1-col2'>XXXXXX000100010001</td>
                </tr>
                <tr className='tr-type1'>
                    <td className='td-type1-col1'>{translate('AccountName')}</td>
                    <td className='td-type1-col2'>ABC Company Limited</td>
                </tr>
                <tr className='tr-type1'>
                    <td className='td-type1-col1'>{translate('AccountNo')}</td>
                    <td className='td-type1-col2'>100010001</td>
                </tr>
                <tr className='tr-type1'>
                    <td className='td-type1-col1'>{translate('BankName')}</td>
                    <td className='td-type1-col2'>ABC BANK</td>
                </tr>
                <tr className='tr-type1'>
                    <td className='td-type1-col1'>{translate('BankAddress')}</td>
                    <td className='td-type1-col2'>#001, Business Center, Test, Malta</td>
                </tr>
                <tr className='tr-type1'>
                    <td className='td-type1-col1'>{translate('SwiftCode')}</td>
                    <td className='td-type1-col2'>ABCABCXXX</td>
                </tr>
                <tr className='tr-type1'>
                    <td className='td-type1-col1'>{translate('SenderName')}</td>
                    <td className='td-type1-col2 Message-Red'>{translate('SenderName-Note')}</td>
                </tr>
            </table>

            <p>
                {translate('Deposit-Message1')}
            </p>
            <p>
                {translate('Deposit-Message2')}
            </p>
            <p>
                {translate('Deposit-Message3')}
            </p>

            <div>
                <div className="gaps-1x" />
            </div>

            {App_Environment === "2" &&
                <button className="btn btn-primary" onClick={goToDepositEntryPage}>{translate('button-Deposit')}</button>
            }

            {/* form */}
        </div >
    );
};

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
})(DepositDetails);
