import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Button from '@mui/material/Button';
import translate from "../i18n/translate";
import { client } from "../store/action/remoteAction";
import { storage, resetTimout } from "./common/Firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import connect from "react-redux/es/components/connect";
import { formatDate } from "../store/action/remoteAction";
import { getMultiLanguageMessage } from '../i18n/multiLanguage';

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;
const App_Environment = process.env.REACT_APP_ENVIRONMENT;

const KycDetails = (props) => {
    const [passport, setPassport] = useState("");
    const [passport2, setPassport2] = useState("");
    const [licence, setLicence] = useState("");
    const [licence2, setLicence2] = useState("");
    const [national, setNational] = useState("");
    const [national2, setNational2] = useState("");
    const [photo1, setPhoto1] = useState("");
    const [photo2, setPhoto2] = useState("");
    const [passportUrl, setPassportUrl] = useState("");
    const [passport2Url, setPassport2Url] = useState("");
    const [licenceUrl, setLicenceUrl] = useState("");
    const [licence2Url, setLicence2Url] = useState("");
    const [nationalUrl, setNationalUrl] = useState("");
    const [national2Url, setNational2Url] = useState("");
    const [photo1Url, setPhoto1Url] = useState("");
    const [photo2Url, setPhoto2Url] = useState("");
    const [percent, setPercent] = useState(0);
    const [submitted, setSubmitted] = useState(0);
    const trimLength = 10;
    const [kycStatus, setKycStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [language, setLanguage] = useState("");
    const [isAgree, setIsAgree] = useState(false);

    const [dtDOB, setDOB] = useState(null);
    const [dtPassportExpiryDate, setPassportExpiryDate] = useState(null);
    const [dtLicenseExpiryDate, setLicenseExpiryDate] = useState(null);
    const [dtNationalIDExpiryDate, setNationalIDExpiryDate] = useState(null);

    const submitKyc = async () => {
        resetTimout();
        data.DOB = dtDOB ? formatDate(dtDOB) : null;
        data.PassportExpiryDate = dtPassportExpiryDate ? formatDate(dtPassportExpiryDate) : null;
        data.NationalIDExpiryDate = dtNationalIDExpiryDate ? formatDate(dtNationalIDExpiryDate) : null;
        data.LicenseExpiryDate = dtLicenseExpiryDate ? formatDate(dtLicenseExpiryDate) : null;

        if (App_Environment === "1") {
            //Personal details validation
            if (!data.Nationality) {
                alert(getMultiLanguageMessage('msg_EmptyNationality', language));
                return;
            }
            if (data.Nationality === "JP") {
                if (!data.UserNameLast_DByte) {
                    alert(getMultiLanguageMessage('msg_EmptyUserNameLast_DByte', language));
                    return;
                }
                if (!data.UserNameFirst_DByte) {
                    alert(getMultiLanguageMessage('msg_EmptyUserNameFirst_DByte', language));
                    return;
                }
            }
            if (!data.UserNameLast) {
                alert(getMultiLanguageMessage('msg_EmptyUserNameLast', language));
                return;
            }
            if (!data.UserNameFirst) {
                alert(getMultiLanguageMessage('msg_EmptyUserNameFirst', language));
                return;
            }
            if (!data.PhoneNumber1) {
                alert(getMultiLanguageMessage('msg_EmptyPhoneNumber', language));
                return;
            }
            if (!dtDOB) {
                alert(getMultiLanguageMessage('msg_EmptyDOB', language));
                return;
            }
            if (!data.PostalCode) {
                alert(getMultiLanguageMessage('msg_EmptyPostalCode', language));
                return;
            }
            if (!data.Country) {
                alert(getMultiLanguageMessage('msg_EmptyCountry', language));
                return;
            }
            if (!data.Address1) {
                alert(getMultiLanguageMessage('msg_EmptyAddress1', language));
                return;
            }
            if (!data.Address2) {
                alert(getMultiLanguageMessage('msg_EmptyAddress2', language));
                return;
            }
            if (data.Country === "JP") {
                if (!data.Address1_DByte) {
                    alert(getMultiLanguageMessage('msg_EmptyAddress1_DByte', language));
                    return;
                }
                if (!data.Address2_DByte) {
                    alert(getMultiLanguageMessage('msg_EmptyAddress2_DByte', language));
                    return;
                }
            }

            //Document and Profile Photo validation
            if (passportUrl !== "" && !data.PassportNumber) {
                alert(getMultiLanguageMessage('msg_EmptyPassportNumber', language));
                return;
            }
            if (passportUrl !== "" && !data.PassportExpiryDate) {
                alert(getMultiLanguageMessage('msg_EmptyPassportExpiryDate', language));
                return;
            } else if (passportUrl === "") {
                data.PassportExpiryDate = null;
            }
            if (passport2Url !== "" && passportUrl == "") {
                alert(getMultiLanguageMessage('msg_EmptyPassportFront', language));
                return;
            }
            if (nationalUrl !== "" && !data.NationalIDNumber) {
                alert(getMultiLanguageMessage('msg_EmptyNationalIDNumber', language));
                return;
            }
            if (nationalUrl !== "" && !data.NationalIDExpiryDate) {
                alert(getMultiLanguageMessage('msg_EmptyNationalIDExpiryDate', language));
                return;
            } else if (nationalUrl === "") {
                data.NationalIDExpiryDate = null;
            }
            if (national2Url !== "" && nationalUrl == "") {
                alert(getMultiLanguageMessage('msg_EmptyNationalIDFront', language));
                return;
            }
            if (licenceUrl !== "" && !data.LicenseNumber) {
                alert(getMultiLanguageMessage('msg_EmptyLicenseNumber', language));
                return;
            }
            if (licenceUrl !== "" && !data.LicenseExpiryDate) {
                alert(getMultiLanguageMessage('msg_EmptyLicenseExpiryDate', language));
                return;
            } else if (licenceUrl === "") {
                data.LicenseExpiryDate = null;
            }
            if (licence2Url !== "" && licenceUrl == "") {
                alert(getMultiLanguageMessage('msg_EmptyLicenseFront', language));
                return;
            }

            if (photo1Url === "") {
                alert(getMultiLanguageMessage('msg_EmptyApplicantPhoto', language));
                return;
            }
            if (photo2Url === "") {
                alert(getMultiLanguageMessage('msg_EmptyProfilePhoto', language));
                return;
            }
        } else if (App_Environment === "2") {
            if (!data.UserNameFirst) {
                data.UserNameFirst = "DemoUser";
            }
            if (!data.UserNameLast) {
                if (data.ClientID) {
                    data.UserNameLast = data.ClientID;
                } else {
                    data.UserNameLast = uid;
                }
            }
        }
        let uid = localStorage.getItem("uid");

        if (!isAgree) {
            alert(getMultiLanguageMessage('msg_ChangeRequest_Agree', language));
            return;
        }

        data.UpdatedByUser = '';
        data.Remarks = '';        
        let result = !kycStatus ? await client(localStorage.getItem("@token")).post(`${BASE_URL}/userdetails`, data).then((response) => {
            setSubmitted(parseInt(response.status));
        }).catch(error => {
            setSubmitted(500);
            console.log(error)
        }) :
            await client(localStorage.getItem("@token")).put(`${BASE_URL}/userdetails/` + uid, data).then((response) => {
                console.log('Result: ' + response);
                setSubmitted(parseInt(response.status));
            }).catch(error => {
                setSubmitted(500);
                console.log(error)
            });
    }

    function handleChange(event) {
        resetTimout();
        switch (event.target.name) {
            case 'passport-photo': setPassport(event.target.files[0]);
                break;
            case 'passport2-photo': setPassport2(event.target.files[0]);
                break;
            case 'national-photo': setNational(event.target.files[0]);
                break;
            case 'national2-photo': setNational2(event.target.files[0]);
                break;
            case 'licence-photo': setLicence(event.target.files[0]);
                break;
            case 'licence2-photo': setLicence2(event.target.files[0]);
                break;
            case 'upload-photo-1': setPhoto1(event.target.files[0]);
                break;
            case 'upload-photo-2': setPhoto2(event.target.files[0]);
                break;
        }
    }

    const handleIsAgreeChange = () => {
        resetTimout();
        setIsAgree(!isAgree);
    };

    const [data, setData] = useState({
        'UserNameFirst': '',
        'UserNameLast': '',
        'UserNameLast_DByte': '',
        'UserNameFirst_DByte': '',
        'MailID': '',
        'DOB': '',
        'Country': '',
        'Address1': '',
        'Address2': '',
        'Address3': '',
        'Address4': '',
        'Address1_DByte': '',
        'Address2_DByte': '',
        'PostalCode': '',
        'PhoneNumber1': '',
        'Nationality': '',
        'PassportExpiryDate': '',
        'NationalIDExpiryDate': '',
        'LicenseExpiryDate': '',
        'PassportNumber': '',
        'NationalIDNumber': '',
        'LicenseNumber': ''
    });

    const handleCountryEdit = (e) => {
        resetTimout();
        if (!e.target.id && !!e.target.value) {
            setData({ ...data, ['Country']: e.target.value });
        }
    }

    const handleNationalityEdit = (e) => {
        resetTimout();
        if (!e.target.id && !!e.target.value) {
            setData({ ...data, ['Nationality']: e.target.value });
        }
    }

    const handleEdit = (e) => {
        resetTimout();
        const regex = /^[a-z0-9.,-@]+$/i
        if (e.target.value === '' || regex.test(e.target.value)) {
            switch (e.target.id) {
                case "last-name": setData({ ...data, ['UserNameLast']: e.target.value }); break;
                case "first-name": setData({ ...data, ['UserNameFirst']: e.target.value }); break;
                case "phone-number": setData({ ...data, ['PhoneNumber1']: e.target.value }); break;
                case "zip-code": setData({ ...data, ['PostalCode']: e.target.value }); break;
                case "email": setData({ ...data, ['MailID']: e.target.value }); break;
                case "passport-number": setData({ ...data, ['PassportNumber']: e.target.value }); break;
                case "national-number": setData({ ...data, ['NationalIDNumber']: e.target.value }); break;
                case "license-number": setData({ ...data, ['LicenseNumber']: e.target.value }); break;
            }
        }
        switch (e.target.id) {
            case "address1": setData({ ...data, ['Address1']: e.target.value }); break;
            case "address2": setData({ ...data, ['Address2']: e.target.value }); break;
            case "kanji-last-name": setData({ ...data, ['UserNameLast_DByte']: e.target.value }); break;
            case "kanji-first-name": setData({ ...data, ['UserNameFirst_DByte']: e.target.value }); break;
            case "Kanjiaddress1": setData({ ...data, ['Address1_DByte']: e.target.value }); break;
            case "Kanjiaddress2": setData({ ...data, ['Address2_DByte']: e.target.value }); break;
        }
    };

    const handleDOB = (e) => {
        resetTimout();
        setDOB(e);
    };

    const handlePassportDate = (e) => {
        resetTimout();
        setPassportExpiryDate(e);
    };

    const handleNationalDate = (e) => {
        resetTimout();
        setNationalIDExpiryDate(e);
    };

    const handleLicenseDate = (e) => {
        resetTimout();
        setLicenseExpiryDate(e);
    }

    useEffect(() => {
        //actionGetUserProfile request send from Navbar
    }, []);

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        if (props?.users[0]?.KycStatus === null || props?.users[0]?.KycStatus.length === 0) {
            props.users[0].KycStatus = "In-Progress";
            setKycStatus(false)
        } else {
            setKycStatus(true);
        }
        if (props?.users[0]?.PhotoUrl) {
            let photos = JSON.parse(props?.users[0]?.PhotoUrl)
            setLoading(true);
            setPassportUrl(photos?.passportUrl);
            if (photos?.passport2Url) {
                setPassport2Url(photos?.passport2Url);
            }
            setNationalUrl(photos?.nationalUrl);
            if (photos?.national2Url) {
                setNational2Url(photos?.national2Url);
            }
            setLicenceUrl(photos?.licenceUrl);
            if (photos?.licence2Url) {
                setLicence2Url(photos?.licence2Url);
            }
            setPhoto1Url(photos?.photo1Url);
            setPhoto2Url(photos?.photo2Url);
            setLoading(false);
        }
        if (props?.users[0]?.DOB) {
            setDOB(new Date(props?.users[0]?.DOB));
        }
        if (props?.users[0]?.PassportExpiryDate) {
            setPassportExpiryDate(new Date(props?.users[0]?.PassportExpiryDate));
        }
        if (props?.users[0]?.NationalIDExpiryDate) {
            setNationalIDExpiryDate(new Date(props?.users[0]?.NationalIDExpiryDate));
        }
        if (props?.users[0]?.LicenseExpiryDate) {
            setLicenseExpiryDate(new Date(props?.users[0]?.LicenseExpiryDate));
        }

        if (props?.users && props?.users.length > 0) {
            if (!props.users[0].Country) {
                props.users[0].Country = '';
            }
            if (!props.users[0].Nationality) {
                props.users[0].Nationality = '';
            }
        }

        setData(props?.users[0]);
    }, [props.users]);

    useEffect(() => {
        if (passport)
            handleUpload("passport");
    }, [passport]);

    useEffect(() => {
        if (passport2)
            handleUpload("passport2");
    }, [passport2]);

    useEffect(() => {
        if (national)
            handleUpload("national");
    }, [national]);

    useEffect(() => {
        if (national2)
            handleUpload("national2");
    }, [national2]);

    useEffect(() => {
        if (licence)
            handleUpload("licence");
    }, [licence]);

    useEffect(() => {
        if (licence2)
            handleUpload("licence2");
    }, [licence2]);

    useEffect(() => {
        if (photo1)
            handleUpload("photo1");
    }, [photo1]);

    useEffect(() => {
        if (photo2)
            handleUpload("photo2");
    }, [photo2]);

    useEffect(() => {
        if (!loading)
            setUploadedPhotos();
    }, [passportUrl, passport2Url, licenceUrl, licence2Url, nationalUrl, national2Url, photo1Url, photo2Url]);

    const setUploadedPhotos = () => {
        let photoUrl = {
            "passportUrl": passportUrl,
            "passport2Url": passport2Url,
            "licenceUrl": licenceUrl,
            "licence2Url": licence2Url,
            "nationalUrl": nationalUrl,
            "national2Url": national2Url,
            "photo1Url": photo1Url,
            "photo2Url": photo2Url
        }
        setData({ ...data, ['PhotoUrl']: JSON.stringify(photoUrl) });
    }

    const handleUpload = (type) => {
        if (!type) {
            alert("Please upload an image first!");
        } else {
            let file = "";
            switch (type) {
                case "passport": file = passport; break;
                case "passport2": file = passport2; break;
                case "national": file = national; break;
                case "national2": file = national2; break;
                case "licence": file = licence; break;
                case "licence2": file = licence2; break;
                case "photo1": file = photo1; break;
                case "photo2": file = photo2; break;
                default: console.log(type); break;
            }
            let userId = localStorage.getItem("uid");
            const storageRef = ref(storage, `/files/${userId}/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setPercent(percent);
                    if (percent === 100) {
                        setPercent(0);
                    }
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        switch (type) {
                            case "passport": setPassportUrl(url); break;
                            case "passport2": setPassport2Url(url); break;
                            case "national": setNationalUrl(url); break;
                            case "national2": setNational2Url(url); break;
                            case "licence": setLicenceUrl(url); break;
                            case "licence2": setLicence2Url(url); break;
                            case "photo1": setPhoto1Url(url); break;
                            case "photo2": setPhoto2Url(url); break;
                            default: console.log(type);
                        }
                    });
                }
            );
        }
    };

    return (
        <div className="user-kyc">
            <div className="from-step-heading">
                <h2 className="user-panel-title">{translate('Menu-AccountDetail')}</h2>
            </div>

            <form action="#">
                <div className="from-step">
                    <div className="from-step-item">
                        <div className="from-step-heading">
                            <div className="from-step-head">
                                <h4>{translate('Kyc-PersonalDetails-Edit')}</h4>
                            </div>
                        </div>

                        <div className="from-step-content">
                            <div className="note note-md note-info note-plane">
                                <h3>
                                    {translate('Kyc-PersonalDetails')}
                                </h3>
                                <h4>
                                    {translate('UserID')} : {data?.ClientID}
                                </h4>
                            </div>
                            <div className="gaps-2x" />
                            <div className="row">
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <TextField id="kanji-last-name" value={data?.UserNameLast_DByte} onChange={handleEdit} defaultValue={data?.UserNameLast_DByte} label={translate('Kanji-Last-Name')} className='input-bordered' />
                                    </div>
                                    {/* .input-item */}
                                </div>
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <TextField id="kanji-first-name" value={data?.UserNameFirst_DByte} onChange={handleEdit} defaultValue={data?.UserNameFirst_DByte} label={translate('Kanji-First-Name')} className='input-bordered' />
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <TextField id="last-name" value={data?.UserNameLast} onChange={handleEdit} defaultValue={data?.UserNameLast} label={translate('Last-Name')} className='input-bordered' />
                                    </div>
                                    {/* .input-item */}
                                </div>
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <TextField id="first-name" value={data?.UserNameFirst} onChange={handleEdit} defaultValue={data?.UserNameFirst} label={translate('First-Name')} className='input-bordered' />
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <TextField id="email" disabled={true} value={data?.MailID} onChange={handleEdit} defaultValue={data?.MailID} label={translate('Email-Address')} className='input-bordered' />
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <TextField id="phone-number" value={data?.PhoneNumber1} onChange={handleEdit} defaultValue={data?.PhoneNumber1} label={translate('Phone-Number')} className='input-bordered' />
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <DesktopDatePicker
                                            id="DOB"
                                            disabled={data?.KycStatus === 'Success' ? true : false}
                                            disableFuture={true}
                                            label={translate('Date-of-birth')}
                                            inputFormat="yyyy/MM/dd"
                                            value={dtDOB}
                                            onChange={handleDOB}
                                            className='input-bordered'
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        {/* TODO: */}
                                        <FormControl fullWidth>
                                            <InputLabel id="nationality-label">{translate('Country')}</InputLabel>
                                            <Select
                                                id="nationality"
                                                labelId="nationality-label"
                                                value={data?.Country}
                                                onChange={handleCountryEdit}
                                                className="input-item-label"
                                                input={<OutlinedInput label={translate('Country')} />}
                                            >
                                                <MenuItem value="">
                                                    <em>Select Nationality</em>
                                                </MenuItem>
                                                <MenuItem value="AF">Afghanistan</MenuItem>
                                                <MenuItem value="AX">Aland Islands</MenuItem>
                                                <MenuItem value="AL">Albania</MenuItem>
                                                <MenuItem value="DZ">Algeria</MenuItem>
                                                <MenuItem value="AS">American Samoa</MenuItem>
                                                <MenuItem value="AD">Andorra</MenuItem>
                                                <MenuItem value="AO">Angola</MenuItem>
                                                <MenuItem value="AI">Anguilla</MenuItem>
                                                <MenuItem value="AQ">Antarctica</MenuItem>
                                                <MenuItem value="AG">Antigua and Barbuda</MenuItem>
                                                <MenuItem value="AR">Argentina</MenuItem>
                                                <MenuItem value="AM">Armenia</MenuItem>
                                                <MenuItem value="AW">Aruba</MenuItem>
                                                <MenuItem value="AU">Australia</MenuItem>
                                                <MenuItem value="AT">Austria</MenuItem>
                                                <MenuItem value="AZ">Azerbaijan</MenuItem>
                                                <MenuItem value="BS">Bahamas</MenuItem>
                                                <MenuItem value="BH">Bahrain</MenuItem>
                                                <MenuItem value="BD">Bangladesh</MenuItem>
                                                <MenuItem value="BB">Barbados</MenuItem>
                                                <MenuItem value="BY">Belarus</MenuItem>
                                                <MenuItem value="BE">Belgium</MenuItem>
                                                <MenuItem value="BZ">Belize</MenuItem>
                                                <MenuItem value="BJ">Benin</MenuItem>
                                                <MenuItem value="BM">Bermuda</MenuItem>
                                                <MenuItem value="BT">Bhutan</MenuItem>
                                                <MenuItem value="BO">Bolivia</MenuItem>
                                                <MenuItem value="BQ">Bonaire, Sint Eustatius and Saba</MenuItem>
                                                <MenuItem value="BA">Bosnia and Herzegovina</MenuItem>
                                                <MenuItem value="BW">Botswana</MenuItem>
                                                <MenuItem value="BV">Bouvet Island</MenuItem>
                                                <MenuItem value="BR">Brazil</MenuItem>
                                                <MenuItem value="IO">British Indian Ocean Territory</MenuItem>
                                                <MenuItem value="BN">Brunei Darussalam</MenuItem>
                                                <MenuItem value="BG">Bulgaria</MenuItem>
                                                <MenuItem value="BF">Burkina Faso</MenuItem>
                                                <MenuItem value="BI">Burundi</MenuItem>
                                                <MenuItem value="KH">Cambodia</MenuItem>
                                                <MenuItem value="CM">Cameroon</MenuItem>
                                                <MenuItem value="CA">Canada</MenuItem>
                                                <MenuItem value="CV">Cape Verde</MenuItem>
                                                <MenuItem value="KY">Cayman Islands</MenuItem>
                                                <MenuItem value="CF">Central African Republic</MenuItem>
                                                <MenuItem value="TD">Chad</MenuItem>
                                                <MenuItem value="CL">Chile</MenuItem>
                                                <MenuItem value="CN">China</MenuItem>
                                                <MenuItem value="CX">Christmas Island</MenuItem>
                                                <MenuItem value="CC">Cocos (Keeling) Islands</MenuItem>
                                                <MenuItem value="CO">Colombia</MenuItem>
                                                <MenuItem value="KM">Comoros</MenuItem>
                                                <MenuItem value="CG">Congo</MenuItem>
                                                <MenuItem value="CD">Congo, Democratic Republic of the Congo</MenuItem>
                                                <MenuItem value="CK">Cook Islands</MenuItem>
                                                <MenuItem value="CR">Costa Rica</MenuItem>
                                                <MenuItem value="CI">Cote D'Ivoire</MenuItem>
                                                <MenuItem value="HR">Croatia</MenuItem>
                                                <MenuItem value="CU">Cuba</MenuItem>
                                                <MenuItem value="CW">Curacao</MenuItem>
                                                <MenuItem value="CY">Cyprus</MenuItem>
                                                <MenuItem value="CZ">Czech Republic</MenuItem>
                                                <MenuItem value="DK">Denmark</MenuItem>
                                                <MenuItem value="DJ">Djibouti</MenuItem>
                                                <MenuItem value="DM">Dominica</MenuItem>
                                                <MenuItem value="DO">Dominican Republic</MenuItem>
                                                <MenuItem value="EC">Ecuador</MenuItem>
                                                <MenuItem value="EG">Egypt</MenuItem>
                                                <MenuItem value="SV">El Salvador</MenuItem>
                                                <MenuItem value="GQ">Equatorial Guinea</MenuItem>
                                                <MenuItem value="ER">Eritrea</MenuItem>
                                                <MenuItem value="EE">Estonia</MenuItem>
                                                <MenuItem value="ET">Ethiopia</MenuItem>
                                                <MenuItem value="FK">Falkland Islands (Malvinas)</MenuItem>
                                                <MenuItem value="FO">Faroe Islands</MenuItem>
                                                <MenuItem value="FJ">Fiji</MenuItem>
                                                <MenuItem value="FI">Finland</MenuItem>
                                                <MenuItem value="FR">France</MenuItem>
                                                <MenuItem value="GF">French Guiana</MenuItem>
                                                <MenuItem value="PF">French Polynesia</MenuItem>
                                                <MenuItem value="TF">French Southern Territories</MenuItem>
                                                <MenuItem value="GA">Gabon</MenuItem>
                                                <MenuItem value="GM">Gambia</MenuItem>
                                                <MenuItem value="GE">Georgia</MenuItem>
                                                <MenuItem value="DE">Germany</MenuItem>
                                                <MenuItem value="GH">Ghana</MenuItem>
                                                <MenuItem value="GI">Gibraltar</MenuItem>
                                                <MenuItem value="GR">Greece</MenuItem>
                                                <MenuItem value="GL">Greenland</MenuItem>
                                                <MenuItem value="GD">Grenada</MenuItem>
                                                <MenuItem value="GP">Guadeloupe</MenuItem>
                                                <MenuItem value="GU">Guam</MenuItem>
                                                <MenuItem value="GT">Guatemala</MenuItem>
                                                <MenuItem value="GG">Guernsey</MenuItem>
                                                <MenuItem value="GN">Guinea</MenuItem>
                                                <MenuItem value="GW">Guinea-Bissau</MenuItem>
                                                <MenuItem value="GY">Guyana</MenuItem>
                                                <MenuItem value="HT">Haiti</MenuItem>
                                                <MenuItem value="HM">Heard Island and Mcdonald Islands</MenuItem>
                                                <MenuItem value="VA">Holy See (Vatican City State)</MenuItem>
                                                <MenuItem value="HN">Honduras</MenuItem>
                                                <MenuItem value="HK">Hong Kong</MenuItem>
                                                <MenuItem value="HU">Hungary</MenuItem>
                                                <MenuItem value="IS">Iceland</MenuItem>
                                                <MenuItem value="IN">India</MenuItem>
                                                <MenuItem value="ID">Indonesia</MenuItem>
                                                <MenuItem value="IR">Iran, Islamic Republic of</MenuItem>
                                                <MenuItem value="IQ">Iraq</MenuItem>
                                                <MenuItem value="IE">Ireland</MenuItem>
                                                <MenuItem value="IM">Isle of Man</MenuItem>
                                                <MenuItem value="IL">Israel</MenuItem>
                                                <MenuItem value="IT">Italy</MenuItem>
                                                <MenuItem value="JM">Jamaica</MenuItem>
                                                <MenuItem value="JP">Japan</MenuItem>
                                                <MenuItem value="JE">Jersey</MenuItem>
                                                <MenuItem value="JO">Jordan</MenuItem>
                                                <MenuItem value="KZ">Kazakhstan</MenuItem>
                                                <MenuItem value="KE">Kenya</MenuItem>
                                                <MenuItem value="KI">Kiribati</MenuItem>
                                                <MenuItem value="KP">Korea, Democratic People's Republic of</MenuItem>
                                                <MenuItem value="KR">Korea, Republic of</MenuItem>
                                                <MenuItem value="XK">Kosovo</MenuItem>
                                                <MenuItem value="KW">Kuwait</MenuItem>
                                                <MenuItem value="KG">Kyrgyzstan</MenuItem>
                                                <MenuItem value="LA">Lao People's Democratic Republic</MenuItem>
                                                <MenuItem value="LV">Latvia</MenuItem>
                                                <MenuItem value="LB">Lebanon</MenuItem>
                                                <MenuItem value="LS">Lesotho</MenuItem>
                                                <MenuItem value="LR">Liberia</MenuItem>
                                                <MenuItem value="LY">Libyan Arab Jamahiriya</MenuItem>
                                                <MenuItem value="LI">Liechtenstein</MenuItem>
                                                <MenuItem value="LT">Lithuania</MenuItem>
                                                <MenuItem value="LU">Luxembourg</MenuItem>
                                                <MenuItem value="MO">Macao</MenuItem>
                                                <MenuItem value="MK">Macedonia, the Former Yugoslav Republic of</MenuItem>
                                                <MenuItem value="MG">Madagascar</MenuItem>
                                                <MenuItem value="MW">Malawi</MenuItem>
                                                <MenuItem value="MY">Malaysia</MenuItem>
                                                <MenuItem value="MV">Maldives</MenuItem>
                                                <MenuItem value="ML">Mali</MenuItem>
                                                <MenuItem value="MT">Malta</MenuItem>
                                                <MenuItem value="MH">Marshall Islands</MenuItem>
                                                <MenuItem value="MQ">Martinique</MenuItem>
                                                <MenuItem value="MR">Mauritania</MenuItem>
                                                <MenuItem value="MU">Mauritius</MenuItem>
                                                <MenuItem value="YT">Mayotte</MenuItem>
                                                <MenuItem value="MX">Mexico</MenuItem>
                                                <MenuItem value="FM">Micronesia, Federated States of</MenuItem>
                                                <MenuItem value="MD">Moldova, Republic of</MenuItem>
                                                <MenuItem value="MC">Monaco</MenuItem>
                                                <MenuItem value="MN">Mongolia</MenuItem>
                                                <MenuItem value="ME">Montenegro</MenuItem>
                                                <MenuItem value="MS">Montserrat</MenuItem>
                                                <MenuItem value="MA">Morocco</MenuItem>
                                                <MenuItem value="MZ">Mozambique</MenuItem>
                                                <MenuItem value="MM">Myanmar</MenuItem>
                                                <MenuItem value="NA">Namibia</MenuItem>
                                                <MenuItem value="NR">Nauru</MenuItem>
                                                <MenuItem value="NP">Nepal</MenuItem>
                                                <MenuItem value="NL">Netherlands</MenuItem>
                                                <MenuItem value="AN">Netherlands Antilles</MenuItem>
                                                <MenuItem value="NC">New Caledonia</MenuItem>
                                                <MenuItem value="NZ">New Zealand</MenuItem>
                                                <MenuItem value="NI">Nicaragua</MenuItem>
                                                <MenuItem value="NE">Niger</MenuItem>
                                                <MenuItem value="NG">Nigeria</MenuItem>
                                                <MenuItem value="NU">Niue</MenuItem>
                                                <MenuItem value="NF">Norfolk Island</MenuItem>
                                                <MenuItem value="MP">Northern Mariana Islands</MenuItem>
                                                <MenuItem value="NO">Norway</MenuItem>
                                                <MenuItem value="OM">Oman</MenuItem>
                                                <MenuItem value="PK">Pakistan</MenuItem>
                                                <MenuItem value="PW">Palau</MenuItem>
                                                <MenuItem value="PS">Palestinian Territory, Occupied</MenuItem>
                                                <MenuItem value="PA">Panama</MenuItem>
                                                <MenuItem value="PG">Papua New Guinea</MenuItem>
                                                <MenuItem value="PY">Paraguay</MenuItem>
                                                <MenuItem value="PE">Peru</MenuItem>
                                                <MenuItem value="PH">Philippines</MenuItem>
                                                <MenuItem value="PN">Pitcairn</MenuItem>
                                                <MenuItem value="PL">Poland</MenuItem>
                                                <MenuItem value="PT">Portugal</MenuItem>
                                                <MenuItem value="PR">Puerto Rico</MenuItem>
                                                <MenuItem value="QA">Qatar</MenuItem>
                                                <MenuItem value="RE">Reunion</MenuItem>
                                                <MenuItem value="RO">Romania</MenuItem>
                                                <MenuItem value="RU">Russian Federation</MenuItem>
                                                <MenuItem value="RW">Rwanda</MenuItem>
                                                <MenuItem value="BL">Saint Barthelemy</MenuItem>
                                                <MenuItem value="SH">Saint Helena</MenuItem>
                                                <MenuItem value="KN">Saint Kitts and Nevis</MenuItem>
                                                <MenuItem value="LC">Saint Lucia</MenuItem>
                                                <MenuItem value="MF">Saint Martin</MenuItem>
                                                <MenuItem value="PM">Saint Pierre and Miquelon</MenuItem>
                                                <MenuItem value="VC">Saint Vincent and the Grenadines</MenuItem>
                                                <MenuItem value="WS">Samoa</MenuItem>
                                                <MenuItem value="SM">San Marino</MenuItem>
                                                <MenuItem value="ST">Sao Tome and Principe</MenuItem>
                                                <MenuItem value="SA">Saudi Arabia</MenuItem>
                                                <MenuItem value="SN">Senegal</MenuItem>
                                                <MenuItem value="RS">Serbia</MenuItem>
                                                <MenuItem value="CS">Serbia and Montenegro</MenuItem>
                                                <MenuItem value="SC">Seychelles</MenuItem>
                                                <MenuItem value="SL">Sierra Leone</MenuItem>
                                                <MenuItem value="SG">Singapore</MenuItem>
                                                <MenuItem value="SX">Sint Maarten</MenuItem>
                                                <MenuItem value="SK">Slovakia</MenuItem>
                                                <MenuItem value="SI">Slovenia</MenuItem>
                                                <MenuItem value="SB">Solomon Islands</MenuItem>
                                                <MenuItem value="SO">Somalia</MenuItem>
                                                <MenuItem value="ZA">South Africa</MenuItem>
                                                <MenuItem value="GS">South Georgia and the South Sandwich Islands</MenuItem>
                                                <MenuItem value="SS">South Sudan</MenuItem>
                                                <MenuItem value="ES">Spain</MenuItem>
                                                <MenuItem value="LK">Sri Lanka</MenuItem>
                                                <MenuItem value="SD">Sudan</MenuItem>
                                                <MenuItem value="SR">Suriname</MenuItem>
                                                <MenuItem value="SJ">Svalbard and Jan Mayen</MenuItem>
                                                <MenuItem value="SZ">Swaziland</MenuItem>
                                                <MenuItem value="SE">Sweden</MenuItem>
                                                <MenuItem value="CH">Switzerland</MenuItem>
                                                <MenuItem value="SY">Syrian Arab Republic</MenuItem>
                                                <MenuItem value="TW">Taiwan, Province of China</MenuItem>
                                                <MenuItem value="TJ">Tajikistan</MenuItem>
                                                <MenuItem value="TZ">Tanzania, United Republic of</MenuItem>
                                                <MenuItem value="TH">Thailand</MenuItem>
                                                <MenuItem value="TL">Timor-Leste</MenuItem>
                                                <MenuItem value="TG">Togo</MenuItem>
                                                <MenuItem value="TK">Tokelau</MenuItem>
                                                <MenuItem value="TO">Tonga</MenuItem>
                                                <MenuItem value="TT">Trinidad and Tobago</MenuItem>
                                                <MenuItem value="TN">Tunisia</MenuItem>
                                                <MenuItem value="TR">Turkey</MenuItem>
                                                <MenuItem value="TM">Turkmenistan</MenuItem>
                                                <MenuItem value="TC">Turks and Caicos Islands</MenuItem>
                                                <MenuItem value="TV">Tuvalu</MenuItem>
                                                <MenuItem value="UG">Uganda</MenuItem>
                                                <MenuItem value="UA">Ukraine</MenuItem>
                                                <MenuItem value="AE">United Arab Emirates</MenuItem>
                                                <MenuItem value="GB">United Kingdom</MenuItem>
                                                <MenuItem value="US">United States</MenuItem>
                                                <MenuItem value="UM">United States Minor Outlying Islands</MenuItem>
                                                <MenuItem value="UY">Uruguay</MenuItem>
                                                <MenuItem value="UZ">Uzbekistan</MenuItem>
                                                <MenuItem value="VU">Vanuatu</MenuItem>
                                                <MenuItem value="VE">Venezuela</MenuItem>
                                                <MenuItem value="VN">Viet Nam</MenuItem>
                                                <MenuItem value="VG">Virgin Islands, British</MenuItem>
                                                <MenuItem value="VI">Virgin Islands, U.s.</MenuItem>
                                                <MenuItem value="WF">Wallis and Futuna</MenuItem>
                                                <MenuItem value="EH">Western Sahara</MenuItem>
                                                <MenuItem value="YE">Yemen</MenuItem>
                                                <MenuItem value="ZM">Zambia</MenuItem>
                                                <MenuItem value="ZW">Zimbabwe</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <TextField id="zip-code" value={data?.PostalCode} onChange={handleEdit} defaultValue={data?.PostalCode} label={translate('Zip-Code')} className='input-bordered' />
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <FormControl fullWidth>
                                            <InputLabel id="nationality-label">{translate('Nationality')}</InputLabel>
                                            <Select
                                                id="nationality"
                                                labelId="nationality-label"
                                                //value={age}
                                                value={data?.Nationality}
                                                onChange={handleNationalityEdit}
                                                className="input-item-label"
                                                input={<OutlinedInput label={translate('Nationality')} />}
                                            >
                                                <MenuItem value="">
                                                    <em>Select Nationality</em>
                                                </MenuItem>
                                                <MenuItem value="AF">Afghanistan</MenuItem>
                                                <MenuItem value="AX">Aland Islands</MenuItem>
                                                <MenuItem value="AL">Albania</MenuItem>
                                                <MenuItem value="DZ">Algeria</MenuItem>
                                                <MenuItem value="AS">American Samoa</MenuItem>
                                                <MenuItem value="AD">Andorra</MenuItem>
                                                <MenuItem value="AO">Angola</MenuItem>
                                                <MenuItem value="AI">Anguilla</MenuItem>
                                                <MenuItem value="AQ">Antarctica</MenuItem>
                                                <MenuItem value="AG">Antigua and Barbuda</MenuItem>
                                                <MenuItem value="AR">Argentina</MenuItem>
                                                <MenuItem value="AM">Armenia</MenuItem>
                                                <MenuItem value="AW">Aruba</MenuItem>
                                                <MenuItem value="AU">Australia</MenuItem>
                                                <MenuItem value="AT">Austria</MenuItem>
                                                <MenuItem value="AZ">Azerbaijan</MenuItem>
                                                <MenuItem value="BS">Bahamas</MenuItem>
                                                <MenuItem value="BH">Bahrain</MenuItem>
                                                <MenuItem value="BD">Bangladesh</MenuItem>
                                                <MenuItem value="BB">Barbados</MenuItem>
                                                <MenuItem value="BY">Belarus</MenuItem>
                                                <MenuItem value="BE">Belgium</MenuItem>
                                                <MenuItem value="BZ">Belize</MenuItem>
                                                <MenuItem value="BJ">Benin</MenuItem>
                                                <MenuItem value="BM">Bermuda</MenuItem>
                                                <MenuItem value="BT">Bhutan</MenuItem>
                                                <MenuItem value="BO">Bolivia</MenuItem>
                                                <MenuItem value="BQ">Bonaire, Sint Eustatius and Saba</MenuItem>
                                                <MenuItem value="BA">Bosnia and Herzegovina</MenuItem>
                                                <MenuItem value="BW">Botswana</MenuItem>
                                                <MenuItem value="BV">Bouvet Island</MenuItem>
                                                <MenuItem value="BR">Brazil</MenuItem>
                                                <MenuItem value="IO">British Indian Ocean Territory</MenuItem>
                                                <MenuItem value="BN">Brunei Darussalam</MenuItem>
                                                <MenuItem value="BG">Bulgaria</MenuItem>
                                                <MenuItem value="BF">Burkina Faso</MenuItem>
                                                <MenuItem value="BI">Burundi</MenuItem>
                                                <MenuItem value="KH">Cambodia</MenuItem>
                                                <MenuItem value="CM">Cameroon</MenuItem>
                                                <MenuItem value="CA">Canada</MenuItem>
                                                <MenuItem value="CV">Cape Verde</MenuItem>
                                                <MenuItem value="KY">Cayman Islands</MenuItem>
                                                <MenuItem value="CF">Central African Republic</MenuItem>
                                                <MenuItem value="TD">Chad</MenuItem>
                                                <MenuItem value="CL">Chile</MenuItem>
                                                <MenuItem value="CN">China</MenuItem>
                                                <MenuItem value="CX">Christmas Island</MenuItem>
                                                <MenuItem value="CC">Cocos (Keeling) Islands</MenuItem>
                                                <MenuItem value="CO">Colombia</MenuItem>
                                                <MenuItem value="KM">Comoros</MenuItem>
                                                <MenuItem value="CG">Congo</MenuItem>
                                                <MenuItem value="CD">Congo, Democratic Republic of the Congo</MenuItem>
                                                <MenuItem value="CK">Cook Islands</MenuItem>
                                                <MenuItem value="CR">Costa Rica</MenuItem>
                                                <MenuItem value="CI">Cote D'Ivoire</MenuItem>
                                                <MenuItem value="HR">Croatia</MenuItem>
                                                <MenuItem value="CU">Cuba</MenuItem>
                                                <MenuItem value="CW">Curacao</MenuItem>
                                                <MenuItem value="CY">Cyprus</MenuItem>
                                                <MenuItem value="CZ">Czech Republic</MenuItem>
                                                <MenuItem value="DK">Denmark</MenuItem>
                                                <MenuItem value="DJ">Djibouti</MenuItem>
                                                <MenuItem value="DM">Dominica</MenuItem>
                                                <MenuItem value="DO">Dominican Republic</MenuItem>
                                                <MenuItem value="EC">Ecuador</MenuItem>
                                                <MenuItem value="EG">Egypt</MenuItem>
                                                <MenuItem value="SV">El Salvador</MenuItem>
                                                <MenuItem value="GQ">Equatorial Guinea</MenuItem>
                                                <MenuItem value="ER">Eritrea</MenuItem>
                                                <MenuItem value="EE">Estonia</MenuItem>
                                                <MenuItem value="ET">Ethiopia</MenuItem>
                                                <MenuItem value="FK">Falkland Islands (Malvinas)</MenuItem>
                                                <MenuItem value="FO">Faroe Islands</MenuItem>
                                                <MenuItem value="FJ">Fiji</MenuItem>
                                                <MenuItem value="FI">Finland</MenuItem>
                                                <MenuItem value="FR">France</MenuItem>
                                                <MenuItem value="GF">French Guiana</MenuItem>
                                                <MenuItem value="PF">French Polynesia</MenuItem>
                                                <MenuItem value="TF">French Southern Territories</MenuItem>
                                                <MenuItem value="GA">Gabon</MenuItem>
                                                <MenuItem value="GM">Gambia</MenuItem>
                                                <MenuItem value="GE">Georgia</MenuItem>
                                                <MenuItem value="DE">Germany</MenuItem>
                                                <MenuItem value="GH">Ghana</MenuItem>
                                                <MenuItem value="GI">Gibraltar</MenuItem>
                                                <MenuItem value="GR">Greece</MenuItem>
                                                <MenuItem value="GL">Greenland</MenuItem>
                                                <MenuItem value="GD">Grenada</MenuItem>
                                                <MenuItem value="GP">Guadeloupe</MenuItem>
                                                <MenuItem value="GU">Guam</MenuItem>
                                                <MenuItem value="GT">Guatemala</MenuItem>
                                                <MenuItem value="GG">Guernsey</MenuItem>
                                                <MenuItem value="GN">Guinea</MenuItem>
                                                <MenuItem value="GW">Guinea-Bissau</MenuItem>
                                                <MenuItem value="GY">Guyana</MenuItem>
                                                <MenuItem value="HT">Haiti</MenuItem>
                                                <MenuItem value="HM">Heard Island and Mcdonald Islands</MenuItem>
                                                <MenuItem value="VA">Holy See (Vatican City State)</MenuItem>
                                                <MenuItem value="HN">Honduras</MenuItem>
                                                <MenuItem value="HK">Hong Kong</MenuItem>
                                                <MenuItem value="HU">Hungary</MenuItem>
                                                <MenuItem value="IS">Iceland</MenuItem>
                                                <MenuItem value="IN">India</MenuItem>
                                                <MenuItem value="ID">Indonesia</MenuItem>
                                                <MenuItem value="IR">Iran, Islamic Republic of</MenuItem>
                                                <MenuItem value="IQ">Iraq</MenuItem>
                                                <MenuItem value="IE">Ireland</MenuItem>
                                                <MenuItem value="IM">Isle of Man</MenuItem>
                                                <MenuItem value="IL">Israel</MenuItem>
                                                <MenuItem value="IT">Italy</MenuItem>
                                                <MenuItem value="JM">Jamaica</MenuItem>
                                                <MenuItem value="JP">Japan</MenuItem>
                                                <MenuItem value="JE">Jersey</MenuItem>
                                                <MenuItem value="JO">Jordan</MenuItem>
                                                <MenuItem value="KZ">Kazakhstan</MenuItem>
                                                <MenuItem value="KE">Kenya</MenuItem>
                                                <MenuItem value="KI">Kiribati</MenuItem>
                                                <MenuItem value="KP">Korea, Democratic People's Republic of</MenuItem>
                                                <MenuItem value="KR">Korea, Republic of</MenuItem>
                                                <MenuItem value="XK">Kosovo</MenuItem>
                                                <MenuItem value="KW">Kuwait</MenuItem>
                                                <MenuItem value="KG">Kyrgyzstan</MenuItem>
                                                <MenuItem value="LA">Lao People's Democratic Republic</MenuItem>
                                                <MenuItem value="LV">Latvia</MenuItem>
                                                <MenuItem value="LB">Lebanon</MenuItem>
                                                <MenuItem value="LS">Lesotho</MenuItem>
                                                <MenuItem value="LR">Liberia</MenuItem>
                                                <MenuItem value="LY">Libyan Arab Jamahiriya</MenuItem>
                                                <MenuItem value="LI">Liechtenstein</MenuItem>
                                                <MenuItem value="LT">Lithuania</MenuItem>
                                                <MenuItem value="LU">Luxembourg</MenuItem>
                                                <MenuItem value="MO">Macao</MenuItem>
                                                <MenuItem value="MK">Macedonia, the Former Yugoslav Republic of</MenuItem>
                                                <MenuItem value="MG">Madagascar</MenuItem>
                                                <MenuItem value="MW">Malawi</MenuItem>
                                                <MenuItem value="MY">Malaysia</MenuItem>
                                                <MenuItem value="MV">Maldives</MenuItem>
                                                <MenuItem value="ML">Mali</MenuItem>
                                                <MenuItem value="MT">Malta</MenuItem>
                                                <MenuItem value="MH">Marshall Islands</MenuItem>
                                                <MenuItem value="MQ">Martinique</MenuItem>
                                                <MenuItem value="MR">Mauritania</MenuItem>
                                                <MenuItem value="MU">Mauritius</MenuItem>
                                                <MenuItem value="YT">Mayotte</MenuItem>
                                                <MenuItem value="MX">Mexico</MenuItem>
                                                <MenuItem value="FM">Micronesia, Federated States of</MenuItem>
                                                <MenuItem value="MD">Moldova, Republic of</MenuItem>
                                                <MenuItem value="MC">Monaco</MenuItem>
                                                <MenuItem value="MN">Mongolia</MenuItem>
                                                <MenuItem value="ME">Montenegro</MenuItem>
                                                <MenuItem value="MS">Montserrat</MenuItem>
                                                <MenuItem value="MA">Morocco</MenuItem>
                                                <MenuItem value="MZ">Mozambique</MenuItem>
                                                <MenuItem value="MM">Myanmar</MenuItem>
                                                <MenuItem value="NA">Namibia</MenuItem>
                                                <MenuItem value="NR">Nauru</MenuItem>
                                                <MenuItem value="NP">Nepal</MenuItem>
                                                <MenuItem value="NL">Netherlands</MenuItem>
                                                <MenuItem value="AN">Netherlands Antilles</MenuItem>
                                                <MenuItem value="NC">New Caledonia</MenuItem>
                                                <MenuItem value="NZ">New Zealand</MenuItem>
                                                <MenuItem value="NI">Nicaragua</MenuItem>
                                                <MenuItem value="NE">Niger</MenuItem>
                                                <MenuItem value="NG">Nigeria</MenuItem>
                                                <MenuItem value="NU">Niue</MenuItem>
                                                <MenuItem value="NF">Norfolk Island</MenuItem>
                                                <MenuItem value="MP">Northern Mariana Islands</MenuItem>
                                                <MenuItem value="NO">Norway</MenuItem>
                                                <MenuItem value="OM">Oman</MenuItem>
                                                <MenuItem value="PK">Pakistan</MenuItem>
                                                <MenuItem value="PW">Palau</MenuItem>
                                                <MenuItem value="PS">Palestinian Territory, Occupied</MenuItem>
                                                <MenuItem value="PA">Panama</MenuItem>
                                                <MenuItem value="PG">Papua New Guinea</MenuItem>
                                                <MenuItem value="PY">Paraguay</MenuItem>
                                                <MenuItem value="PE">Peru</MenuItem>
                                                <MenuItem value="PH">Philippines</MenuItem>
                                                <MenuItem value="PN">Pitcairn</MenuItem>
                                                <MenuItem value="PL">Poland</MenuItem>
                                                <MenuItem value="PT">Portugal</MenuItem>
                                                <MenuItem value="PR">Puerto Rico</MenuItem>
                                                <MenuItem value="QA">Qatar</MenuItem>
                                                <MenuItem value="RE">Reunion</MenuItem>
                                                <MenuItem value="RO">Romania</MenuItem>
                                                <MenuItem value="RU">Russian Federation</MenuItem>
                                                <MenuItem value="RW">Rwanda</MenuItem>
                                                <MenuItem value="BL">Saint Barthelemy</MenuItem>
                                                <MenuItem value="SH">Saint Helena</MenuItem>
                                                <MenuItem value="KN">Saint Kitts and Nevis</MenuItem>
                                                <MenuItem value="LC">Saint Lucia</MenuItem>
                                                <MenuItem value="MF">Saint Martin</MenuItem>
                                                <MenuItem value="PM">Saint Pierre and Miquelon</MenuItem>
                                                <MenuItem value="VC">Saint Vincent and the Grenadines</MenuItem>
                                                <MenuItem value="WS">Samoa</MenuItem>
                                                <MenuItem value="SM">San Marino</MenuItem>
                                                <MenuItem value="ST">Sao Tome and Principe</MenuItem>
                                                <MenuItem value="SA">Saudi Arabia</MenuItem>
                                                <MenuItem value="SN">Senegal</MenuItem>
                                                <MenuItem value="RS">Serbia</MenuItem>
                                                <MenuItem value="CS">Serbia and Montenegro</MenuItem>
                                                <MenuItem value="SC">Seychelles</MenuItem>
                                                <MenuItem value="SL">Sierra Leone</MenuItem>
                                                <MenuItem value="SG">Singapore</MenuItem>
                                                <MenuItem value="SX">Sint Maarten</MenuItem>
                                                <MenuItem value="SK">Slovakia</MenuItem>
                                                <MenuItem value="SI">Slovenia</MenuItem>
                                                <MenuItem value="SB">Solomon Islands</MenuItem>
                                                <MenuItem value="SO">Somalia</MenuItem>
                                                <MenuItem value="ZA">South Africa</MenuItem>
                                                <MenuItem value="GS">South Georgia and the South Sandwich Islands</MenuItem>
                                                <MenuItem value="SS">South Sudan</MenuItem>
                                                <MenuItem value="ES">Spain</MenuItem>
                                                <MenuItem value="LK">Sri Lanka</MenuItem>
                                                <MenuItem value="SD">Sudan</MenuItem>
                                                <MenuItem value="SR">Suriname</MenuItem>
                                                <MenuItem value="SJ">Svalbard and Jan Mayen</MenuItem>
                                                <MenuItem value="SZ">Swaziland</MenuItem>
                                                <MenuItem value="SE">Sweden</MenuItem>
                                                <MenuItem value="CH">Switzerland</MenuItem>
                                                <MenuItem value="SY">Syrian Arab Republic</MenuItem>
                                                <MenuItem value="TW">Taiwan, Province of China</MenuItem>
                                                <MenuItem value="TJ">Tajikistan</MenuItem>
                                                <MenuItem value="TZ">Tanzania, United Republic of</MenuItem>
                                                <MenuItem value="TH">Thailand</MenuItem>
                                                <MenuItem value="TL">Timor-Leste</MenuItem>
                                                <MenuItem value="TG">Togo</MenuItem>
                                                <MenuItem value="TK">Tokelau</MenuItem>
                                                <MenuItem value="TO">Tonga</MenuItem>
                                                <MenuItem value="TT">Trinidad and Tobago</MenuItem>
                                                <MenuItem value="TN">Tunisia</MenuItem>
                                                <MenuItem value="TR">Turkey</MenuItem>
                                                <MenuItem value="TM">Turkmenistan</MenuItem>
                                                <MenuItem value="TC">Turks and Caicos Islands</MenuItem>
                                                <MenuItem value="TV">Tuvalu</MenuItem>
                                                <MenuItem value="UG">Uganda</MenuItem>
                                                <MenuItem value="UA">Ukraine</MenuItem>
                                                <MenuItem value="AE">United Arab Emirates</MenuItem>
                                                <MenuItem value="GB">United Kingdom</MenuItem>
                                                <MenuItem value="US">United States</MenuItem>
                                                <MenuItem value="UM">United States Minor Outlying Islands</MenuItem>
                                                <MenuItem value="UY">Uruguay</MenuItem>
                                                <MenuItem value="UZ">Uzbekistan</MenuItem>
                                                <MenuItem value="VU">Vanuatu</MenuItem>
                                                <MenuItem value="VE">Venezuela</MenuItem>
                                                <MenuItem value="VN">Viet Nam</MenuItem>
                                                <MenuItem value="VG">Virgin Islands, British</MenuItem>
                                                <MenuItem value="VI">Virgin Islands, U.s.</MenuItem>
                                                <MenuItem value="WF">Wallis and Futuna</MenuItem>
                                                <MenuItem value="EH">Western Sahara</MenuItem>
                                                <MenuItem value="YE">Yemen</MenuItem>
                                                <MenuItem value="ZM">Zambia</MenuItem>
                                                <MenuItem value="ZW">Zimbabwe</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <TextField id="Kanjiaddress1" value={data?.Address1_DByte} onChange={handleEdit} defaultValue={data?.Address1_DByte} label={translate('Kanji-Address-Line-1')} className='input-bordered' />
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <TextField id="Kanjiaddress2" value={data?.Address2_DByte} onChange={handleEdit} defaultValue={data?.Address2_DByte} label={translate('Kanji-Address-Line-2')} className='input-bordered' />
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <TextField id="address1" value={data?.Address1} onChange={handleEdit} defaultValue={data?.Address1} label={translate('Address-Line-1')} className='input-bordered' />
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <TextField id="address2" value={data?.Address2} onChange={handleEdit} defaultValue={data?.Address2} label={translate('Address-Line-2')} className='input-bordered' />
                                    </div>
                                    {/* .input-item */}
                                </div>
                            </div>
                            {/* .row */}
                        </div>
                        {/* .from-step-content */}
                    </div>
                    {/* .from-step-item */}
                    <div className="from-step-item">
                        <div className="from-step-heading">
                            <div className="from-step-head">
                                <h4>{translate('Kyc-VerificationID-Edit')}</h4>
                            </div>
                        </div>
                        {percent !== 0 ? <CircularProgress /> : <div className="from-step-content">
                            <div className="note note-md note-info note-plane">
                                <em className="fas fa-info-circle" />
                                <p>{translate('documents-details')}</p>
                            </div>
                            <div className="gaps-2x" />
                            <ul className="nav nav-tabs nav-tabs-bordered" role="tablist">
                                <li className="nav-item">
                                    <div
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                    >
                                        <label htmlFor="passport-photo">
                                            <p>{passport?.name?.substring(0, trimLength)}</p>
                                            <input
                                                style={{ display: 'none' }}
                                                id="passport-photo"
                                                name="passport-photo"
                                                type="file"
                                                onChange={handleChange}
                                            />
                                            <Fab color="primary" size="small" component="span" aria-label="add">
                                                <AddIcon />
                                            </Fab>
                                        </label>
                                        <div className="nav-tabs-icon">
                                            <img src={passportUrl ? passportUrl : require('../assets/images/icon-passport.png')} alt="icon" />
                                            <img src={passportUrl ? passportUrl : require('../assets/images/icon-passport-color.png')}
                                                alt="icon" />
                                        </div>
                                        <span>{translate('Passport')}</span>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                    >
                                        <label htmlFor="national-photo">
                                            <p>{national?.name?.substring(0, trimLength)}</p>
                                            <input
                                                style={{ display: 'none' }}
                                                id="national-photo"
                                                name="national-photo"
                                                type="file"
                                                onChange={handleChange}
                                            />
                                            <Fab color="primary" size="small" component="span" aria-label="add">
                                                <AddIcon />
                                            </Fab>
                                        </label>
                                        <div className="nav-tabs-icon">
                                            <img src={nationalUrl ? nationalUrl : require('../assets/images/icon-national-id.png')} alt="icon" />
                                            <img src={nationalUrl ? nationalUrl : require('../assets/images/icon-national-id-color.png')}
                                                alt="icon" />
                                        </div>
                                        <span>{translate('National-Card')}</span>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                    >
                                        <label htmlFor="licence-photo">
                                            <p>{licence?.name?.substring(0, trimLength)}</p>
                                            <input
                                                style={{ display: 'none' }}
                                                id="licence-photo"
                                                name="licence-photo"
                                                type="file"
                                                onChange={handleChange}
                                            />
                                            <Fab color="primary" size="small" component="span" aria-label="add">
                                                <AddIcon />
                                            </Fab>
                                        </label>
                                        <div className="nav-tabs-icon">
                                            <img src={licenceUrl ? licenceUrl : require('../assets/images/icon-licence.png')} alt="icon" />
                                            <img src={licenceUrl ? licenceUrl : require('../assets/images/icon-licence-color.png')}
                                                alt="icon" />
                                        </div>
                                        <span>{translate('Drivers-License')}</span>
                                    </div>
                                </li>
                                <p></p>

                                <li className="nav-item">
                                    <div
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                    >
                                        <label htmlFor="passport2-photo">
                                            <p>{passport2?.name?.substring(0, trimLength)}</p>
                                            <input
                                                style={{ display: 'none' }}
                                                id="passport2-photo"
                                                name="passport2-photo"
                                                type="file"
                                                onChange={handleChange}
                                            />
                                            <Fab color="primary" size="small" component="span" aria-label="add">
                                                <AddIcon />
                                            </Fab>
                                        </label>
                                        <div className="nav-tabs-icon">
                                            <img src={passport2Url ? passport2Url : require('../assets/images/icon-passport.png')} alt="icon" />
                                            <img src={passport2Url ? passport2Url : require('../assets/images/icon-passport-color.png')}
                                                alt="icon" />
                                        </div>
                                        <span>{translate('Passport-Back')}</span>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                    >
                                        <label htmlFor="national2-photo">
                                            <p>{national2?.name?.substring(0, trimLength)}</p>
                                            <input
                                                style={{ display: 'none' }}
                                                id="national2-photo"
                                                name="national2-photo"
                                                type="file"
                                                onChange={handleChange}
                                            />
                                            <Fab color="primary" size="small" component="span" aria-label="add">
                                                <AddIcon />
                                            </Fab>
                                        </label>
                                        <div className="nav-tabs-icon">
                                            <img src={national2Url ? national2Url : require('../assets/images/icon-national-id.png')} alt="icon" />
                                            <img src={national2Url ? national2Url : require('../assets/images/icon-national-id-color.png')}
                                                alt="icon" />
                                        </div>
                                        <span>{translate('National-Card-Back')}</span>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                    >
                                        <label htmlFor="licence2-photo">
                                            <p>{licence2?.name?.substring(0, trimLength)}</p>
                                            <input
                                                style={{ display: 'none' }}
                                                id="licence2-photo"
                                                name="licence2-photo"
                                                type="file"
                                                onChange={handleChange}
                                            />
                                            <Fab color="primary" size="small" component="span" aria-label="add">
                                                <AddIcon />
                                            </Fab>
                                        </label>
                                        <div className="nav-tabs-icon">
                                            <img src={licence2Url ? licence2Url : require('../assets/images/icon-licence.png')} alt="icon" />
                                            <img src={licence2Url ? licence2Url : require('../assets/images/icon-licence-color.png')}
                                                alt="icon" />
                                        </div>
                                        <span>{translate('Drivers-License-Back')}</span>
                                    </div>
                                </li>

                                <p></p>
                                <li className="nav-item">
                                    <div className="input-item input-with-label">
                                        <TextField id="passport-number" value={data?.PassportNumber} onChange={handleEdit} defaultValue={data?.PassportNumber} label={translate('passport-Number')} className='input-bordered' />
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className="input-item input-with-label">
                                        <TextField id="national-number" value={data?.NationalIDNumber} onChange={handleEdit} defaultValue={data?.NationalIDNumber} label={translate('national-Number')} className='input-bordered' />
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className="input-item input-with-label">
                                        <TextField id="license-number" value={data?.LicenseNumber} onChange={handleEdit} defaultValue={data?.LicenseNumber} label={translate('license-Number')} className='input-bordered' />
                                    </div>
                                </li>

                                <p></p>
                                <li className="nav-item">
                                    <div className="input-item input-with-label">
                                        <DesktopDatePicker
                                            id="passport-expirydate"
                                            disablePast={true}
                                            label={translate('passport-expirydate')}
                                            inputFormat="yyyy/MM/dd"
                                            value={dtPassportExpiryDate}
                                            onChange={handlePassportDate}
                                            renderInput={(params) => <TextField className='input-bordered' {...params} />}
                                        />
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className="input-item input-with-label">
                                        <DesktopDatePicker
                                            id="national-expirydate"
                                            disablePast={true}
                                            label={translate('national-expirydate')}
                                            inputFormat="yyyy/MM/dd"
                                            value={dtNationalIDExpiryDate}
                                            onChange={handleNationalDate}
                                            renderInput={(params) => <TextField className='input-bordered' {...params} />}
                                        />
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className="input-item input-with-label">
                                        <DesktopDatePicker
                                            id="license-expirydate"
                                            disablePast={true}
                                            label={translate('license-expirydate')}
                                            inputFormat="yyyy/MM/dd"
                                            value={dtLicenseExpiryDate}
                                            onChange={handleLicenseDate}
                                            renderInput={(params) => <TextField className='input-bordered' {...params} />}
                                        />
                                    </div>
                                </li>

                                <p>{translate('Photo-message')}</p>
                                <li className="nav-item">
                                    <div
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                    >
                                        <label htmlFor="upload-photo-1">
                                            <p>{photo1?.name?.substring(0, trimLength)}</p>
                                            <input
                                                style={{ display: 'none' }}
                                                id="upload-photo-1"
                                                name="upload-photo-1"
                                                type="file"
                                                onChange={handleChange}
                                            />
                                            <Fab color="primary" size="small" component="span" aria-label="add">
                                                <AddIcon />
                                            </Fab>
                                        </label>
                                        <div className="nav-tabs-icon">
                                            <img src={photo1Url ? photo1Url : require('../assets/images/icon-licence.png')} alt="icon" />
                                            <img src={photo1Url ? photo1Url : require('../assets/images/icon-licence-color.png')}
                                                alt="icon" />
                                        </div>
                                        <span>{translate('Photo-1')}</span>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                    >
                                        <label htmlFor="upload-photo-2">
                                            <p>{photo2?.name?.substring(0, trimLength)}</p>
                                            <input
                                                style={{ display: 'none' }}
                                                id="upload-photo-2"
                                                name="upload-photo-2"
                                                type="file"
                                                onChange={handleChange}
                                            />
                                            <Fab color="primary" size="small" component="span" aria-label="add">
                                                <AddIcon />
                                            </Fab>
                                        </label>
                                        <div className="nav-tabs-icon">
                                            <img src={photo2Url ? photo2Url : require('../assets/images/icon-licence.png')} alt="icon" />
                                            <img src={photo2Url ? photo2Url : require('../assets/images/icon-licence-color.png')}
                                                alt="icon" />
                                        </div>
                                        <span>{translate('Photo-2')}</span>
                                    </div>
                                </li>
                            </ul>
                            {/* .nav-tabs-line */}
                        </div>}
                        {/* .from-step-content */}
                        {(passportUrl === "" && licenceUrl === "" && nationalUrl === "") ? <Alert severity="error">Passport,National Id or Driving License not submitted</Alert> : ''}
                        {(photo1Url === "") ? <Alert severity="error">Applicant photo not submitted</Alert> : ''}
                        {(photo2Url === "") ? <Alert severity="error">Profile photo not submitted</Alert> : ''}
                    </div>
                    {/* .from-step-item */}
                    <div className="from-step-item">
                        <div className="from-step-content">
                            <div className='Message-Red'>{translate('Change-Request-Message1')}</div>
                            <div className='Message-Red'>{translate('Change-Request-Message2')}</div>
                            <FormControlLabel control={<Checkbox
                                onChange={handleIsAgreeChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                checked={isAgree ? true : false}
                            />} label={translate('Agree-Message')} />
                            {/* .input-item */}
                            <div className="gaps-1x" />
                            {/* 20px gap */}
                            <Button
                                color="primary"
                                variant="contained"
                                component="span"
                                className="btn btn-secondary"
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#kycConfirm"
                                onClick={submitKyc}
                            >
                                {translate('Change-Request')}
                            </Button>
                            <div className="gaps-1x" />
                            {submitted === 200 ? <Alert severity="success">Success!</Alert> : submitted !== 0 ? <Alert severity="error">Error! Contact Support</Alert> : ''}
                            <div className="gaps-1x" />
                            {/* 20px gap */}
                        </div>
                        {/* .from-step-content */}
                    </div>
                    {/* .from-step-item */}
                </div>
                {/* .from-step */}
            </form>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        users: state.remote.users
    };
};
export default connect(mapStateToProps, {
})(KycDetails);
