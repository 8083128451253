import React, { useState, useEffect } from "react";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;
let CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

const ProductDisplay = () => (
  <section>
    <div className="product">
      <img
        src="/static/media/logo.c0d9a527.PNG"
        alt="The cover of Stubborn Attachments"
      />
      <div className="description">
      <h3>AOS Coin</h3>
      <h5>$20.00</h5>
      </div>
    </div>
    <button className='go-to-checkout-button' onClick={goToCheckout}>
        Go To Checkout
    </button>
  </section>
);

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const goToCheckout = (e) => {
  e.preventDefault();
  fetch(SERVER_URL + `/create-checkout-session`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      /*body: JSON.stringify({ items: { 
        price: 20, 
        quantity: 1,
      }}),*/
  })
  .then(res => {
      if (res.ok) return res.json()
      return res.json().then(json => Promise.reject(json))
  })
  .then(( body ) => {
      console.log(body);
      window.location.href = body.url
  })
  .catch((error) => {
      alert("Create Stripe checkout:" + error);
  });
};

const Payment = () => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return message ? (
    <Message message={message} />
  ) : (
    <ProductDisplay />
  );
}

export default Payment;