import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import {connect} from 'react-redux';


const Layout = (props) => {
    return (
        <>
            {/* Topbar */}
            {props.navBar && <Navbar/>}
            {/* TopBar End */}
            <div className="user-wraper">
                <div className="container">
                    <div className="d-flex">
                        {props.sideBar && <Sidebar/>}
                        <div className="user-content">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>

            {/*Footer*/}
            {props.footer && <Footer/>}
            {/*Footer End*/}
        </>
    );
};
const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
        sideBar: state.siteLayout.sideBar,
        footer: state.siteLayout.footer,
    };
};

export default connect(mapStateToProps, {})(Layout);
