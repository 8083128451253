import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { formatDate } from "../store/action/remoteAction";
import { resetTimout } from "./common/Firebase";

let CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const ReferralCode = (props) => {
    const [isReferralCodeCopied, setIsReferralCodeCopied] = useState(false);
    const [isReferralUrlCopied, setIsReferralUrlCopied] = useState(false);

    const [data, setData] = useState({
        'UserNameFirst': '',
        'UserNameLast': '',
        'UserNameLast_DByte': '',
        'UserNameFirst_DByte': '',
        'MailID': '',
        'DOB': '',
        'Nationality': '',
        'PhoneNumber1': '',
        'Coupon': '',
    });

    const copyReferralCode = () => {
        resetTimout();
        setIsReferralCodeCopied(false);
        setIsReferralUrlCopied(false);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(data?.Coupon);
            setIsReferralCodeCopied(true);
        }
        else {
            alert(data?.Coupon);
        }
    };

    const copyReferralUrl = () => {
        resetTimout();
        setIsReferralCodeCopied(false);
        setIsReferralUrlCopied(false);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(CLIENT_URL + "/signup?coupon=" + data?.Coupon);
            setIsReferralUrlCopied(true);
        }
        else {
            alert(CLIENT_URL + "/signup?coupon=" + data?.Coupon);
        }
    };

    useEffect(() => {
        //actionGetUserProfile request send from Navbar
    }, []);

    useEffect(() => {
        //console.log('Hello v '+props?.users[0]?.WalletId);
        if (props?.users[0]?.KycStatus === null || props?.users[0]?.KycStatus.length === 0) {
            props.users[0].KycStatus = "In-Progress";
        }
        setData(props?.users[0]);
    }, [props.users]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-ReferralCode')}</h2>

            {/* .tab-panel wallet */}
            <div className="tab-pane" id="referral-code">
                <form action="#">
                    {/* .row */}
                    <div>
                        <div className="gaps-1x" />
                        <div className="input-item input-with-label">
                            <TextField defaultValue={data?.Coupon} value={data?.Coupon} id="coupon-code" label={translate('coupon-code')} className='input-bordered' />
                        </div>
                        <div align='right'>
                            <button className="btn-primary" onClick={() => copyReferralCode()}>{translate('Copy-to-clipboard')}</button>
                        </div>
                        <div className="gaps-1x" />

                        <div className="input-item input-with-label">
                            <TextField defaultValue={data?.Coupon} value={CLIENT_URL + "/signup?coupon=" + data?.Coupon} id="coupon-address" label={translate('coupon-address')} className='input-bordered' />
                        </div>
                        <div align='right'>
                            <button className="btn-primary" onClick={() => copyReferralUrl()}>{translate('Copy-to-clipboard')}</button>
                        </div>
                        <div className="gaps-1x" />
                        <p>
                            {translate('referralcode-message')}
                        </p>
                        <p>
                            <b>{translate('referralcode-title')}</b>
                        </p>
                        <p>
                            {translate('referralcode-details')}
                        </p>
                        <p>
                            <b>{translate('referralbonus-title')}</b>
                        </p>
                        <p>
                            {translate('referralbonus-details')}
                        </p>
                    </div>

                    {/* .input-item */}
                    <div className="gaps-1x" />
                    {/* 10px gap */}
                    <div className="d-sm-flex justify-content-between align-items-center">
                        <div className="gaps-2x d-sm-none" />
                        <span className="color-success">
                            {isReferralCodeCopied &&
                                <div>
                                    <em className="ti ti-check-box" /> {translate('coupon-code-copied-message')}
                                </div>
                            }
                            {isReferralUrlCopied &&
                                <div>
                                    <em className="ti ti-check-box" /> {translate('coupon-address-copied-message')}
                                </div>
                            }
                        </span>
                    </div>
                </form>
                {/* form */}
            </div>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        users: state.remote.users
    };
};

export default connect(mapStateToProps, {
})(ReferralCode);
