import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Activity = () => {
    return (
        <div className="user-panel">
            <h2 className="user-panel-title">Activity</h2>
            <p>
                Here is your recent activities. you can also clear this log as well as
                disable this feature from security settings.{" "}
            </p>

            {/* NEW TABLE */}

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className="data-table tranx-table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" component="th">ID</TableCell>
                            <TableCell align="center" component="th">Date</TableCell>
                            <TableCell align="center" component="th">Device</TableCell>
                            <TableCell align="center" component="th">Browser</TableCell>
                            <TableCell align="center" component="th">IP</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align='center'>1</TableCell>
                            <TableCell align='center'>05-24-2022 11:09PM</TableCell>
                            <TableCell align='center'>Android</TableCell>
                            <TableCell align='center'>Chrome</TableCell>
                            <TableCell align='center'>198.31.170.21</TableCell>
                        </TableRow>

                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align='center'>2</TableCell>
                            <TableCell align='center'>05-24-2022 11:09PM</TableCell>
                            <TableCell align='center'>Iphone</TableCell>
                            <TableCell align='center'>Chrome</TableCell>
                            <TableCell align='center'>198.31.170.21</TableCell>
                        </TableRow>

                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align='center'>3</TableCell>
                            <TableCell align='center'>05-24-2022 11:09PM</TableCell>
                            <TableCell align='center'>Android</TableCell>
                            <TableCell align='center'>Chrome</TableCell>
                            <TableCell align='center'>198.31.170.21</TableCell>
                        </TableRow>

                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align='center'>4</TableCell>
                            <TableCell align='center'>05-24-2022 11:09PM</TableCell>
                            <TableCell align='center'>Iphone</TableCell>
                            <TableCell align='center'>Chrome</TableCell>
                            <TableCell align='center'>198.31.170.21</TableCell>
                        </TableRow>

                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align='center'>5</TableCell>
                            <TableCell align='center'>05-24-2022 11:09PM</TableCell>
                            <TableCell align='center'>Desktop</TableCell>
                            <TableCell align='center'>Chrome</TableCell>
                            <TableCell align='center'>198.31.170.21</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/* NEW TABLE END */}
        </div >
    );
};

export default Activity;
