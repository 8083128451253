import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import store from '../src/store';

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import '../src/assets/css/vendor.bundle.css';
import '../src/assets/css/style.css';

ReactDOM.render(
    <Provider store={store}><BrowserRouter>
        <App className="container"/>
    </BrowserRouter></Provider>,
    document.getElementById("root")
);

serviceWorker.unregister();
