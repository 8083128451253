import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Select, MenuItem, FormControl } from "@mui/material";
import translate from "../i18n/translate";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import connect from "react-redux/es/components/connect";
import { client } from "../store/action/remoteAction";
import { LOCALES } from '../i18n/constants';
import { resetTimout } from "./common/Firebase";
import { getMultiLanguageMessage } from '../i18n/multiLanguage';

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;

const DemoDeposit = (props) => {
    const [clientId, setClientId] = useState('');
    const [depositRequestAmount, setDepositRequestAmount] = useState(0);
    const [senderBankId, setSenderBankId] = useState(0);
    const [senderBankName, setSenderBankName] = useState('');
    const [language, setLanguage] = useState("");
    const [pageId, setPageId] = useState(0);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const submitWithdrawRequest = async () => {
        resetTimout();
        let uid = localStorage.getItem("uid");

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            setIsButtonClicked(true);
            const request = {
                "UserID": uid,
                "ClientID": clientId,
                "Currency": "EURO",
                "Amount": "" + depositRequestAmount + "",
                "SenderBankID": senderBankId,
                "CreatedByUser": uid
            };
            await client(localStorage.getItem("@token")).post(`${BASE_URL}/deposit/demodeposit`, request).then((response) => {
                window.location.href = '/cashtransaction';
            }).catch(error => {
                let errorMessage = "Error: ";
                if (error && error.message) {
                    errorMessage += error.message;
                } else {
                    errorMessage += JSON.stringify(error)
                }
                alert(errorMessage);
                setIsButtonClicked(false);
            });
        }
    };

    const goToConfirmationPage = (e) => {
        resetTimout();
        let errorIncorrectAmunt = "Incorrect Amount. Enter upto 2 decimal places.";
        let errorEmptySenderBank = "Please select account of sender";
        if (language === LOCALES.JAPANESE) {
            errorIncorrectAmunt = '金額が正しくありません。 小数点以下2桁まで入力してください。';
            errorEmptySenderBank = "送金元口座を選択してください";
        }

        if (senderBankId <= 0) {
            alert(errorEmptySenderBank);
            return;
        }

        if (!depositRequestAmount || !depositRequestAmount.match(/^\d{1,}(\.\d{0,2})?$/) || parseFloat(depositRequestAmount) <= 0) {
            alert(errorIncorrectAmunt);
            return;
        }

        setPageId(1);
    };

    const goToPrevPage = (e) => {
        resetTimout();
        setPageId(0);
    };

    const handleChange = (e) => {
        resetTimout();
        switch (e.target.id) {
            case "id_DepositAmount":
                setDepositRequestAmount(e.target.value.replace(/[^0-9.]/g, ""));
                break;
            default: console.log('err');
        }
    };

    const handleBankAccountChange = (e) => {
        resetTimout();
        setSenderBankId(e.target.value);
        if (e.target.value === 1) {
            setSenderBankName('Progress Demo Bank');
        } else if (e.target.value === 2) {
            setSenderBankName('Malta Demo Bank');
        } else {
            setSenderBankName('');
        }
    };

    useEffect(() => {
        if (props?.users.length > 0) {
            setClientId(props?.users[0]?.ClientID);
        }
    }, [props.users]);

    useEffect(() => {
        props.actionToggleNavBar(true);
        props.actionToggleSideBar(true);
        props.actionToggleFooter(true);
        setLanguage(localStorage.getItem("locale"));
    }, []);

    return (
        <div className="withdraw-panel">
            <h2 className="user-panel-title">{translate('DepositRequest')}</h2>

            <div className="gaps-1x" />
            <div>
                <div className="gaps-1x" />

                {pageId === 0 &&
                    <div>
                        <table width='100%'>
                            <tr>
                                <td className='td-type2-col1'>{translate('SenderAccount')}</td>
                                <td className='td-type2-col2'>
                                    <FormControl style={{ maxWidth: 400 }}>
                                        <Select id="withdrawBank" value={senderBankId} onChange={handleBankAccountChange} className="input-item-label">
                                            <MenuItem value={0}>
                                                <em>{translate('Select-SenderAccount')}</em>
                                            </MenuItem>
                                            <MenuItem value={1}>{'Progress Demo Bank'}</MenuItem>
                                            <MenuItem value={2}>{'Malta Demo Bank'}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr>
                                <td className='td-type2-col1'>{translate('DepositAmount')}</td>
                                <td className='td-type2-col2'>
                                    <TextField
                                        size="small"
                                        id="id_DepositAmount"
                                        sx={{ width: '24.5ch' }}
                                        value={depositRequestAmount}
                                        defaultValue={''}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{translate('Euro')}</InputAdornment>,
                                        }}
                                    />
                                </td>
                            </tr>
                        </table>

                        <div className="gaps-3x" />
                        <button className="btn btn-primary" onClick={goToConfirmationPage}>{translate('To-Confirm-Page')}</button>
                    </div>
                }

                {pageId === 1 &&
                    <div>
                        <table width='100%'>
                            <tr>
                                <td width={'40%'}>{translate('SenderAccount')}</td>
                                <td width={'60%'}>{senderBankName}</td>
                            </tr>
                            <tr>
                                <td width={'40%'}>{translate('DepositAmount')}</td>
                                <td width={'60%'}>{depositRequestAmount}&nbsp;{translate('Euro')}</td>
                            </tr>
                        </table>
                        {/* .row */}
                        <div className="gaps-3x" />
                        <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => goToPrevPage(event)}>{translate('button-Cancel')}</button>&nbsp;&nbsp;
                        <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={submitWithdrawRequest}>{translate('button-DepositRequest')}</button>
                        <div className="gaps-2x" />
                    </div>
                }

            </div>
            {/* form */}
        </div >

    );
};

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
        users: state.remote.users
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
})(DemoDeposit);
