import React from 'react';
import translate from "../i18n/translate";

const HowTo = () => {
    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('How-to-Buy-token')}</h2>
            <div className="user-panel-content">
                {/*<p>
                    <strong>
                        Investors really are spoiled for choice. There are a lot of serious ICOs
                        raising funds for important and useful projects, At the same time.
                    </strong>
                </p>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                        className="embed-responsive-item"
                        src="https://www.youtube-nocookie.com/embed/nO8RRutn8uo?rel=0&showinfo=0"
                        frameBorder={0}
                        allow="autoplay; encrypted-media"
                        allowFullScreen=""
                    />
                </div>*/}
                <h6>{translate('how-to-step-1')}</h6>
                {/*<p>
                    Every legitimate project that sources funds through an ICO has a website,
                    where they specify what the project is all about, its goals, the amount of
                    money needed, how long the funding campaign will go on for and so forth.
                </p>*/}
                <img src={require('../assets/images/human-a.jpg')} alt="human" />
                <h6>{translate('how-to-step-2')}</h6>
                <h6>{translate('how-to-step-3')}</h6>
                <h6>{translate('how-to-step-4')}</h6>
                <h6>{translate('how-to-step-5')}</h6>
                <h6>{translate('how-to-step-6')}</h6>
                <h6>{translate('how-to-step-7')}</h6>
            </div>
        </div>
    );
};

export default HowTo;
