import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { actionGetCashBalance, actionGetBitcoinBalance, actionGetCommissionRate } from "../store/action/remoteAction";
import { getMultiLanguageMessage } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";
import { client } from "../store/action/remoteAction";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;
let BASE_URL = process.env.REACT_APP_SERVER_URL_API;
//const App_Environment = process.env.REACT_APP_ENVIRONMENT;
const App_Environment = "0"; //To use Paymix Gateway for Demo

const Bid = (props) => {
    const [pageId, setPageId] = useState(0);
    const [aosRemaining, setAosRemaining] = useState(0);
    const [aos, setAos] = useState(0);
    const [currency, setCurrency] = useState('');
    const [decimalCount, setDecimalCount] = useState(2);
    const [aosRate, setAosRate] = useState(0);
    const [aosBuyAmount, setAosBuyAmount] = useState(0);
    const [commissionID, setCommissionID] = useState(0);
    const [commissionAmount, setCommissionAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [paymentType, setPaymentType] = useState("");
    const [euroBalance, setEuroBalance] = useState(0);
    const [btcBalance, setBtcBalance] = useState(0);
    const [btcGasFee, setBtcGasFee] = useState(0);
    const [btcTransactionHash, setBtcTransactionHash] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [settlementTransactionId, setSettlementTransactionID] = useState("");
    const [isPartialOrderFlag, setIsPartialOrderFlag] = useState(false);
    const [language, setLanguage] = useState("");
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        //console.log(query);
        var vars = query.split("&");
        //console.log(vars); 
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            //console.log(pair);
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return ("");
    };

    const onChangeAOSToken = (e) => {
        resetTimout();
        let requestedAos = e.target.value.replace(/[^0-9.]/g, "");
        let nAmount = 0;

        setAos(requestedAos);
        if (requestedAos) {
            if (currency === 'BTC') {
                nAmount = parseFloat((parseFloat(requestedAos) * aosRate).toFixed(decimalCount));
            } else {
                nAmount = (parseFloat(requestedAos) * aosRate).toFixed(decimalCount);
            }
        }
        setAosBuyAmount(nAmount);
        calculateCommissionAmount(requestedAos, nAmount, currency);
    }

    const calculateCommissionAmount = (buyQuantity, buyAmount, buyCurrency) => {
        if (!props.commissionRates || props.commissionRates.length <= 0) {
            return;
        }
        let commissionRate = props.commissionRates[0];
        setCommissionID(commissionRate.CommissionID);
        let nCommissionAmount = 0;
        if (commissionRate.CommissionCalcType === 1) {
            nCommissionAmount = parseFloat((parseFloat(buyQuantity) * parseFloat(commissionRate.CommissionValue)).toFixed(2));
        } else if (commissionRate.CommissionCalcType === 2) {
            nCommissionAmount = parseFloat((parseFloat(buyAmount) * (parseFloat(commissionRate.CommissionValue) / 100)).toFixed(2));
        }
        if (!nCommissionAmount) {
            nCommissionAmount = 0;
        }
        setCommissionAmount(nCommissionAmount);
        let nTotalAmount = 0;
        if (buyCurrency === 'BTC') {
            nTotalAmount = buyAmount;
        } else {
            if (parseFloat(buyAmount) > 0) {
                nTotalAmount = (parseFloat(buyAmount) + parseFloat(nCommissionAmount)).toFixed(2);
            }
        }
        setTotalAmount(nTotalAmount);
    };

    const onChangePaymentType = (e) => {
        resetTimout();
        setPaymentType(e.target.value);
    };

    /*const goToPaymentPage = (e) => {
        resetTimout();
        window.location.href = '/bids?tid=' + props.transactions[e].TransactionID;
    };*/

    const goToConfirmationPage = async (e) => {
        resetTimout();
        if (!transactionId) {
            alert('Invalid Selection');
            return;
        }
        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0 || aosBuyAmount <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aos) > aosRemaining) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (currency === 'EURO' && paymentType === 'Cash' && parseFloat(euroBalance) < parseFloat(aosBuyAmount)) {
            alert(getMultiLanguageMessage('msg_InsufficientBalance_Error', language));
            return;
        }
        if (currency === 'BTC' && paymentType === 'Cash' && parseFloat(euroBalance) < parseFloat(commissionAmount)) {
            alert(getMultiLanguageMessage('msg_InsufficientBalance_Error', language));
            return;
        }
        if (!props.commissionRates || props.commissionRates.length <= 0) {
            alert("Transaction Fee calculation error. Please contact customer support.");
            return;
        }

        if (currency === 'BTC') {
            //Get Bitcoin Gas Fee
            let bodyJSON = {
                items: {
                    BuySellFlag: 1,
                    Amount: aosBuyAmount,
                    UnitPrice: aosRate,
                    Quantity: aos,
                    UserId: localStorage.getItem("uid"),
                    Tid: transactionId,
                    OtcFlag: 1,
                    CommissionID: commissionID,
                    CommissionAmount: commissionAmount,
                    PaymentType: paymentType === 'Cash' ? 1 : 2,
                    ToWalletID: ''
                }
            };
            setIsButtonClicked(true);
            await client(localStorage.getItem("@token")).post(`${BASE_URL}/transactions/btc/gas`, bodyJSON).then((response) => {
                setIsButtonClicked(false);
                if (response.data) {
                    setPageId(1);
                    if (response.data.ErrorCode == 1) {
                        alert(response.data.ErrorMessage);
                    } else {
                        setSettlementTransactionID(response.data.TransactionID);
                        setBtcGasFee(response.data.GasFee);
                        setBtcTransactionHash(response.data.TransactionHash);
                        setPageId(2);
                    }
                } else {
                    alert("Error! Please contact customer support");
                }
            }).catch(error => {
                setIsButtonClicked(false);
                alert("Error! Please contact customer support");
                console.log(error);
            });
        } else {
            setPageId(2);
        }
    };

    const goToPrevPage = (e) => {
        resetTimout();
        setPageId(1);
    };


    const goToCheckout = (e) => {
        resetTimout();
        e.preventDefault();

        if (!transactionId) {
            alert('Invalid Selection');
            return;
        }
        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0 || aosBuyAmount <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aos) > aosRemaining) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (currency === 'BTC') {
            if (parseFloat(btcBalance) < (parseFloat(aosBuyAmount) + parseFloat(btcGasFee))) {
                alert(getMultiLanguageMessage('msg_InsufficientBitcoinBalance_Error', language));
                return;
            }
            if (paymentType === 'Cash' && parseFloat(euroBalance) < parseFloat(commissionAmount)) {
                alert(getMultiLanguageMessage('msg_InsufficientBalance_Error', language));
                return;
            }
        } else if (currency === 'EURO') {
            if (paymentType === 'Cash' && parseFloat(euroBalance) < parseFloat(totalAmount)) {
                alert(getMultiLanguageMessage('msg_InsufficientBalance_Error', language));
                return;
            }
        }

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            let serverUrl = '';
            let bodyJSON = JSON.stringify({
                items: {
                    BuySellFlag: 1,
                    Currency: currency,
                    Amount: aosBuyAmount,
                    UnitPrice: aosRate,
                    Quantity: aos,
                    UserId: localStorage.getItem("uid"),
                    Tid: transactionId,
                    CommissionID: commissionID,
                    CommissionAmount: commissionAmount,
                    OtcFlag: 1,
                    TransactionHash: btcTransactionHash,
                    SettlementTransactionID: settlementTransactionId
                }
            });

            if (currency === 'BTC' && paymentType === 'CreditCard') {
                serverUrl = SERVER_URL + `/btc-buysettlement-otc-checkout`;
            } else if (currency === 'BTC' && paymentType === 'Cash') {
                serverUrl = SERVER_URL + `/btc-buysettlement-otc-cashbalance`;
            } else if (currency === 'EURO' && paymentType === 'CreditCard') {
                if (App_Environment === "2") {
                    localStorage.setItem("aos-creditdemo", bodyJSON);
                    let cardHolderName = props?.users[0]?.UserNameFirst + " " + props?.users[0]?.UserNameLast;
                    localStorage.setItem("aos-cardholdername", cardHolderName);
                    window.location.href = '/credit-card-demo';
                } else {
                    serverUrl = SERVER_URL + `/create-checkout-session-paymix-otc`;
                }
            } else if (currency === 'EURO' && paymentType === 'Cash') {
                serverUrl = SERVER_URL + `/otcbuy-cashbalance`;
            } else {
                return;
            }

            if (serverUrl) {
                setIsButtonClicked(true);
                fetch(serverUrl, {
                    method: 'POST',
                    redirect: 'follow',
                    headers: { 'Content-Type': 'application/json' },
                    body: bodyJSON,
                })
                    .then(async res => {
                        if (res.ok) {
                            return res.json();
                        } else {
                            const json = await res.json();
                            return await Promise.reject(json);
                        }
                    })
                    /*.then(res => {
                        if (res.ok) return res.json()
                        return res.json().then(json => Promise.reject(json))
                    })*/
                    .then((body) => {
                        window.location.href = body.url;
                    })
                    .catch((error) => {
                        let errorMessage = "Error: ";
                        if (error && error.message) {
                            errorMessage += error.message;
                        } else {
                            errorMessage += JSON.stringify(error);
                        }
                        alert(errorMessage);
                        setIsButtonClicked(false);
                    });
            }
        }
    };

    const getOrderDetails = async (tid) => {
        if (!tid) {
            return;
        }
        client(localStorage.getItem("@token")).get(`${BASE_URL}/transactions/otc/${tid}`).then((response) => {
            if (response.status == 200 && response.data.length === 1) {
                let txnSell = response.data[0];
                if (parseFloat(txnSell.RemainingQuantity) > 0) {
                    setAos(parseFloat(txnSell.RemainingQuantity).toFixed(2));
                    setAosRemaining(parseFloat(txnSell.RemainingQuantity).toFixed(2));
                    setCurrency(txnSell.Currency);
                    setDecimalCount(txnSell.DecimalCount);
                    let nAosRate = parseFloat(txnSell.UnitPrice).toFixed(txnSell.DecimalCount);
                    let nAmount = (parseFloat(txnSell.UnitPrice) * parseFloat(txnSell.RemainingQuantity)).toFixed(txnSell.DecimalCount);
                    if (txnSell.Currency === 'BTC') {
                        nAosRate = parseFloat(nAosRate);
                        nAmount = parseFloat(nAmount);
                        props.actionGetBitcoinBalance();
                    }
                    setAosRate(nAosRate);
                    setAosBuyAmount(nAmount);
                    setTransactionId(txnSell.TransactionID);
                    calculateCommissionAmount(parseFloat(txnSell.RemainingQuantity).toFixed(2), nAmount, txnSell.Currency);
                    if (txnSell.PartialOrderFlag === 1) {
                        setIsPartialOrderFlag(true);
                    } else {
                        setIsPartialOrderFlag(false);
                    }
                    setPageId(1);
                } else {
                    handleOrderError();
                }
            } else {
                handleOrderError();
            }
        }).catch(error => {
            handleOrderError();
            console.log(error);
        });
    };

    const handleOrderError = () => {
        let sLanguage = localStorage.getItem("locale");
        alert(getMultiLanguageMessage('msg_InvalidSellOrder', sLanguage));
        //window.location.href = '/bids';
    };

    //const goToPromoterApplyPage = () => {
    //    window.location.href = '/promoterapply';
    //};

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        //actionGetUserProfile request send from Navbar
        let tid = getQueryVariable("tid");

        props.actionToggleNavBar(true);
        props.actionToggleSideBar(true);
        props.actionToggleFooter(true);
        if (tid && tid.length > 0) {
            setTransactionId(tid);
            props.actionGetCashBalance();
        } else {
            alert('Invalid Selection');
        }
    }, []);

    useEffect(() => {
        if (props?.users && props.users.length > 0) {
        }
    }, [props.users]);

    useEffect(() => {
        if (props.cashBalances?.length > 0) {
            let userBalance = props.cashBalances[0];
            setEuroBalance(userBalance.Balance);
        }
    }, [props.cashBalances]);

    useEffect(() => {
        if (props?.bitcoinBalances && props?.bitcoinBalances.length > 0) {
            setBtcBalance(props?.bitcoinBalances[0].Balance.toFixed(8));
        }
    }, [props.bitcoinBalances]);

    useEffect(() => {
        if (transactionId) {
            props.actionGetCommissionRate(1);
        }
    }, [transactionId]);

    useEffect(() => {
        if (transactionId) {
            getOrderDetails(transactionId);
        }
    }, [props.commissionRates]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-OTCBuy-Settlement')}</h2>
            <div className="gaps-1x" />

            {pageId === 1 &&
                <div>
                    <div className="gaps-1x" />
                    <div>
                        <TextField
                            size="small"
                            label={translate('Order-Quantity')}
                            id="outlined-start-adornment-aos"
                            sx={{ width: '24.5ch' }}
                            value={aos}
                            onChange={onChangeAOSToken}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">AOS</InputAdornment>,
                            }}
                            disabled={isPartialOrderFlag ? false : true}
                        />
                    </div>

                    <div>
                        <div className="gaps-2x" />
                        <TextField
                            size="small"
                            label={translate('Amount')}
                            id="outlined-start-adornment-rate"
                            sx={{ width: '24.5ch' }}
                            value={aosBuyAmount}
                            defaultValue={aosBuyAmount}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</InputAdornment>,
                            }}
                            disabled={true}
                        />
                    </div>

                    <div>
                        <div className="gaps-2x" />
                        <TextField
                            size="small"
                            label={translate('Commission-Amount')}
                            id="commission-amount"
                            sx={{ width: '24.5ch' }}
                            value={commissionAmount}
                            defaultValue={commissionAmount}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{translate('Euro')}</InputAdornment>,
                            }}
                            disabled={true}
                        />
                    </div>

                    <div className="gaps-2x" />
                    <div>
                        <TextField
                            size="small"
                            label={translate('Payment-Amount')}
                            id="outlined-start-adornment-euro"
                            sx={{ width: '24.5ch' }}
                            value={totalAmount}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</InputAdornment>,
                            }}
                            disabled={true}
                        />
                    </div>
                    {currency === 'BTC' &&
                        <div>
                            <div className="col-md-8">
                                <label className="input-item-label">{translate('Bitcoin-Balance')}&nbsp;:&nbsp;{btcBalance} {translate('Bitcoin')}</label>
                            </div>
                        </div>
                    }

                    <div className="gaps-1x" />
                    <div onChange={onChangePaymentType}>
                        <input className="payment-Type" type="radio" value="Cash" name="paymentType" checked={paymentType === 'Cash' ? true : false} /> {translate('Payment-By-Cash')}
                        <input className="payment-Type" type="radio" value="CreditCard" name="paymentType" checked={paymentType === 'CreditCard' ? true : false} /> {translate('Payment-By-CreditCard')}
                    </div>

                    {paymentType === 'Cash' &&
                        <div>
                            <div className="col-md-8">
                                <label className="input-item-label">{translate('Cash-Balance')}&nbsp;:&nbsp;{euroBalance} {translate('Euro')}</label>
                            </div>
                        </div>
                    }
                    {isButtonClicked &&
                        <>
                            <div className="gaps-1x" />
                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                        </>
                    }
                    <div className="gaps-1x" />
                    {(paymentType === 'Cash' || paymentType === 'CreditCard') &&
                        <a
                            href="#"
                            className="btn btn-primary payment-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#tranxETH"
                            disabled={isButtonClicked ? true : false}
                            onClick={event => goToConfirmationPage(event)}
                        >
                            {translate('To-Confirm-Page')}
                        </a>
                    }
                </div>
            }

            {pageId === 2 &&
                <div>
                    <table width='70%'>
                        <tbody>
                            <tr>
                                <td align='left' >{translate('Rate')} :</td>
                                <td align='right'>{aosRate} {currency === 'BTC' ? translate('Bitcoin-Aos') : translate('Euro-Aos')} </td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Order-Quantity')} :</td>
                                <td align='right' >{aos} AOS</td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Currency')} :</td>
                                <td align='right'>{currency === 'BTC' ? translate('Currency-Bitcoin') : translate('Currency-Euro')} </td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Amount')} :</td>
                                <td align='right'>{aosBuyAmount} {currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</td>
                            </tr>
                            <tr>
                                <td align='left'>{translate('Commission-Amount')} :</td>
                                <td align='right'>{commissionAmount.toFixed(2)} {translate('Euro')}</td>
                            </tr>
                            {currency === 'BTC' &&
                                <tr>
                                    <td align='left' >{translate('GasFee')} :</td>
                                    <td align='right'>{parseFloat(btcGasFee)} {translate('Bitcoin')}</td>
                                </tr>
                            }
                            <tr>
                                <td align='left' >{translate('Payment-Amount')} :</td>
                                <td align='right'>{currency === 'BTC' ? parseFloat((parseFloat(aosBuyAmount) + parseFloat(btcGasFee)).toFixed(decimalCount)) : parseFloat(totalAmount).toFixed(decimalCount)} {currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</td>
                            </tr>
                            {currency === 'BTC' &&
                                <tr>
                                    <td align='left'>{currency === 'BTC' ? translate('Commission-Payment-Type') : translate('Payment-Type')} :</td>
                                    <td align='right'>{paymentType === 'CreditCard' ? translate('Payment-By-CreditCard') : translate('Payment-By-WalletBalance')} </td>
                                </tr>
                            }
                        </tbody>
                    </table>

                    {isButtonClicked &&
                        <>
                            <div className="gaps-1x" />
                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                        </>
                    }
                    <div className="gaps-1x" />
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => goToPrevPage(event)}>{translate('Back-Btn')}</button>&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => goToCheckout(event)}>{translate('Submit')}</button>
                    <div className="gaps-1x" />
                </div>
            }
        </div>

    );
};

const mapStateToProps = state => {
    return {
        transactions: state.remote.otctransactions,
        cashBalances: state.remote.cashBalances,
        bitcoinBalances: state.remote.userBtcBalances,
        users: state.remote.users,
        promoter: state.remote.promoter,
        commissionRates: state.remote.commissionRates,
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
    actionGetCashBalance,
    actionGetBitcoinBalance,
    actionGetCommissionRate
})(Bid);
