import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import translate from "../i18n/translate";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { actionGetCurrencyAll, actionGetAOSBalance, actionGetCashBalance, actionGetCommissionRate } from "../store/action/remoteAction";
import connect from "react-redux/es/components/connect";
import { client } from "../store/action/remoteAction";
import ConfirmDialog from "./ConfirmDialog";
import { getMultiLanguageMessage, getMultiLanguageLabel } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;
let BASE_URL = process.env.REACT_APP_SERVER_URL_API;

const TokenSell = (props) => {
    const [aosBalance, setAosBalance] = useState(0);
    const [btcWalletStatus, setBtcWalletStatus] = useState(0);
    const [isGift, setGift] = useState(false);
    const [isPublicPurchasePrice, setIsPublicPurchasePrice] = useState(false);
    const [isPartialOrderFlag, setIsPartialOrderFlag] = useState(false);
    const [address, setAddress] = useState('');
    const [aos, setAos] = useState(0);
    const [currency, setCurrency] = useState('');
    const [aosEuroRate, setAosEuroRate] = useState(0);
    const [aosBtcRate, setAosBtcRate] = useState(0);
    const [aosSellPrice, setAosSellPrice] = useState(0);
    const [aosSellAmount, setAosSellAmount] = useState(0);
    const [commissionID, setCommissionID] = useState(0);
    const [commissionAmount, setCommissionAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [paymentType, setPaymentType] = useState("");
    const [euroBalance, setEuroBalance] = useState(0);
    const [language, setLanguage] = useState("");
    const [pageId, setPageId] = useState(0);
    const [isValidatePrice, setIsValidatePrice] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const getPPPReplaceMessage = () => {
        let sConfirmMsg = '';
        if (currency === 'EURO') {
            sConfirmMsg = getMultiLanguageMessage('msg_Confirm_Replace_PPP', language).replace('xxx', aosEuroRate);
        } else if (currency === 'BTC') {
            sConfirmMsg = getMultiLanguageMessage('msg_Confirm_Replace_Bitcoin_PPP', language).replace('xxx', aosBtcRate);
        }
        return sConfirmMsg;
    };

    const [Dialog, showConfirmDialog] = ConfirmDialog(
        getMultiLanguageLabel('lbl_ConfirmDialog_Title', language),
        getPPPReplaceMessage(),
        getMultiLanguageLabel('lbl_Change', language),
        getMultiLanguageLabel('lbl_DoNotChange', language)
    );

    const goToConfirmationPage = async () => {
        resetTimout();

        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aosBalance) < aos) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (isGift && !address) {
            alert(getMultiLanguageMessage('msg_EmptyWalletAddress', language));
            return;
        }
        if (!isGift && parseFloat(aosSellPrice) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }
        if (!isGift && parseFloat(aosSellAmount) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }
        if (currency === 'BTC' && paymentType === 'Cash' && parseFloat(euroBalance) < parseFloat(commissionAmount)) {
            alert(getMultiLanguageMessage('msg_InsufficientBalance_Error', language));
            return;
        }

        let nPageId = 0;
        if (isValidatePrice) {
            const isOk = await showConfirmDialog();
            if (isOk) {
                onSellPriceChange();
            } else {
                nPageId = 1;
            }
            setIsValidatePrice(false);
        } else {
            nPageId = 1;
        }

        if (isGift && nPageId === 1) {  //Validate Gift Wallet Address
            let uid = localStorage.getItem("uid");
            const request = {
                "UserID": uid,
                "ToWalletID": address
            }
            await client(localStorage.getItem("@token")).post(`${BASE_URL}/wallets/gift`, request).then((response) => {
                if (response.data && response.data.length === 1) {
                    setPageId(1);
                } else {
                    alert(getMultiLanguageMessage('msg_WalletNotFound_Error', language));
                }
            }).catch(error => {
                alert("Error! Please contact customer support");
                console.log(error);
            });
        } else {
            setPageId(nPageId);
        }
    };

    const goToPrevPage = () => {
        resetTimout();
        setPageId(0);
    };

    const submitSellTransaction = async () => {
        resetTimout();
        let uid = localStorage.getItem("uid");
        let nPaymentType = 0;

        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aosBalance) < aos) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (isGift && !address) {
            alert(getMultiLanguageMessage('msg_EmptyWalletAddress', language));
            return;
        }
        if (!isGift && !currency) {
            alert(getMultiLanguageMessage('msg_EmptyCurrency_Error', language));
            return;
        }
        if (!isGift && parseFloat(aosSellPrice) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }
        if (!isGift && parseFloat(aosSellAmount) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }

        if (currency === 'BTC') {
            if (paymentType === 'Cash') {
                nPaymentType = 1;
            } else if (paymentType === 'CreditCard') {
                nPaymentType = 2;
            }
        }

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            const bodyJSON = {
                "UserId": uid,
                "CryptoAsset": "AOS",
                "BuySellFlag": isGift ? 3 : 2,
                "Currency": currency,
                "FromWalletId": uid,
                "ToWalletID": isGift ? address : "",
                "Quantity": "" + aos + "",
                "UnitPrice": isGift ? 0 : aosSellPrice,
                "Amount": isGift ? 0 : aosSellAmount,
                "CommissionID": isGift ? 0 : commissionID,
                "CommissionAmount": isGift ? 0 : commissionAmount,
                "PaymentType": nPaymentType,
                "OtcFlag": 1,
                "PartialOrderFlag": isPartialOrderFlag ? 1 : 0
            };

            setIsButtonClicked(true);
            if (currency === 'EURO' || (currency === 'BTC' && paymentType === 'Cash')) {
                await client(localStorage.getItem("@token")).post(`${BASE_URL}/transactions/`, bodyJSON).then((response) => {
                    setAos(0);
                    setAosSellAmount(0);
                    window.location.href = '/transactions';
                }).catch(error => {
                    if (error.message) {
                        alert(error.message);
                    } else {
                        alert("Error! Please contact customer support");
                    }
                    console.log(error);
                    setIsButtonClicked(false);
                });
            } else if (currency === 'BTC' && paymentType === 'CreditCard') {
                let serverUrl = SERVER_URL + `/btc-sellopen-otc-checkout`;
                fetch(serverUrl, {
                    method: 'POST',
                    redirect: 'follow',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(bodyJSON),
                })
                    .then(res => {
                        if (res.ok) return res.json()
                        return res.json().then(json => Promise.reject(json))
                    })
                    .then((body) => {
                        window.location.href = body.url;
                    })
                    .catch((error) => {
                        let errorMessage = "Error: ";
                        if (error && error.message) {
                            errorMessage += error.message;
                        } else {
                            errorMessage += JSON.stringify(error)
                        }
                        alert(errorMessage);
                        setIsButtonClicked(false);
                    });
            } else {
                alert('Invalid Transaction');
                setIsButtonClicked(false);
            }
        }
    };

    const giftChange = () => {
        resetTimout();
        setGift(!isGift);
        if (!isGift) {
            setCurrency('EURO');
        }
    };

    const onSellPriceChange = () => {
        resetTimout();
        let nAmount = 0;
        if (!isPublicPurchasePrice) {
            if (currency === 'EURO') {
                setAosSellPrice(aosEuroRate);
                if (parseFloat(aos) > 0 && parseFloat(aosEuroRate) > 0) {
                    nAmount = (parseFloat(aos) * parseFloat(aosEuroRate)).toFixed(2);
                }
            } else if (currency === 'BTC') {
                setAosSellPrice(aosBtcRate);
                if (parseFloat(aos) > 0 && parseFloat(aosBtcRate) > 0) {
                    nAmount = (parseFloat(aos) * parseFloat(aosBtcRate)).toFixed(8);
                }
            }
            setAosSellAmount(nAmount);
            calculateCommissionAmount(aos, nAmount);
            setIsValidatePrice(false);
        }
        setIsPublicPurchasePrice(!isPublicPurchasePrice);
    };

    const partialOrderOnChange = (e) => {
        resetTimout();
        setIsPartialOrderFlag(!isPartialOrderFlag);
    };

    const handleChange = (e) => {
        resetTimout();
        let nAmount = 0;
        switch (e.target.id) {
            case "outlined-start-adornment-aos":
                let requestedAos = e.target.value.replace(/[^0-9.]/g, "");
                setAos(requestedAos);
                if (parseFloat(requestedAos) > 0 && parseFloat(aosSellPrice) > 0) {
                    nAmount = (parseFloat(requestedAos) * parseFloat(aosSellPrice)).toFixed(2);
                }
                setAosSellAmount(nAmount);
                calculateCommissionAmount(requestedAos, nAmount);
                break;
            case "outlined-start-adornment-rate":
                let requestedPrice = e.target.value.replace(/[^0-9.]/g, "");
                setAosSellPrice(requestedPrice);
                if (parseFloat(aos) > 0 && parseFloat(requestedPrice) > 0) {
                    nAmount = (parseFloat(aos) * parseFloat(requestedPrice)).toFixed(8);
                }
                setAosSellAmount(nAmount);
                calculateCommissionAmount(aos, nAmount);
                if (currency === 'EURO') {
                    if (parseFloat(requestedPrice) > 0 && parseFloat(requestedPrice) < parseFloat(aosEuroRate)) {
                        setIsValidatePrice(true);
                    } else {
                        setIsValidatePrice(false);
                    }
                } else if (currency === 'BTC') {
                    if (parseFloat(requestedPrice) > 0 && parseFloat(requestedPrice) < parseFloat(aosBtcRate)) {
                        setIsValidatePrice(true);
                    } else {
                        setIsValidatePrice(false);
                    }
                }
                break;
            default: console.log('err');
        }
    };

    const onChangeAddress = (e) => {
        resetTimout();
        setAddress(e.target.value);
    };

    const onChangeCurrency = (e) => {
        resetTimout();
        setCurrency(e.target.value);
        setAosSellPrice(0);
        if (e.target.value === 'BTC') {
            // Cash balance required to pay Commission Fee
            props.actionGetCashBalance();
        }
    };

    const onChangePaymentType = (e) => {
        resetTimout();
        setPaymentType(e.target.value);
    };

    const calculateCommissionAmount = (sellQuantity, sellAmount) => {
        if (!props.commissionRates || props.commissionRates.length <= 0) {
            alert("Unexpected Error. Please contact customer support.");
            return;
        }
        let commissionRate = props.commissionRates[0];
        setCommissionID(commissionRate.CommissionID);
        let nCommissionAmount = 0;
        if (commissionRate.CommissionCalcType === 1) {
            nCommissionAmount = parseFloat((parseFloat(sellQuantity) * parseFloat(commissionRate.CommissionValue)).toFixed(2));
        } else if (commissionRate.CommissionCalcType === 2) {
            nCommissionAmount = parseFloat((parseFloat(sellAmount) * (parseFloat(commissionRate.CommissionValue) / 100)).toFixed(2));
        }
        if (!nCommissionAmount) {
            nCommissionAmount = 0;
        }
        setCommissionAmount(nCommissionAmount);
        let nTotalAmount = 0;
        if (parseFloat(sellAmount) > 0) {
            if (currency === 'EURO') {
                nTotalAmount = (parseFloat(sellAmount) - parseFloat(nCommissionAmount)).toFixed(2);
            } else if (currency === 'BTC') {
                nTotalAmount = parseFloat(sellAmount);
            }
        }
        setTotalAmount(nTotalAmount);
    };

    useEffect(() => {
        props.actionToggleNavBar(true);
        props.actionToggleSideBar(true);
        props.actionToggleFooter(true);
        props.actionGetCurrencyAll();
        props.actionGetAOSBalance();
        props.actionGetCommissionRate(2);
    }, []);

    useEffect(() => {
        if (props.currencyRates?.length >= 1) {
            let index = 0;
            for (index = 0; index < props.currencyRates.length; index++) {
                if (props.currencyRates[index].FromCurrency === "EURO") {
                    setAosEuroRate(parseFloat(props.currencyRates[index]?.Rate).toFixed(2));
                } else if (props.currencyRates[index].FromCurrency === "BTC") {
                    setAosBtcRate(parseFloat(props.currencyRates[index]?.Rate).toFixed(8));
                }
            }
        }
    }, [props.currencyRates]);

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        if (props?.userBalances?.length > 0) {
            props.userBalances[0]?.Balance ? setAosBalance((props.userBalances[0]?.Balance).toFixed(2)) : setAosBalance(0);
        }
    }, [props.userBalances]);

    useEffect(() => {
        if (props?.cashBalances?.length > 0) {
            props.cashBalances[0]?.Balance ? setEuroBalance((props.cashBalances[0]?.Balance).toFixed(2)) : setEuroBalance(0);
        }
    }, [props.cashBalances]);

    useEffect(() => {
        if (props?.users?.length > 0) {
            setBtcWalletStatus(props.users[0].BtcWalletStatus);
            props.users[0].BtcWalletStatus === 2 ? setCurrency('') : setCurrency('EURO');
        }
    }, [props.users]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{isGift ? translate('Gift-Tokens') : translate('Menu-OTCSell-Open')}</h2>
            <h2 className="input-item-label">{translate('AOS-Token-Balance')} = {aosBalance}</h2>

            {pageId === 0 &&
                <div>
                    <div className="gaps-1x" />
                    <div className="row">
                        <div className="col-md-8" style={{ padding: 0 }}>
                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <div>
                                    <TextField
                                        size="small"
                                        label={translate('Order-Quantity')}
                                        id="outlined-start-adornment-aos"
                                        sx={{ width: '24.5ch' }}
                                        value={aos}
                                        defaultValue={aos}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">AOS</InputAdornment>,
                                        }}
                                    />
                                </div>

                                {(!isGift && btcWalletStatus === 2) &&
                                    <>
                                        <div className="gaps-1x" />
                                        <div>
                                            <input className="payment-Type" type="radio" value="EURO" name="CurrencyType" checked={currency === 'EURO' ? true : false} onChange={onChangeCurrency} /> {translate('Currency-Euro')}
                                            <input className="payment-Type" type="radio" value="BTC" name="CurrencyType" checked={currency === 'BTC' ? true : false} onChange={onChangeCurrency} /> {translate('Currency-Bitcoin')}
                                        </div>
                                    </>
                                }

                                {!isGift &&
                                    <div>
                                        <div className="gaps-2x" />
                                        <TextField
                                            size="small"
                                            label={translate('Rate')}
                                            id="outlined-start-adornment-rate"
                                            sx={{ width: '24.5ch' }}
                                            value={aosSellPrice}
                                            defaultValue={aosSellPrice}
                                            onChange={handleChange}
                                            disabled={currency === '' ? true : isPublicPurchasePrice ? true : false}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">{currency === 'BTC' ? translate('Bitcoin') : currency === 'EURO' ? translate('Euro') : ''}</InputAdornment>,
                                            }}
                                        />
                                    </div>
                                }

                                {!isGift &&
                                    <div>
                                        <FormControlLabel control={<Checkbox
                                            onChange={onSellPriceChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            checked={isPublicPurchasePrice ? true : false}
                                        />} label={translate('public-purchase-price')} />
                                        &nbsp;
                                        <FormControlLabel control={<Checkbox
                                            onChange={giftChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />} label={translate('Gift')} />
                                    </div>
                                }

                                {isGift &&
                                    <div>
                                        <FormControlLabel control={<Checkbox
                                            onChange={giftChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            checked={true}
                                        />} label={translate('Gift')} />
                                    </div>
                                }

                                {!isGift &&
                                    <div>
                                        <FormControlLabel control={<Checkbox
                                            onChange={partialOrderOnChange}
                                            checked={isPartialOrderFlag}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />} label={translate('Partial-Order')} />
                                    </div>
                                }

                                {isGift &&
                                    <div>
                                        <div class="input-item input-with-label">
                                            <label for="swalllet" class="input-item-label">{translate('Select-Wallet')}</label>
                                            <select class="input-bordered" name="swalllet" id="swalllet">
                                                <option value="eth">AOS</option>
                                            </select>
                                        </div>

                                        <div class="input-item input-with-label">
                                            <label for="token-address" class="input-item-label">{translate('Enter-Address')}:</label>
                                            <input class="input-bordered" type="text" id="token-address" name="token-address" onChange={onChangeAddress} value={address} defaultValue={address} />
                                        </div>
                                    </div>
                                }

                                {!isGift &&
                                    <>
                                        <div>
                                            <div className="gaps-1x" />
                                            <TextField
                                                size="small"
                                                label={translate('Commission-Amount')}
                                                id="commission-amount"
                                                sx={{ width: '24.5ch' }}
                                                value={commissionAmount}
                                                defaultValue={commissionAmount}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">{translate('Euro')}</InputAdornment>,
                                                }}
                                                disabled={true}
                                            />
                                        </div>

                                        {currency === 'BTC' &&
                                            <>
                                                <div className="gaps-1x" />
                                                <label className="input-item-label">{translate('Commission-Payment-Type')}</label>
                                                <div onChange={onChangePaymentType}>
                                                    <input className="payment-Type" type="radio" value="Cash" name="paymentType" checked={paymentType === 'Cash' ? true : false} /> {translate('Payment-By-Cash')}
                                                    <input className="payment-Type" type="radio" value="CreditCard" name="paymentType" checked={paymentType === 'CreditCard' ? true : false} /> {translate('Payment-By-CreditCard')}
                                                </div>
                                                <div className="gaps-1x" />
                                                {paymentType === 'Cash' &&
                                                    <div>
                                                        <div className="col-md-8">
                                                            <label className="input-item-label">{translate('Cash-Balance')}&nbsp;:&nbsp;{euroBalance} {translate('Euro')}</label>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </>
                                }

                                {!isGift &&
                                    <div>
                                        <div className="gaps-1x" />
                                        <TextField
                                            size="small"
                                            label={translate('Payment-Amount')}
                                            id="payment-amount"
                                            sx={{ width: '24.5ch' }}
                                            value={totalAmount}
                                            defaultValue={totalAmount}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">{currency === 'BTC' ? translate('Bitcoin') : currency === 'EURO' ? translate('Euro') : ''}</InputAdornment>,
                                            }}
                                            disabled={true}
                                        />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                    <div className="gaps-1x" />
                    <button className="btn btn-primary" onClick={goToConfirmationPage}>{translate('To-Confirm-Page')}</button>
                    <div className="gaps-1x" />
                    <Dialog />
                </div>
            }

            {pageId === 1 &&
                <div>
                    <table width='70%'>
                        <tr>
                            <td align='left' >{translate('Order-Quantity')} :</td>
                            <td align='right' >{aos} AOS</td>
                        </tr>
                        {!isGift &&
                            <tr>
                                <td align='left' >{translate('Currency')} :</td>
                                <td align='right'>{currency === 'BTC' ? translate('Currency-Bitcoin') : translate('Currency-Euro')} </td>
                            </tr>
                        }
                        {!isGift &&
                            <tr>
                                <td align='left' >{translate('Rate')} :</td>
                                <td align='right'>{aosSellPrice} {currency === 'BTC' ? translate('Bitcoin-Aos') : translate('Euro-Aos')} </td>
                            </tr>
                        }
                        {!isGift &&
                            <tr>
                                <td align='left'>{translate('Commission-Amount')} :</td>
                                <td align='right'>{commissionAmount} {translate('Euro')}</td>
                            </tr>
                        }
                        {(!isGift && currency === 'BTC') &&
                            <tr>
                                <td align='left'>{translate('Commission-Payment-Type')} :</td>
                                <td align='right'>{paymentType === 'CreditCard' ? translate('Payment-By-CreditCard') : translate('Payment-By-WalletBalance')} </td>
                            </tr>
                        }
                        {!isGift &&
                            <tr>
                                <td align='left' >{translate('Payment-Amount')} :</td>
                                <td align='right'>{totalAmount} {currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</td>
                            </tr>
                        }
                        {/*!isGift &&
                            <tr>
                                <td align='left'>{translate('Payment-Type')} :</td>
                                <td align='right'>{translate('Payment-By-WalletBalance')} </td>
                            </tr>
                        */}
                        {isGift &&
                            <tr>
                                <td align='left' colspan={2}>{translate('WalletAddress')} :</td>
                            </tr>
                        }
                        {isGift &&
                            <tr>
                                <td align='left' colspan={2} className='td-wrap-text' >{address} </td>
                            </tr>
                        }
                    </table>

                    {isButtonClicked &&
                        <>
                            <div className="gaps-1x" />
                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                        </>
                    }
                    <div className="gaps-1x" />
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={goToPrevPage}>{translate('Back-Btn')}</button>&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={submitSellTransaction}>{translate('Submit')}</button>
                    <div className="gaps-1x" />
                </div>
            }
            {/* form */}
        </div >

    );
};

const mapStateToProps = state => {
    return {
        users: state.remote.users,
        navBar: state.siteLayout.navBar,
        currencyRates: state.remote.currencyRates,
        userBalances: state.remote.userBalances,
        cashBalances: state.remote.cashBalances,
        commissionRates: state.remote.commissionRates
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
    actionGetCurrencyAll,
    actionGetAOSBalance,
    actionGetCashBalance,
    actionGetCommissionRate
})(TokenSell);
