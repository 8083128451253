import * as actionTypes from '../action';
import { LOCALES } from '../../i18n';

const initialState = {
    navBar: true,
    sideBar: true,
    sideBarMobile: false,
    footer: true,
    locale: LOCALES.ENGLISH
};

const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_NAVBAR:
            return { ...state, navBar: action.payload };

        case actionTypes.TOGGLE_SIDEBAR:
            return { ...state, sideBar: action.payload };

        case actionTypes.TOGGLE_SIDEBAR_MOBILE:
            return { ...state, sideBarMobile: action.payload };

        case actionTypes.TOGGLE_FOOTER:
            return { ...state, footer: action.payload };

        case actionTypes.CHANGE_LANGUAGE:
            return { ...state, locale: action.payload };

        default:
            return state;
    }

};
export default layoutReducer;
