import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { client } from "../store/action/remoteAction";
import { actionGetPromoterApplication } from "../store/action/remoteAction";
import { resetTimout } from "./common/Firebase";

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;

const PromoterNotification = (props) => {
    const [data, setData] = useState({
        'UserNameFirst': '',
        'UserNameLast': '',
        'UserNameLast_DByte': '',
        'UserNameFirst_DByte': '',
        'MailID': ''
    });
    const [isPromoterApplicationExist, setIsPromoterApplicationExist] = useState(false);
    const [promoterProcessStatus, setPromoterProcessStatus] = useState('');
    const [isDontShowAgain, setIsDontShowAgain] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [language, setLanguage] = useState("");

    const updatePromoterNotificationStatus = async () => {
        resetTimout();
        let uid = localStorage.getItem("uid");

        setIsButtonClicked(true);
        const request = {
            "UserID": uid
        };
        await client(localStorage.getItem("@token")).post(`${BASE_URL}/promoterrequest/notificationstatus`, request).then((response) => {
            window.location.href = '/';
        }).catch(error => {
            let errorMessage = "Error: ";
            if (error && error.message) {
                errorMessage += error.message;
            } else {
                errorMessage += JSON.stringify(error)
            }
            alert(errorMessage);
            setIsButtonClicked(false);
        });
    };

    const goToPromoterCouponPage = async () => {
        resetTimout();        
        if (isDontShowAgain) {
            let uid = localStorage.getItem("uid");
    
            setIsButtonClicked(true);
            const request = {
                "UserID": uid
            };
            await client(localStorage.getItem("@token")).post(`${BASE_URL}/promoterrequest/notificationstatus`, request).then((response) => {
                window.location.href = '/promotercoupon';        
            }).catch(error => {
                let errorMessage = "Error: ";
                if (error && error.message) {
                    errorMessage += error.message;
                } else {
                    errorMessage += JSON.stringify(error)
                }
                alert(errorMessage);
                setIsButtonClicked(false);
            });            
        } else {
            window.location.href = '/promotercoupon';
        }        
    };

    const handleIsDontShowAgain = () => {
        resetTimout();
        setIsDontShowAgain(!isDontShowAgain);
    };

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        localStorage.setItem("promoterNotification", "1");
        //actionGetUserProfile request send from Navbar
        props.actionGetPromoterApplication();
    }, []);

    useEffect(() => {
        if (props?.promoter && props?.promoter?.length > 0) {
            setIsPromoterApplicationExist(true);
            setPromoterProcessStatus(props.promoter[0].ProcessStatus);
        }
    }, [props.promoter]);

    useEffect(() => {
        if (props?.users?.length > 0) {
            setData(props?.users[0]);
        }
    }, [props.users]);

    return (
        <div className="user-panel">
            <div className="gaps-1x" />
            {isPromoterApplicationExist &&
                <div id="promoter-application">
                    <div>
                        {promoterProcessStatus === 'Approved' &&
                            <div>
                                <p>
                                    {translate('Promoter-Application-Approved-Message')}
                                </p>
                                <p>
                                    {translate('Promoter-Coupon-Setup-Message')}
                                </p>

                                <span>
                                    <FormControlLabel control={<Checkbox
                                        onChange={handleIsDontShowAgain}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        checked={isDontShowAgain ? true : false}
                                    />} label={translate('DonotShowAgain-Message')} />                                    
                                </span>
                                <br />
                                {isDontShowAgain &&
                                    <div className='Message-Red'>
                                        {translate('Promoter-DontShowAgain-Message')}
                                    </div>
                                }

                                <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => goToPromoterCouponPage(event)}>{translate('button-Ok')}</button>
                            </div>
                        }

                        {promoterProcessStatus === 'Rejected' &&
                            <div>
                                <p>
                                    {translate('Promoter-Application-Rejected-Message')}
                                </p>
                                <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => updatePromoterNotificationStatus(event)}>{translate('button-Ok')}</button>
                            </div>
                        }

                        <div className="gaps-1x" />

                    </div>

                </div>
            }

        </div >
    );
};

const mapStateToProps = state => {
    return {
        users: state.remote.users,
        promoter: state.remote.promoter
    };
};

export default connect(mapStateToProps, {
    actionGetPromoterApplication
})(PromoterNotification);
