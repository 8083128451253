import React, {useEffect} from 'react';
import connect from "react-redux/es/components/connect";
import {actionToggleFooter, actionToggleNavBar, actionToggleSideBar} from "../store/action/layoutAction";

const SignupSuccess = (props) => {
    useEffect(() => {
        props.actionToggleNavBar(false);
        props.actionToggleSideBar(false);
        props.actionToggleFooter(false);
    }, [props]);
    return (
        <div className="user-ath-page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8  text-center">
                        <div className="user-ath-logo">
                            <a href="/">
                                <img
                                    src="../assets/images/logo-sm.png"
                                    srcSet={require('../assets/images/logo-sm2x.png')}
                                    alt="icon"
                                />
                            </a>
                        </div>
                        <div className="user-ath-box">
                            <div className="status status-thank">
                                <div className="status-icon">
                                    <em className="ti ti-check"/>
                                </div>
                                <span className="status-text">
              Thank you! Your singup process is alomost done.
            </span>
                                <p>Please check your mail and verify.</p>
                            </div>
                            {/* .status */}
                        </div>
                        <div className="gaps-2x"/>
                        <div className="form-note">
                            {" "}
                            Already a member? <a href="/login">Login</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
    };
};
export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter
})(SignupSuccess);
