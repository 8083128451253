import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import translate from "../i18n/translate";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { actionGetCurrencyAos } from "../store/action/remoteAction";
import connect from "react-redux/es/components/connect";
import { getMultiLanguageMessage } from '../i18n/multiLanguage';
import { Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import { LOCALES } from '../i18n/constants';
import { resetTimout } from "./common/Firebase";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;
//const App_Environment = process.env.REACT_APP_ENVIRONMENT;
const App_Environment = "0";

const Token = (props) => {
    const [trancheList, setTrancheList] = useState([]);
    const [isActiveTrancheExist, setIsActiveTrancheExist] = useState(false);
    const [availableReserveAos, setAvailableReserveAos] = useState(0);
    const [userInputAos, setUserInputAos] = useState(0);
    const [buyQuantity, setBuyQuantity] = useState(0);
    const [buyAmount, setBuyAmount] = useState(0);
    const [tempBuyAmount, setTempBuyAmount] = useState(0);
    const [aosRate, setAosRate] = useState(0);
    const [currentTrancheAos, setCurrentTrancheAos] = useState(0);
    const [reserveTrancheAos, setReserveTrancheAos] = useState(0);
    const [paymentType, setPaymentType] = useState("");
    const [euroBalance, setEuroBalance] = useState(0);
    const [bonusFlag, setBonusFlag] = useState(false);
    const [language, setLanguage] = useState("");
    const [showPaymentTypePage, setShowPaymentTypePage] = useState(false);
    const [showPartialExecutionPage, setShowPartialExecutionPage] = useState(false);
    const [showOptionsPage, setShowOptionsPage] = useState(false);
    const [showTrancheRatePage, setShowTrancheRatePage] = useState(false);
    const [orderWithoutBonusAos, setOrderWithoutBonusAos] = useState(0);
    const [orderWithBonusAos, setOrderWithBonusAos] = useState(0);
    const [orderWithBonusApplicableAos, setOrderWithBonusApplicableAos] = useState(0);
    const [selectedOrderOption, setSelectedOrderOption] = useState(0);
    const [optionType, setOptionType] = useState(0);
    const [pageId, setPageId] = useState(0);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const ORDER_PERCENT = 0.8698;
    const BONUS_PERCENT = 0.15;

    const handleChange = (e) => {
        resetTimout();
        switch (e.target.id) {
            case "outlined-start-adornment-aos":
                let nRequestedAos = e.target.value.replace(/[^0-9.]/g, "");
                let nRemainingAos = 0;
                let nOrderVal = 0;
                let nBonusVal = 0;
                let isTokenAvailableInTranche = false;
                let nCurrentTrancheAos = 0;

                setBuyQuantity(nRequestedAos);
                setUserInputAos(nRequestedAos);
                if (!nRequestedAos) {
                    nRequestedAos = 0;
                } else {
                    nRequestedAos = parseFloat(nRequestedAos);
                }
                setShowPaymentTypePage(false);
                setShowTrancheRatePage(false);
                resetTrancheBuyQuantity();

                if (props.trancheRates?.length >= 1) {
                    if (isActiveTrancheExist) {
                        nRemainingAos = parseFloat(props.trancheRates[0].AOSRemaining);
                    }
                    //Check Aos available in current/next tranches
                    if ((nRemainingAos + availableReserveAos) <= 0) {
                        return;
                    }

                    if (bonusFlag) {
                        let nBonusAos = parseFloat((nRequestedAos * BONUS_PERCENT).toFixed(2));
                        let nTotalAosRequired = parseFloat((nRequestedAos + nBonusAos).toFixed(2));
                        if (nTotalAosRequired > nRemainingAos) { //Requested token + bonus is more than avialable in current tranche
                            if (availableReserveAos > 0) {
                                setShowPartialExecutionPage(true);
                                setShowOptionsPage(false);
                            } else {
                                setShowPartialExecutionPage(false);
                                setShowOptionsPage(true);
                                setOptionType(2); //Reserve Tranche not exist. User Buy available (quantity + bonus) in the Active tranche                                
                            }
                            nOrderVal = parseFloat(Math.trunc(nRemainingAos * ORDER_PERCENT));
                            setOrderWithBonusApplicableAos(nOrderVal);
                            nBonusVal = parseFloat((nOrderVal * BONUS_PERCENT).toFixed(2));
                            if ((nOrderVal + nBonusVal) < nRemainingAos) {
                                //Add remaining quanity to Order, we do not want to take quantity from next tranche before completing current tranche.
                                nOrderVal = nOrderVal + (nRemainingAos - (nOrderVal + nBonusVal));
                            }
                            nOrderVal = parseFloat(nOrderVal.toFixed(2));
                            setOrderWithBonusAos(nOrderVal);
                        } else {
                            setOrderWithBonusApplicableAos(nRequestedAos);
                            isTokenAvailableInTranche = true;
                        }
                    } else {
                        if (nRequestedAos > nRemainingAos) { //Requested token is more than avialable in current tranche
                            if (availableReserveAos > 0) {
                                setShowPartialExecutionPage(true);
                                setShowOptionsPage(false);
                            } else {
                                setOrderWithoutBonusAos(nRemainingAos);
                                setShowPartialExecutionPage(false);
                                setShowOptionsPage(true);
                                setOptionType(1); //Reserve Tranche not exist. User Buy available quantity in the Active tranche
                            }
                        } else {
                            isTokenAvailableInTranche = true;
                        }
                    }

                    if (isTokenAvailableInTranche) {    //Requested Aos avilable in current tranche                        
                        nCurrentTrancheAos = nRequestedAos;
                        setOptionType(0);
                        setShowOptionsPage(false);
                        setShowPartialExecutionPage(false);
                        if (nRequestedAos > 0) {
                            setShowPaymentTypePage(true);
                        }
                        setBuyAmount((nRequestedAos * aosRate).toFixed(2));
                        //Update Tranche Quantity/Amount
                        trancheList[0].BuyQuantity = nRequestedAos;
                        trancheList[0].BuyAmount = (nRequestedAos * aosRate).toFixed(2);
                    } else {
                        setBuyAmount(0);
                        setPaymentType("");
                    }
                    //Set Current Tranche AOS
                    setCurrentTrancheAos(nCurrentTrancheAos);
                }
                break;
            default: console.log('err');
        }
    }

    const onChangePaymentType = (e) => {
        resetTimout();
        setPaymentType(e.target.value);
    }

    const onChangePartialExecutionFlag = (e) => {
        resetTimout();
        let nActiveTrancheAos = isActiveTrancheExist ? parseFloat(trancheList[0].AOSRemaining) : 0;
        let nUserInputAos = parseFloat(userInputAos);
        let nReserveTrancheAos = 0;
        let nReserveAosAvailable = 0;
        let nReserveAosAllocated = 0;
        let nTrancheCount = trancheList ? trancheList.length : 0;
        let nIndex = 0;
        let nReserveIndex = isActiveTrancheExist ? 1 : 0;
        let nTotalBuyAmount = 0;

        setShowTrancheRatePage(false);
        setOptionType(0);
        setShowOptionsPage(false);
        setShowPaymentTypePage(false);
        setPaymentType("");
        setBuyAmount(0);

        if (e.target.value === "Yes") {
            let nReserveAosNeeded = 0;
            if (bonusFlag) {
                nReserveAosNeeded = nUserInputAos - orderWithBonusAos;
                nTotalBuyAmount = parseFloat((parseFloat(orderWithBonusAos) * parseFloat(aosRate)).toFixed(2));
                if (isActiveTrancheExist) {
                    trancheList[0].BuyQuantity = orderWithBonusAos;
                    trancheList[0].BuyAmount = nTotalBuyAmount;
                }
            } else {
                nReserveAosNeeded = nUserInputAos - nActiveTrancheAos;
                nTotalBuyAmount = parseFloat((parseFloat(nActiveTrancheAos) * parseFloat(aosRate)).toFixed(2));
                if (isActiveTrancheExist) {
                    trancheList[0].BuyQuantity = nActiveTrancheAos;
                    trancheList[0].BuyAmount = nTotalBuyAmount;
                }
            }

            for (nIndex = nReserveIndex; nIndex < nTrancheCount; nIndex++) {
                nReserveAosAvailable = parseFloat(trancheList[nIndex].AOSRemaining);
                nReserveAosAllocated = 0;
                if (nReserveAosNeeded > nReserveAosAvailable) {
                    nReserveAosAllocated = nReserveAosAvailable;
                } else {
                    nReserveAosAllocated = nReserveAosNeeded;
                }
                trancheList[nIndex].BuyQuantity = nReserveAosAllocated;
                trancheList[nIndex].BuyAmount = parseFloat((parseFloat(nReserveAosAllocated) * parseFloat(trancheList[nIndex].Rate)).toFixed(2));
                nTotalBuyAmount = nTotalBuyAmount + trancheList[nIndex].BuyAmount;
                nReserveAosNeeded = parseFloat((nReserveAosNeeded - nReserveAosAllocated).toFixed(2));
                nReserveTrancheAos = parseFloat((nReserveTrancheAos + nReserveAosAllocated).toFixed(2));
                if (nReserveAosNeeded === 0) {
                    break;
                }
            }
            setTempBuyAmount(nTotalBuyAmount);
            setReserveTrancheAos(parseFloat(nReserveTrancheAos.toFixed(2)));    //Reserve quantity alloted for this order.

            if (bonusFlag) {
                setCurrentTrancheAos(orderWithBonusAos);
                setOptionType(4);
            } else {
                setOrderWithoutBonusAos(parseFloat((nActiveTrancheAos + nReserveTrancheAos).toFixed(2)));
                setCurrentTrancheAos(nActiveTrancheAos);
                setOptionType(3);
            }
            setShowOptionsPage(true);
        } else if (e.target.value === "No") {
            if (bonusFlag) {
                setOrderWithoutBonusAos(0);
                setOptionType(2);
            } else {
                setOrderWithoutBonusAos(nActiveTrancheAos);
                setOptionType(1);
            }
            setReserveTrancheAos(0);
            setShowOptionsPage(true);
        }
    }

    const onChangeOrderOption = (e) => {
        resetTimout();
        let selectedOption = e.target.value;
        let nBuyAmount = 0;
        let isShowTrancheRate = false;

        if (selectedOption === "1") {   //Order without Bonus (Current Tranche)
            setBuyQuantity(orderWithoutBonusAos.toFixed(2));
            nBuyAmount = parseFloat((orderWithoutBonusAos * aosRate).toFixed(2));
            setCurrentTrancheAos(orderWithoutBonusAos);
        } else if (selectedOption === "2") { //Order with Bonus (Current Tranche)
            setBuyQuantity(orderWithBonusAos.toFixed(2));
            nBuyAmount = parseFloat((orderWithBonusAos * aosRate).toFixed(2));
            setCurrentTrancheAos(orderWithBonusAos);
        } else if (selectedOption === "3") { //Multiple Tranche Without Bonus Option
            setBuyQuantity(orderWithoutBonusAos.toFixed(2));
            nBuyAmount = tempBuyAmount; //Amount calculated on selecting allow partial order option
            isShowTrancheRate = true;
        } else if (selectedOption === "4") { //Multiple Tranche With Bonus Option
            setBuyQuantity((orderWithBonusAos + reserveTrancheAos).toFixed(2));
            nBuyAmount = tempBuyAmount; //Amount calculated on selecting allow partial order option
            isShowTrancheRate = true;
        } else if (selectedOption === "10") { //Cancel
            //Reload page
            window.location.href = "tokens";
        }

        setBuyAmount(nBuyAmount);
        setSelectedOrderOption(parseInt(selectedOption));
        setShowPaymentTypePage(true);
        setShowTrancheRatePage(isShowTrancheRate);
    };

    const goToConfirmationPage = (e) => {
        resetTimout();
        if (!buyQuantity || !buyQuantity.match(/^\d{1,}(\.\d{0,2})?$/) || buyQuantity <= 0 || buyAmount <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (paymentType === 'Cash' && parseFloat(euroBalance) < parseFloat(buyAmount)) {
            alert(getMultiLanguageMessage('msg_InsufficientBalance_Error', language));
            return;
        }
        setPageId(1);
    };

    const goToPrevPage = (e) => {
        resetTimout();
        setPageId(0);
    };

    const goToCheckout = (e) => {
        resetTimout();
        e.preventDefault();
        let orderDetail = [];
        let index = 0;

        if (!buyQuantity || !buyQuantity.match(/^\d{1,}(\.\d{0,2})?$/) || buyQuantity <= 0 || buyAmount <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (paymentType == 'Cash' && parseFloat(euroBalance) < parseFloat(buyAmount)) {
            alert(getMultiLanguageMessage('msg_InsufficientBalance_Error', language));
            return;
        }

        for (index = 0; index < trancheList.length; index++) {
            if (trancheList[index].BuyQuantity === 0) {
                break;
            }
            orderDetail.push(trancheList[index]);
        }

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            let serverUrl = '';
            let bodyJSON = JSON.stringify({
                items: {
                    Quantity: buyQuantity,
                    Amount: buyAmount,
                    BonusApplicableQuantity: orderWithBonusApplicableAos,
                    UserId: localStorage.getItem("uid"),
                    OrderOption: selectedOrderOption,
                    OtcFlag: 0,
                    OrderDetail: JSON.stringify(orderDetail)
                }
            });

            if (paymentType === 'CreditCard') {
                if (App_Environment === "2") {
                    localStorage.setItem("aos-creditdemo", bodyJSON);
                    let cardHolderName = props?.users[0]?.UserNameFirst + " " + props?.users[0]?.UserNameLast;
                    localStorage.setItem("aos-cardholdername", cardHolderName);
                    window.location.href = '/credit-card-demo';
                } else {
                    serverUrl = SERVER_URL + `/buytoken-checkout`;
                }
            } else if (paymentType === 'Cash') {
                serverUrl = SERVER_URL + `/buytoken-cashbalance`;
            } else {
                return;
            }

            if (serverUrl) {
                setIsButtonClicked(true);
                fetch(serverUrl, {
                    method: 'POST',
                    redirect: 'follow',
                    headers: { 'Content-Type': 'application/json' },
                    body: bodyJSON,
                })
                    .then(res => {
                        if (res.ok) return res.json()
                        return res.json().then(json => Promise.reject(json))
                    })
                    .then((body) => {
                        window.location.href = body.url;
                    })
                    .catch((error) => {
                        let errorMessage = "Error: ";
                        if (error && error.message) {
                            errorMessage += error.message;
                        } else {
                            errorMessage += JSON.stringify(error)
                        }
                        alert(errorMessage);
                        setIsButtonClicked(false);
                    });
            }
        }
    };

    const resetTrancheBuyQuantity = () => {
        let nTrancheCount = trancheList ? trancheList.length : 0;
        let nIndex = 0;
        for (nIndex = 0; nIndex < nTrancheCount; nIndex++) {
            trancheList[nIndex].BuyQuantity = 0;
            trancheList[nIndex].BuyAmount = 0;
        }
        setTrancheList(trancheList);
    };

    useEffect(() => {
        props.actionToggleNavBar(true);
        props.actionToggleSideBar(true);
        props.actionToggleFooter(true);
        props.actionGetCurrencyAos();
        //actionGetUserProfile request send from Navbar
    }, []);

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        if (props?.users?.length > 0) {
            props.users[0]?.EuroBalance ? setEuroBalance((props.users[0]?.EuroBalance).toFixed(2)) : setEuroBalance(0);
            if (props.users[0]?.IVAFOBuyFlag === 0 && props.users[0]?.IVAFOBuyOrderFlag === 0 && props.users[0]?.UserType === 3) {
                setBonusFlag(true);
            }
        }
    }, [props.users]);

    useEffect(() => {
        let tranches = props.trancheRates;
        let nTotalReserveQuantity = 0;
        let nAosRate = 0;
        let isActiveTranche = false;
        if (tranches) {
            let nTrancheCount = tranches.length;
            let nIndex = 0;
            for (nIndex = 0; nIndex < nTrancheCount; nIndex++) {
                if (nIndex === 0 && tranches[0].IsActiveTranche === 1 && parseFloat(tranches[0].AOSRemaining) > 0) {
                    isActiveTranche = true;
                    nAosRate = tranches[0].Rate;
                } else {
                    nTotalReserveQuantity = nTotalReserveQuantity + parseFloat(tranches[nIndex].AOSRemaining);
                    if (nAosRate === 0) {
                        if (parseFloat(tranches[nIndex].AOSRemaining) > 0) {
                            nAosRate = tranches[nIndex].Rate;
                        }
                    }
                }
                tranches[nIndex].BuyQuantity = 0;
                tranches[nIndex].BuyAmount = 0;
            }
            setTrancheList(tranches);
        } else {
            setTrancheList([]);
        }
        setIsActiveTrancheExist(isActiveTranche);
        setAosRate(nAosRate);
        setAvailableReserveAos(nTotalReserveQuantity);
    }, [props.trancheRates]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-IVAFO')}</h2>

            {pageId === 0 &&
                <div>
                    <div className="gaps-1x" />
                    <div className="row">
                        <div className="col-md-8" style={{ padding: 0 }}>
                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <div>
                                    <TextField
                                        size="small"
                                        label={translate('Rate')}
                                        id="outlined-start-adornment-rate"
                                        sx={{ width: '24.5ch' }}
                                        value={parseFloat(aosRate).toFixed(2)}
                                        defaultValue={parseFloat(aosRate).toFixed(2)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{translate('Euro')} / 1 AOS</InputAdornment>,
                                        }}
                                    />
                                </div>

                                <div className="gaps-2x" />
                                <div>
                                    <TextField
                                        size="small"
                                        label={translate('Order-Quantity')}
                                        id="outlined-start-adornment-aos"
                                        sx={{ width: '24.5ch' }}
                                        value={buyQuantity}
                                        defaultValue={buyQuantity}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">AOS</InputAdornment>,
                                        }}
                                    />
                                </div>

                                <div className="gaps-2x" />
                                <div>
                                    <TextField
                                        size="small"
                                        label={translate('Payment-Amount')}
                                        id="outlined-start-adornment-euro"
                                        sx={{ width: '24.5ch' }}
                                        value={buyAmount}
                                        defaultValue={buyAmount}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{translate('Euro')}</InputAdornment>,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* .row */}
                    <div className="gaps-2x" />

                    {showPartialExecutionPage &&
                        <div>
                            <h5 className="user-panel-subtitle">{translate('insufficient-aos-balance')}</h5>
                            <div className="gaps-1x" />
                            <h5 className="user-panel-subtitle">{translate('PartialOrder-Question')}</h5>
                            <div onChange={onChangePartialExecutionFlag}>
                                <input className="partialexecution-Flag" type="radio" value="Yes" name="partialexecutionFlag" /> {translate('Radio-Option-Yes')}
                                <input className="partialexecution-Flag" type="radio" value="No" name="partialexecutionFlag" /> {translate('Radio-Option-No')}
                            </div>
                            <div className="gaps-1x" />
                        </div>
                    }

                    {/*Order Without Bonus (No Partial Order)*/}
                    {(showOptionsPage && optionType === 1) &&
                        <div>
                            <div onChange={onChangeOrderOption}>
                                <h5 className="user-panel-subtitle">{translate('order-options-note')}</h5>
                                <ol>
                                    <li><input className="orderOption-Type" type="radio" value="1" name="orderOption" /> {language === LOCALES.JAPANESE ? orderWithoutBonusAos.toString() + ' AOS注文' : 'Order ' + orderWithoutBonusAos.toString() + ' AOS'}</li>
                                    <li><input className="orderOption-Type" type="radio" value="10" name="orderOption" /> {translate('cancel-order')}</li>
                                </ol>
                            </div>
                            <div className="gaps-1x" />
                        </div>
                    }

                    {/*Order With Bonus (No Partial Order)*/}
                    {(showOptionsPage && optionType === 2) &&
                        <div>
                            <div onChange={onChangeOrderOption}>
                                <h5 className="user-panel-subtitle">{translate('order-options-note')}</h5>
                                <ol>
                                    <li><input className="orderOption-Type" type="radio" value="2" name="orderOption" /> {language === LOCALES.JAPANESE ? orderWithBonusAos.toString() + ' AOS注文' : 'Order ' + orderWithBonusAos.toString() + ' AOS'}</li>
                                    <li><input className="orderOption-Type" type="radio" value="10" name="orderOption" /> {translate('cancel-order')}</li>
                                </ol>
                            </div>
                            <div className="gaps-1x" />
                        </div>
                    }

                    {/*Order Without Bonus Multiple Tranche*/}
                    {(showOptionsPage && optionType === 3) &&
                        <div>
                            <div onChange={onChangeOrderOption}>
                                <h5 className="user-panel-subtitle">{translate('order-options-note')}</h5>
                                <ol>
                                    <li><input className="orderOption-Type" type="radio" value="3" name="orderOption" /> {language === LOCALES.JAPANESE ? orderWithoutBonusAos.toString() + ' AOS注文 (現IVFAO: ' + currentTrancheAos.toString() + ', 予約: ' + reserveTrancheAos.toString() + ')' : 'Order ' + orderWithoutBonusAos.toString() + ' AOS (Current: ' + currentTrancheAos.toString() + ', Reserved: ' + reserveTrancheAos.toString() + ')'}</li>
                                    <li><input className="orderOption-Type" type="radio" value="10" name="orderOption" /> {translate('cancel-order')}</li>
                                </ol>
                            </div>
                            <div className="gaps-1x" />
                        </div>
                    }

                    {/*Order With Bonus Multiple Tranche */}
                    {(showOptionsPage && optionType === 4) &&
                        <div>
                            <div onChange={onChangeOrderOption}>
                                <h5 className="user-panel-subtitle">{translate('order-options-note')}</h5>
                                <ol>
                                    <li><input className="orderOption-Type" type="radio" value="4" name="orderOption" /> {language === LOCALES.JAPANESE ? (orderWithBonusAos + reserveTrancheAos).toFixed(2) + ' AOS注文 (現IVFAO: ' + orderWithBonusAos.toFixed(2) + ', 予約: ' + reserveTrancheAos.toFixed(2) + ')' : 'Order ' + (orderWithBonusAos + reserveTrancheAos).toFixed(2) + ' AOS (Current: ' + orderWithBonusAos.toFixed(2) + ', Reserved: ' + reserveTrancheAos.toFixed(2) + ')'}</li>
                                    <li><input className="orderOption-Type" type="radio" value="10" name="orderOption" /> {translate('cancel-order')}</li>
                                </ol>
                            </div>
                            <div className="gaps-1x" />
                        </div>
                    }

                    {showTrancheRatePage &&
                        <div>
                            <div className="gaps-1x" />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 450 }} aria-label="" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" component="th">{translate('Tokens')}</TableCell>
                                            <TableCell align="center" component="th">{translate('UnitPrice')}</TableCell>
                                            <TableCell align="center" component="th">{translate('Amount')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {trancheList.map((tranche, index) => {
                                        return tranche.BuyQuantity > 0 ? <TableBody>
                                            <TableRow>
                                                <TableCell align='center'>{tranche.BuyQuantity}</TableCell>
                                                <TableCell align='center'>{tranche.Rate} {translate('Euro')}</TableCell>
                                                <TableCell align='center'>{tranche.BuyAmount} {translate('Euro')}</TableCell>
                                            </TableRow>
                                        </TableBody> : ''
                                    })
                                    }
                                </Table>
                            </TableContainer>
                            <div className="gaps-2x" />
                        </div>
                    }

                    {showPaymentTypePage &&
                        <div onChange={onChangePaymentType}>
                            <input className="payment-Type" type="radio" value="Cash" name="paymentType" checked={paymentType === 'Cash' ? true : false} /> {translate('Payment-By-Cash')}
                            <input className="payment-Type" type="radio" value="CreditCard" name="paymentType" checked={paymentType === 'CreditCard' ? true : false} /> {translate('Payment-By-CreditCard')}
                        </div>
                    }

                    <div className="gaps-1x" />
                    {paymentType === 'Cash' &&
                        <div>
                            <div className="col-md-8">
                                <label className="input-item-label">{translate('Cash-Balance')}&nbsp;:&nbsp;{euroBalance} {translate('Euro')}</label>
                            </div>
                        </div>
                    }
                    <div className="gaps-1x" />
                    {(paymentType === 'Cash' || paymentType === 'CreditCard') &&
                        <a
                            href="#"
                            className="btn btn-primary payment-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#tranxETH"
                            onClick={event => goToConfirmationPage(event)}
                        >
                            {translate('To-Confirm-Page')}
                        </a>
                    }
                </div >
            }

            {pageId === 1 &&
                <div>
                    <table width='70%'>
                        <tr>
                            <td align='left' >{translate('Rate')} :</td>
                            <td align='right'>{aosRate} EUR/AOS </td>
                        </tr>
                        <tr>
                            <td align='left' >{translate('Order-Quantity')} :</td>
                            <td align='right' >{buyQuantity} AOS</td>
                        </tr>
                        <tr>
                            <td align='left' >{translate('Payment-Amount')} :</td>
                            <td align='right'>{buyAmount} EUR</td>
                        </tr>
                        <tr>
                            <td align='left'>{translate('Payment-Type')} :</td>
                            <td align='right'>{paymentType === 'CreditCard' ? translate('Payment-By-CreditCard') : translate('Payment-By-WalletBalance')} </td>
                        </tr>
                    </table>

                    {isButtonClicked &&
                        <>
                            <div className="gaps-1x" />
                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                        </>
                    }
                    <div className="gaps-1x" />
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => goToPrevPage(event)}>{translate('Back-Btn')}</button>&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => goToCheckout(event)}>{translate('Submit')}</button>
                    <div className="gaps-1x" />
                </div>
            }

        </div >

    );
};

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
        trancheRates: state.remote.currencyAos,
        users: state.remote.users,
        usersError: state.remote.usersError
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
    actionGetCurrencyAos
})(Token);