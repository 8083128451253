import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { LOCALES } from '../../i18n';
import { actionChangeLanguage, actionToggleSideBarMobile } from '../../store/action/layoutAction'
import { userLogout } from "./Firebase";
import translate from "../../i18n/translate";
import { actionGetUserProfile } from "../../store/action/remoteAction";

const Navbar = (props) => {
    const [uniqueId, setUniqueId] = useState("");
    const [email, setEmail] = useState("");
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [kycProcessInit, setKycProcessInit] = useState(false);
    const [euroBalance, setEuroBalance] = useState(0);
    const [aosBalance, setAosBalance] = useState(0);

    const toggleMobile = (e) => {
        setSidebarVisible(!sidebarVisible);
        props.actionToggleSideBarMobile(sidebarVisible);
    }

    const handleLogout = async () => {
        await userLogout(1);
    };

    useEffect(() => {
        if (props?.users.length > 0) {
            setUniqueId(props?.users[0]?.ClientID);
            (props?.users[0]?.UserNameFirst === null || props?.users[0]?.UserNameFirst.length === 0) ? setEmail(props?.users[0]?.MailID) : setEmail(props?.users[0]?.UserNameFirst + ' ' + props?.users[0]?.UserNameLast);
            (props?.users[0]?.KycStatus === "Success") ? setKycProcessInit(true) : setKycProcessInit(false);
            props?.users[0]?.EuroBalance ? setEuroBalance((props?.users[0]?.EuroBalance).toFixed(2)) : setEuroBalance(0);
            props?.users[0]?.AosBalance ? setAosBalance((props?.users[0]?.AosBalance).toFixed(2)) : setAosBalance(0);
        }
    }, [props.users]);

    useEffect(() => {
        props.actionGetUserProfile();
    }, []);

    useEffect(() => {
    }, [props.usersError]);

    return (props?.users?.length > 0) ? (
        <div className="topbar">
            <div className="topbar-md d-lg-none">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <a href="#" className="toggle-nav" onClick={toggleMobile}>
                            <div className="toggle-icon">
                                <span className="toggle-line" />
                                <span className="toggle-line" />
                                <span className="toggle-line" />
                                <span className="toggle-line" />
                            </div>
                        </a>
                        {/* .toggle-nav */}
                        <div className="site-logo">
                            <a href="/" className="site-brand">
                                <img
                                    src={require(`../../assets/images/logo.PNG`)}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="logo"
                                    srcSet={require(`../../assets/images/logo2x.PNG`)}
                                />
                            </a>
                        </div>
                        {/* .site-logo */}
                        <div className="dropdown topbar-action-item topbar-action-user">
                            <a href="#" data-bs-toggle="dropdown">
                                <img
                                    className="icon"
                                    src={require('../../assets/images/user-thumb-sm.png')}
                                    alt="thumb"
                                />
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                                <div className="user-dropdown">
                                    <div className="user-dropdown-head">
                                        <h6 className="user-dropdown-name">
                                            Unique ID: <span>{uniqueId}</span>
                                        </h6>
                                        <span className="user-dropdown-email">
                                            {email}
                                        </span>
                                    </div>
                                    <div className="user-dropdown-balance">
                                        <h6>{translate('Balance')}</h6>
                                        <h3>{aosBalance} AOS</h3>
                                        <h3>{euroBalance} EURO</h3>
                                    </div>
                                    <ul className="user-dropdown-links">
                                        <li>
                                            <a href="#" onClick={() => handleLogout()}>
                                                <i className="ti ti-power-off" />
                                                {translate('Logout')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* .toggle-action */}
                    </div>
                    {/* .container */}
                </div>
                {/* .container */}
            </div>
            {/* .topbar-md */}
            <div className="container">
                <div className="d-lg-flex align-items-center justify-content-between">
                    <div className="topbar-lg d-none d-lg-block">
                        <div className="site-logo">
                            <a href="/" className="site-brand">
                                <img
                                    src={require(`../../assets/images/logo.PNG`)}
                                    style={{ width: "30%", height: "30%" }}
                                    alt="logo"
                                    srcSet={require(`../../assets/images/logo.PNG`)}
                                />
                            </a>
                        </div>
                        {/* .site-logo */}
                    </div>
                    {/* .topbar-lg */}
                    <div className="topbar-action d-none d-lg-block">
                        <ul className="topbar-action-list">
                            <li className="topbar-action-item topbar-action-link">
                                <a href="/">
                                    <em className="ti ti-home" /> {translate('Go-to-main-site')}
                                </a>
                            </li>
                            {/* .topbar-action-item */}
                            <li className="dropdown topbar-action-item topbar-action-language">
                                <a href="/" data-bs-toggle="dropdown">
                                    {" "}
                                    {props.locale === 'en-us' ? 'EN' : 'JP'} <em className="ti ti-angle-down" />{" "}
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a href="/" onClick={(e) => {
                                            e.preventDefault();
                                            props.actionChangeLanguage(LOCALES.JAPANESE);
                                        }}>JP</a>
                                    </li>
                                    <li>
                                        <a href="/" onClick={(e) => {
                                            e.preventDefault();
                                            props.actionChangeLanguage(LOCALES.ENGLISH);
                                        }}>EN</a>
                                    </li>
                                </ul>
                            </li>
                            {/* .topbar-action-item */}
                            <li className="dropdown topbar-action-item topbar-action-user">
                                <a href="/" data-bs-toggle="dropdown">
                                    {" "}
                                    <img
                                        className="icon"
                                        src={require('../../assets/images/user-thumb-sm.png')}
                                        alt="thumb"
                                    />{" "}
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <div className="user-dropdown">
                                        <div className="user-dropdown-head">
                                            <h6 className="user-dropdown-name">
                                                <span>{uniqueId}</span>
                                            </h6>
                                            <span className="user-dropdown-email">
                                                {email}
                                            </span>
                                        </div>
                                        <div className="user-dropdown-balance">
                                            <h6>{translate('Balance')}</h6>
                                            <h3>{aosBalance} AOS</h3>
                                            <h3>{euroBalance} EURO</h3>
                                        </div>
                                        <ul className="user-dropdown-links">
                                            <li>
                                                <a href="#" onClick={() => handleLogout()}>
                                                    <i className="ti ti-power-off" />
                                                    {translate('Logout')}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            {/* .topbar-action-item */}
                        </ul>
                        {/* .topbar-action-list */}
                    </div>
                    {/* .topbar-action */}
                </div>
                {/* .d-flex */}
            </div>
            {/* .container */}
        </div>
    ) : "";
};

const mapStateToProps = state => {
    return {
        locale: state.siteLayout.locale,
        sideBarMobile: state.siteLayout.sideBarMobile,
        users: state.remote.users,
        usersError: state.remote.usersError
    };
};

export default connect(mapStateToProps, {
    actionChangeLanguage,
    actionGetUserProfile,
    actionToggleSideBarMobile
})(Navbar);
