import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { formatDate } from "../store/action/remoteAction";
import { resetTimout } from "./common/Firebase";

let CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const ReferralCode = (props) => {
    let uid = localStorage.getItem("uid");
    const [emailPref, setEmailPref] = useState(false);

    const [data, setData] = useState({
        'UserNameFirst': '',
        'UserNameLast': '',
        'UserNameLast_DByte': '',
        'UserNameFirst_DByte': '',
        'MailID': '',
        'DOB': '',
        'Nationality': '',
        'PhoneNumber1': '',
    });

    const handleCheck = (e) => {
        resetTimout();
        setEmailPref(e.target.checked);
        localStorage.setItem("emailPref", e.target.checked);
    }

    useEffect(() => {
        let pref = localStorage.getItem("emailPref");
        setEmailPref(pref);
        //actionGetUserProfile request send from Navbar
    }, []);

    useEffect(() => {
        if (props?.users?.length > 0) {
            if (props?.users[0]?.KycStatus === null || props?.users[0]?.KycStatus.length === 0) {
                props.users[0].KycStatus = "In-Progress";
            }
            setData(props?.users[0]);
        }
    }, [props.users]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-Notification')}</h2>

            {/* .tab-pane notification*/}
            <div className="tab-pane" id="notification">
                <div className="gaps-1x" />
                <ul className="notification-list">
                    <li>
                        <span>{translate('Notify-latest')}</span>
                        <div className="padl-2x">
                            <Switch {...label} onChange={handleCheck} checked={emailPref} inputProps={{ 'aria-label': 'controlled' }} />
                        </div>
                    </li>
                </ul>
                <ul>
                    {translate('notification-footer-note')}
                </ul>
            </div>
            {/* .tab-pane */}
        </div >
    );
};

const mapStateToProps = state => {
    return {
        users: state.remote.users
    };
};

export default connect(mapStateToProps, {
})(ReferralCode);
