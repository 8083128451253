import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import translate from "../i18n/translate";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { actionGetBitcoinBalance, actionGetCashBalance, actionGetCommissionRate, actionGetBitcoinGasFee } from "../store/action/remoteAction";
import connect from "react-redux/es/components/connect";
import { getMultiLanguageMessage } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;
let EURO_DECIMALCOUNT = process.env.REACT_APP_EURO_DECIMALCOUNT;
let BTC_DECIMALCOUNT = process.env.REACT_APP_BTC_DECIMALCOUNT;

const OtcBuyToken = (props) => {
    const [btcWalletStatus, setBtcWalletStatus] = useState(0);
    const [isPartialOrderFlag, setIsPartialOrderFlag] = useState(false);
    const [aos, setAos] = useState(0);
    const [currency, setCurrency] = useState('');
    const [decimalCount, setDecimalCount] = useState(EURO_DECIMALCOUNT);
    const [aosRate, setAosRate] = useState(0);
    const [aosBuyAmount, setAosBuyAmount] = useState(0);
    const [commissionID, setCommissionID] = useState(0);
    const [commissionAmount, setCommissionAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [language, setLanguage] = useState("");
    const [pageId, setPageId] = useState(0);
    const [paymentType, setPaymentType] = useState("");
    const [euroBalance, setEuroBalance] = useState(0);
    const [btcBalance, setBtcBalance] = useState(0);
    const [btcGasFee, setBtcGasFee] = useState(0);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const onChangeCurrency = (e) => {
        resetTimout();
        setCurrency(e.target.value);
        setAosRate(0);
        setTotalAmount(0);
        if (e.target.value === 'BTC') {
            setDecimalCount(BTC_DECIMALCOUNT);
            if (btcGasFee === 0) {
                props.actionGetBitcoinGasFee();
            }
            if (parseFloat(btcBalance) === 0) {
                props.actionGetBitcoinBalance();
            }
        } else {
            setDecimalCount(EURO_DECIMALCOUNT);
        }
    };

    const onChangePaymentType = (e) => {
        resetTimout();
        setPaymentType(e.target.value);
    };

    const partialOrderOnChange = (e) => {
        resetTimout();
        setIsPartialOrderFlag(!isPartialOrderFlag);
    };

    const handleChange = (e) => {
        resetTimout();
        let nAmount = 0;
        switch (e.target.id) {
            case "outlined-start-adornment-aos":
                let requestedAos = e.target.value.replace(/[^0-9.]/g, "");
                setAos(requestedAos);
                if (parseFloat(requestedAos) > 0 && parseFloat(aosRate) > 0) {
                    nAmount = (parseFloat(requestedAos) * parseFloat(aosRate)).toFixed(decimalCount);
                }
                setAosBuyAmount(nAmount);
                calculateCommissionAmount(requestedAos, nAmount);
                break;
            case "outlined-start-adornment-rate":
                let requestedPrice = e.target.value.replace(/[^0-9.]/g, "");
                setAosRate(requestedPrice);
                if (parseFloat(aos) > 0 && parseFloat(requestedPrice) > 0) {
                    nAmount = (parseFloat(aos) * parseFloat(requestedPrice)).toFixed(decimalCount);
                }
                calculateCommissionAmount(aos, nAmount);
                setAosBuyAmount(nAmount);
                break;
            default: console.log('err');
        }
    };

    const calculateCommissionAmount = (buyQuantity, buyAmount) => {
        if (!props.commissionRates || props.commissionRates.length <= 0) {
            alert("Unexpected Error. Please contact customer support.");
            return;
        }
        let commissionRate = props.commissionRates[0];
        setCommissionID(commissionRate.CommissionID);
        let nCommissionAmount = 0;
        if (commissionRate.CommissionCalcType === 1) {
            nCommissionAmount = parseFloat((parseFloat(buyQuantity) * parseFloat(commissionRate.CommissionValue)).toFixed(EURO_DECIMALCOUNT));
        } else if (commissionRate.CommissionCalcType === 2) {
            nCommissionAmount = parseFloat((parseFloat(buyAmount) * (parseFloat(commissionRate.CommissionValue) / 100)).toFixed(EURO_DECIMALCOUNT));
        }
        if (!nCommissionAmount) {
            nCommissionAmount = 0;
        }
        setCommissionAmount(nCommissionAmount);
        let nTotalAmount = 0;
        if (currency === 'BTC') {
            nTotalAmount = parseFloat((parseFloat(buyAmount) + parseFloat(btcGasFee)).toFixed(BTC_DECIMALCOUNT));
        } else {
            if (parseFloat(buyAmount) > 0) {
                nTotalAmount = (parseFloat(buyAmount) + parseFloat(nCommissionAmount)).toFixed(EURO_DECIMALCOUNT);
            }
        }
        setTotalAmount(nTotalAmount);
    };

    const goToConfirmationPage = async () => {
        resetTimout();

        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aosRate) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }
        if (parseFloat(aosBuyAmount) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }
        if (currency === 'BTC') {
            if (parseFloat(btcBalance) < (parseFloat(aosBuyAmount) + parseFloat(btcGasFee))) {
                alert(getMultiLanguageMessage('msg_InsufficientBitcoinBalance_Error', language));
                return;
            }
            if (paymentType === 'Cash' && parseFloat(euroBalance) < parseFloat(commissionAmount)) {
                alert(getMultiLanguageMessage('msg_InsufficientBalance_Error', language));
                return;
            }
            if (isPartialOrderFlag) {
                if (!window.confirm(getMultiLanguageMessage('msg_Bitcoin_PatialOrder_Confirm', language))) {
                    return;
                }
            }
        } else if (currency === 'EURO') {
            if (paymentType === 'Cash' && parseFloat(euroBalance) < parseFloat(totalAmount)) {
                alert(getMultiLanguageMessage('msg_InsufficientBalance_Error', language));
                return;
            }
        }

        setPageId(1);
    };

    const goToPrevPage = () => {
        resetTimout();
        setPageId(0);
    };

    const submitOtcBuyTransaction = async () => {
        resetTimout();
        let uid = localStorage.getItem("uid");
        let nPaymentType = 0;

        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (currency === 'BTC') {
            if (paymentType === 'Cash') {
                nPaymentType = 1;
            } else if (paymentType === 'CreditCard') {
                nPaymentType = 2;
            }
        }

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            let serverUrl = '';
            let bodyJSON = JSON.stringify({
                UserId: uid,
                CryptoAsset: "AOS",
                BuySellFlag: 7,
                Currency: currency,
                FromWalletId: uid,
                ToWalletID: "",
                Quantity: "" + aos + "",
                UnitPrice: aosRate,
                Amount: aosBuyAmount,
                OtherGasFee: btcGasFee,
                CommissionID: commissionID,
                CommissionAmount: commissionAmount,
                PaymentType: nPaymentType,
                OtcFlag: 1,
                PartialOrderFlag: isPartialOrderFlag ? 1 : 0
            });

            if (currency === 'BTC') {
                if (paymentType === 'CreditCard') {
                    serverUrl = SERVER_URL + `/btc-buyopen-otc-checkout`;
                } else if (paymentType === 'Cash') {
                    serverUrl = SERVER_URL + `/btc-buyopen-otc-cashbalance`;
                } else {
                    return;
                }
            } else {
                if (paymentType === 'CreditCard') {
                    serverUrl = SERVER_URL + `/create-checkout-session-paymix-open-otcbuy`;
                } else if (paymentType === 'Cash') {
                    serverUrl = SERVER_URL + `/otcbuy-Open-cashbalance`;
                } else {
                    return;
                }
            }

            if (serverUrl) {
                setIsButtonClicked(true);
                fetch(serverUrl, {
                    method: 'POST',
                    redirect: 'follow',
                    headers: { 'Content-Type': 'application/json' },
                    body: bodyJSON,
                })
                    .then(res => {
                        if (res.ok) return res.json()
                        return res.json().then(json => Promise.reject(json))
                    })
                    .then((body) => {
                        window.location.href = body.url;
                    })
                    .catch((error) => {
                        let errorMessage = "Error: ";
                        if (error && error.message) {
                            errorMessage += error.message;
                        } else {
                            errorMessage += JSON.stringify(error)
                        }
                        alert(errorMessage);
                        setIsButtonClicked(false);
                    });
            }
        }

    };

    useEffect(() => {
        props.actionToggleNavBar(true);
        props.actionToggleSideBar(true);
        props.actionToggleFooter(true);
        props.actionGetCashBalance();
        props.actionGetCommissionRate(7);
    }, []);

    useEffect(() => {
        if (props?.users?.length > 0) {
            setBtcWalletStatus(props.users[0].BtcWalletStatus);
            props.users[0].BtcWalletStatus === 2 ? setCurrency('') : setCurrency('EURO');
        }
    }, [props.users]);

    useEffect(() => {
        if (props.cashBalances?.length > 0) {
            let userBalance = props.cashBalances[0];
            setEuroBalance(userBalance.Balance);
        }
    }, [props.cashBalances]);

    useEffect(() => {
        if (props?.bitcoinBalances && props?.bitcoinBalances.length > 0) {
            setBtcBalance(props?.bitcoinBalances[0].Balance);
        }
    }, [props.bitcoinBalances]);

    useEffect(() => {
        if (props?.bitcoinGasFees && props?.bitcoinGasFees.length > 0) {
            setBtcGasFee(parseFloat(props?.bitcoinGasFees[0].ConfigValue));
        }
    }, [props.bitcoinGasFees]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-OTCBuy-Open')}</h2>

            {pageId === 0 &&
                <div>
                    <div className="gaps-1x" />
                    <div className="row">
                        <div className="col-md-8" style={{ padding: 0 }}>
                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <div>
                                    <TextField
                                        size="small"
                                        label={translate('Order-Quantity')}
                                        id="outlined-start-adornment-aos"
                                        sx={{ width: '24.5ch' }}
                                        value={aos}
                                        defaultValue={aos}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">AOS</InputAdornment>,
                                        }}
                                    />
                                </div>

                                {(btcWalletStatus === 2) &&
                                    <>
                                        <div className="gaps-1x" />
                                        <div>
                                            <input className="payment-Type" type="radio" value="EURO" name="CurrencyType" checked={currency === 'EURO' ? true : false} onChange={onChangeCurrency} /> {translate('Currency-Euro')}
                                            <input className="payment-Type" type="radio" value="BTC" name="CurrencyType" checked={currency === 'BTC' ? true : false} onChange={onChangeCurrency} /> {translate('Currency-Bitcoin')}
                                        </div>
                                    </>
                                }

                                <div>
                                    <div className="gaps-2x" />
                                    <TextField
                                        size="small"
                                        label={translate('Rate')}
                                        id="outlined-start-adornment-rate"
                                        sx={{ width: '24.5ch' }}
                                        value={aosRate}
                                        defaultValue={aosRate}
                                        onChange={handleChange}
                                        disabled={currency === '' ? true : false}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{currency === 'BTC' ? translate('Bitcoin') : currency === 'EURO' ? translate('Euro') : ''}</InputAdornment>,
                                        }}
                                    />
                                </div>

                                <div>
                                    <div className="gaps-1x" />
                                    <FormControlLabel control={<Checkbox
                                        onChange={partialOrderOnChange}
                                        checked={isPartialOrderFlag}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} label={translate('Partial-Order')} />
                                </div>

                                <div>
                                    <div className="gaps-1x" />
                                    <TextField
                                        size="small"
                                        label={translate('Commission-Amount')}
                                        id="commission-amount"
                                        sx={{ width: '24.5ch' }}
                                        value={commissionAmount}
                                        defaultValue={commissionAmount}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{translate('Euro')}</InputAdornment>,
                                        }}
                                        disabled={true}
                                    />
                                </div>

                                {currency === 'BTC' &&
                                    <div>
                                        <div className="gaps-1x" />
                                        <TextField
                                            size="small"
                                            label={translate('GasFee')}
                                            id="bitcoin-gasfee"
                                            sx={{ width: '24.5ch' }}
                                            value={btcGasFee}
                                            defaultValue={btcGasFee}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">{translate('Bitcoin')}</InputAdornment>,
                                            }}
                                            disabled={true}
                                        />
                                    </div>
                                }

                                <div>
                                    <div className="gaps-1x" />
                                    <TextField
                                        size="small"
                                        label={translate('Payment-Amount')}
                                        id="payment-amount"
                                        sx={{ width: '24.5ch' }}
                                        value={totalAmount}
                                        defaultValue={totalAmount}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</InputAdornment>,
                                        }}
                                        disabled={true}
                                    />
                                </div>
                                {currency === 'BTC' &&
                                    <div>
                                        <div className="col-md-8">
                                            <label className="input-item-label">{translate('Bitcoin-Balance')}&nbsp;:&nbsp;{btcBalance} {translate('Bitcoin')}</label>
                                        </div>
                                    </div>
                                }

                                <div className="gaps-1x" />
                                {currency === 'BTC' &&
                                    <label className="input-item-label">{translate('Commission-Payment-Type')}</label>
                                }
                                <div>
                                    <input className="payment-Type" type="radio" value="Cash" name="paymentType" onChange={onChangePaymentType} checked={paymentType === 'Cash' ? true : false} /> {translate('Payment-By-Cash')}
                                    <input className="payment-Type" type="radio" value="CreditCard" name="paymentType" onChange={onChangePaymentType} checked={paymentType === 'CreditCard' ? true : false} /> {translate('Payment-By-CreditCard')}
                                </div>
                                <div className="gaps-1x" />

                                <div className="gaps-1x" />
                                {paymentType === 'Cash' &&
                                    <div>
                                        <div className="col-md-8">
                                            <label className="input-item-label">{translate('Cash-Balance')}&nbsp;:&nbsp;{euroBalance} {translate('Euro')}</label>
                                        </div>
                                    </div>
                                }
                                <div className="gaps-1x" />
                                {(paymentType === 'Cash' || paymentType === 'CreditCard') &&
                                    <a
                                        href="#"
                                        className="btn btn-primary payment-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#tranxETH"
                                        onClick={event => goToConfirmationPage(event)}
                                    >
                                        {translate('To-Confirm-Page')}
                                    </a>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="gaps-1x" />
                </div>
            }

            {pageId === 1 &&
                <div>
                    <table width='70%'>
                        <tbody>
                            <tr>
                                <td align='left' >{translate('Order-Quantity')} :</td>
                                <td align='right' >{aos} AOS</td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Currency')} :</td>
                                <td align='right'>{currency === 'BTC' ? translate('Currency-Bitcoin') : translate('Currency-Euro')} </td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Rate')} :</td>
                                <td align='right'>{aosRate} {currency === 'BTC' ? translate('Bitcoin-Aos') : translate('Euro-Aos')} </td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Amount')} :</td>
                                <td align='right'>{parseFloat(aosBuyAmount)} {currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</td>
                            </tr>
                            <tr>
                                <td align='left'>{translate('Commission-Amount')} :</td>
                                <td align='right'>{parseFloat(commissionAmount).toFixed(EURO_DECIMALCOUNT)} {translate('Euro')}</td>
                            </tr>
                            {currency === 'BTC' &&
                                <tr>
                                    <td align='left' >{translate('GasFee')} :</td>
                                    <td align='right'>{parseFloat(btcGasFee)} {translate('Bitcoin')}</td>
                                </tr>
                            }
                            <tr>
                                <td align='left' >{translate('Payment-Amount')} :</td>
                                <td align='right'>{currency === 'BTC' ? parseFloat((parseFloat(aosBuyAmount) + parseFloat(btcGasFee)).toFixed(decimalCount)) : parseFloat(totalAmount).toFixed(decimalCount)} {currency === 'BTC' ? translate('Bitcoin') : translate('Euro')}</td>
                            </tr>
                            <tr>
                                <td align='left'>{currency === 'BTC' ? translate('Commission-Payment-Type') : translate('Payment-Type')} :</td>
                                <td align='right'>{paymentType === 'CreditCard' ? translate('Payment-By-CreditCard') : translate('Payment-By-WalletBalance')} </td>
                            </tr>
                        </tbody>
                    </table>

                    {isButtonClicked &&
                        <>
                            <div className="gaps-1x" />
                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                        </>
                    }
                    <div className="gaps-1x" />
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={goToPrevPage}>{translate('Back-Btn')}</button>&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={submitOtcBuyTransaction}>{translate('Submit')}</button>
                    <div className="gaps-1x" />
                </div>
            }
            {/* form */}
        </div >

    );
};

const mapStateToProps = state => {
    return {
        users: state.remote.users,
        navBar: state.siteLayout.navBar,
        euroRate: state.remote.currencyEuro,
        cashBalances: state.remote.cashBalances,
        bitcoinBalances: state.remote.userBtcBalances,
        commissionRates: state.remote.commissionRates,
        bitcoinGasFees: state.remote.bitcoinGasFees
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
    actionGetCashBalance,
    actionGetBitcoinBalance,
    actionGetCommissionRate,
    actionGetBitcoinGasFee
})(OtcBuyToken);
