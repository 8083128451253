import React from 'react';
import { useState } from "react"
import BidsTableBody from "./BidsTableBody";
import BidsTableHead from "./BidsTableHead";
import translate from "../i18n/translate";

const BidsTable = ({ tableData, language, kycStatus }) => {
    const [sortedTableData, setSortedTableData] = useState(null);

    const columns = [
        { label: translate('Date'), accessor: "DateCreated", sortable: true },
        { label: translate('Buy-Sell'), accessor: "BuySellFlag", sortable: true },
        { label: translate('Amount'), accessor: "Amount", sortable: true },
        { label: translate('Quantity'), accessor: "RemainingQuantity", sortable: true },
        { label: translate('Partial-Order'), accessor: "PartialOrderFlag", sortable: true },
        { label: '', accessor: "BuyButton", sortable: false },
    ];

    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...tableData].sort((a, b) => {
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                if (a[sortField] === null && b[sortField] === null) return 0;
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            tableData = sorted;
            setSortedTableData(sorted);
        }
    };

    return (
        <>
            <table className="table">
                <BidsTableHead columns={columns} handleSorting={handleSorting} />
                <BidsTableBody columns={columns} tableData={sortedTableData ? sortedTableData : tableData} language={language} kycStatus={kycStatus} />
            </table>
        </>
    );
};

export default BidsTable;