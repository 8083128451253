import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import Box from '@mui/system/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import NativeSelect from '@mui/material/NativeSelect';
import { actionGetUserCashTransaction, client } from "../store/action/remoteAction";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from "react-router-dom";
import { getMultiLanguageLabel, getMultiLanguageMessage } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;

const CashTransactions = (props) => {
    const [message, setMessage] = useState("");
    const [language, setLanguage] = useState("");
    const [transactionType, setTransactionType] = useState('');
    const [processStatus, setProcessStatus] = useState('');
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        props.actionGetUserCashTransaction('');
    }, []);

    const Message = ({ message }) => (
        <section>
            <p style={{ color: isError ? "red" : "blue" }}>{message}</p>
        </section>
    );

    const searchTransactions = () => {
        resetTimout();
        props.actionGetUserCashTransaction(formFilter());
    };

    const CancelWithdraw = async (e) => {
        resetTimout();
        if (window.confirm(getMultiLanguageMessage('msg_WithdrawCancel_Confirm', language))) {
            let uid = localStorage.getItem("uid");
            let transaction = props.cashtransactions[e];
            await client(localStorage.getItem("@token")).put(`${BASE_URL}/cashtransaction/cancelwithdraw/` + transaction.TransactionID, { "TransferNote": transaction.TransferNote, "UserID": uid }).then((response) => {
                if (response.status == 200) {
                    setMessage(getMultiLanguageMessage('msg_WithdrawCancel_Success', language));
                    setIsError(false);
                } else {
                    setMessage(getMultiLanguageMessage('msg_WithdrawCancel_Failed', language));
                    setIsError(true);
                }
            }).catch(error => {
                setMessage(getMultiLanguageMessage('msg_WithdrawCancel_Failed', language));
                console.log(error);
                setIsError(true);
            });
            props.actionGetUserCashTransaction(formFilter());
        }
    };

    const onChangeTransactionType = (event) => {
        resetTimout();
        setTransactionType(event.target.value);
    };

    const onChangeStatus = (event) => {
        resetTimout();
        setProcessStatus(event.target.value);
    };

    const formFilter = () => {
        resetTimout();
        let filterCondition = "";
        if (transactionType) {
            filterCondition += ',"TransactionType": "' + transactionType + '"';
        }
        if (processStatus) {
            filterCondition += ',"ProcessStatus": "' + processStatus + '"';
        }

        return filterCondition;
    };

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-Statement')}</h2>
            {/* if there is no transaction you can use this code */}

            {message ? (
                <Message message={message} />
            ) : ''}
            <Box sx={{ display: 'flex' }}>
                <Accordion sx={{ display: 'nowrap', marginBottom: '10' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>{translate('Filters')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">{translate('TransactionType')}</FormLabel>
                            <FormGroup>
                                <NativeSelect
                                    inputProps={{
                                        name: 'transactionType',
                                        id: 'transactionType',
                                    }}
                                    onChange={onChangeTransactionType}
                                >
                                    <option value={''}></option >
                                    <option value={'Deposit'}>{getMultiLanguageLabel('lbl_Deposit', language)}</option >
                                    <option value={'Withdraw'}>{getMultiLanguageLabel('lbl_Withdraw', language)}</option >
                                </NativeSelect>
                            </FormGroup>
                        </FormControl>

                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">{translate('Status')}</FormLabel>
                            <FormGroup>
                                <NativeSelect
                                    inputProps={{
                                        name: 'status',
                                        id: 'status',
                                    }}
                                    onChange={onChangeStatus}
                                >
                                    <option value={''}></option>
                                    <option value={'Created'}>{getMultiLanguageLabel('lbl_Created', language)}</option>
                                    <option value={'Processed'}>{getMultiLanguageLabel('lbl_Processed', language)}</option>
                                    <option value={'Cancelled'}>{getMultiLanguageLabel('lbl_Cancelled', language)}</option>
                                </NativeSelect>
                            </FormGroup>
                        </FormControl>

                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <Button variant="contained" onClick={() => searchTransactions()}>{translate('Search')}</Button>
                        </FormControl>
                    </AccordionDetails>

                </Accordion>
            </Box>

            <div className="gaps-2x" />
            {/* NEW TABLE */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className="data-table tranx-table">
                    <TableHead>
                        <TableRow>
                            <TableCell width='14%' align="center" component="th">{translate('TransactionType')}</TableCell>
                            <TableCell width='10%' align="center" component="th">{translate('Amount')}</TableCell>
                            <TableCell width='8%' align="center" component="th">{translate('Currency')}</TableCell>
                            <TableCell width='15%' align="center" component="th">{translate('Status')}</TableCell>
                            <TableCell width='15%' align="center" component="th">{translate('CreatedDate')}</TableCell>
                            <TableCell width='15%' align="center" component="th">{translate('ProcessedDate')}</TableCell>
                            <TableCell width='15%' align="center" component="th">{translate('WithdrawAccount')}</TableCell>
                            <TableCell width='8%' align="center" component="th"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props?.cashtransactions?.map((txn, index) => {
                            return <TableRow sx={{
                                '&:last-child td, &:last-child th': { border: 0 }
                            }}>
                                <TableCell width='14%' align='center'>
                                    <span>{translate(txn.TransactionType)}</span>
                                </TableCell>
                                <TableCell width='10%' align='center'>
                                    <span>{txn.Amount}</span>
                                </TableCell>
                                <TableCell width='8%' align='center'>
                                    <span>{translate('Euro')}</span>
                                </TableCell>
                                <TableCell width='15%' align='center'>
                                    <span>{translate(txn.ProcessStatus)}</span>
                                </TableCell>
                                <TableCell width='15%' align='center'>
                                    <span>{txn.DateCreated}</span>
                                </TableCell>
                                <TableCell width='15%' align='center'>
                                    <span>{txn.DateProcessed ? txn.DateProcessed : ''}</span>
                                </TableCell>
                                <TableCell width='15%' align='center' className='td-wrap-text'>
                                    <span>{txn.WithdrawAccountNo}</span>
                                </TableCell>
                                <TableCell width='8%' align='center'>
                                    {(txn.TransactionType === 'Withdraw' && txn.ProcessStatus === 'Created') ? <IconButton color="primary" aria-label="add to shopping cart">
                                        <CancelIcon data-index={index} onClick={() => CancelWithdraw(index)} />
                                    </IconButton> : ""}
                                </TableCell>
                            </TableRow>
                        })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {/* NEW TABLE ENDS */}
        </div>

    );
};

const mapStateToProps = state => {
    return {
        cashtransactions: state.remote.transactions
    };
};

export default connect(mapStateToProps, {
    actionGetUserCashTransaction
})(CashTransactions);
