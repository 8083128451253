import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import translate from "../i18n/translate";
import { Select, MenuItem, OutlinedInput, InputLabel, FormControl } from "@mui/material";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { actionGetCashBalance, actionGetBankAccount } from "../store/action/remoteAction";
import connect from "react-redux/es/components/connect";
import { client } from "../store/action/remoteAction";
import Alert from '@mui/material/Alert';
import { LOCALES } from '../i18n/constants';
import { resetTimout } from "./common/Firebase";
import { getMultiLanguageMessage } from '../i18n/multiLanguage';

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;

const WithdrawRequest = (props) => {
    const [euroBalance, setEuroBalance] = useState(0);
    const [withdrawRequestAmount, setWithdrawRequestAmount] = useState(0);
    const [transferNote, setTransferNote] = useState(''); //Not Used
    const [submitted, setSubmitted] = useState(0);
    const [seletedBankIndex, setSeletedBankIndex] = useState(-1);
    const [seletedBank, setSeletedBank] = useState('');
    const [isAgree, setIsAgree] = useState(false);
    const [language, setLanguage] = useState("");
    const [pageId, setPageId] = useState(0);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const submitWithdrawRequest = async () => {
        resetTimout();
        let uid = localStorage.getItem("uid");

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            setIsButtonClicked(true);
            const request = {
                "UserID": uid,
                "Currency": "EURO",
                "TransactionType": "Withdraw",
                "Amount": "" + withdrawRequestAmount + "",
                "WithdrawBankAccountID": seletedBank.AUTORECID,
                "TransferNote": transferNote,
                "CreatedByUser": uid
            };
            await client(localStorage.getItem("@token")).post(`${BASE_URL}/cashtransaction`, request).then((response) => {
                let newBalance = parseFloat(euroBalance) - parseFloat(withdrawRequestAmount);
                setEuroBalance(newBalance.toFixed(2));
                setWithdrawRequestAmount(0);
                setTransferNote('');
                setSubmitted(parseInt(response.status));
                window.location.href = '/cashtransaction';
            }).catch(error => {
                let errorMessage = "Error: ";
                if (error && error.message) {
                    errorMessage += error.message;
                } else {
                    errorMessage += JSON.stringify(error)
                }
                alert(errorMessage);
                setIsButtonClicked(false);
            });
        }
    };

    const goToConfirmationPage = (e) => {
        resetTimout();
        let errorIncorrectAmunt = "Incorrect Amount. Enter upto 2 decimal places.";
        let errorInsufficientBalance = "Withdraw Amount is more than cash balance";
        let errorBankAccountNotExist = "Please select a bank account for withdrawal";
        let agreeNoSelected = 'Please check Agree.';
        if (language === LOCALES.JAPANESE) {
            errorIncorrectAmunt = '金額が正しくありません。 小数点以下2桁まで入力してください。';
            errorInsufficientBalance = '出金金額が現金残高を超えています。';
            errorBankAccountNotExist = "出金先口座を選択してください。";
            agreeNoSelected = '同意するにチェックを入れてください。';
        }

        if (!withdrawRequestAmount || !withdrawRequestAmount.match(/^\d{1,}(\.\d{0,2})?$/) || parseFloat(withdrawRequestAmount) <= 0) {
            alert(errorIncorrectAmunt);
            return;
        }
        if (parseFloat(withdrawRequestAmount) > euroBalance) {
            alert(errorInsufficientBalance);
            return;
        }
        if (seletedBankIndex < 0) {
            alert(errorBankAccountNotExist);
            return;
        }
        if (transferNote.length > 255) {
            alert("Note must not exceed 255 characters");
            return;
        }

        if (!isAgree) {
            alert(agreeNoSelected);
            return;
        }

        setPageId(1);
    };

    const goToPrevPage = (e) => {
        resetTimout();
        setIsAgree(false);
        setPageId(0);
    };

    const handleChange = (e) => {
        resetTimout();
        switch (e.target.id) {
            case "id_WithdrawAmount":
                setWithdrawRequestAmount(e.target.value.replace(/[^0-9.]/g, ""));
                break;
            case "id_TransferNote":
                setTransferNote(e.target.value);
                break;
            default: console.log('err');
        }
    };

    const handleAllAmount = (e) => {
        resetTimout();
        setWithdrawRequestAmount(euroBalance.toFixed(2));
    };

    const handleClear = (e) => {
        resetTimout();
        setWithdrawRequestAmount(0);
    };

    const handleBankAccountChange = (e) => {
        resetTimout();
        let index = e.target.value;
        if (index >= 0) {
            setSeletedBank(props.userBankAccounts[index])
            setSeletedBankIndex(index);
        } else {
            setSeletedBank('');
            setSeletedBankIndex(-1);
        }
    };

    const handleIsAgreeChange = () => {
        resetTimout();
        setIsAgree(!isAgree);
    };

    useEffect(() => {
        props.actionToggleNavBar(true);
        props.actionToggleSideBar(true);
        props.actionToggleFooter(true);
        props.actionGetCashBalance();
        props.actionGetBankAccount();
        setLanguage(localStorage.getItem("locale"));
    }, []);

    useEffect(() => {
        if (props.userBalances?.length > 0) {
            let userBalance = props.userBalances[0];
            setEuroBalance(userBalance.Balance);
        }
    }, [props.userBalances]);

    useEffect(() => {
        if (props?.userBankAccounts?.length > 0) {
        }
    }, [props.userBankAccounts]);

    return (
        <div className="withdraw-panel">
            <h2 className="user-panel-title">{translate('WithdrawRequest')}</h2>

            <div className="gaps-1x" />
            <div>
                <div className="gaps-1x" />

                {pageId === 0 &&
                    <div>
                        <table width='100%'>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('WithdrawPossibleAmount')}</td>
                                <td className='td-type1-col2'>{euroBalance}&nbsp;{translate('Euro')}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('WithdrawAmount')}</td>
                                <td className='td-type1-col2'>
                                    <TextField
                                        size="small"
                                        id="id_WithdrawAmount"
                                        sx={{ width: '24.5ch' }}
                                        value={withdrawRequestAmount}
                                        defaultValue={''}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{translate('Euro')}</InputAdornment>,
                                        }}
                                    />
                                    <div>{translate('MinimumAmount')}&nbsp;:&nbsp;0.01</div>
                                    <div>
                                        <button className="btn btn-primary" onClick={handleAllAmount}>{translate('button-All')}</button>&nbsp;&nbsp;
                                        <button className="btn btn-primary" onClick={handleClear}>{translate('button-Clear')}</button>
                                    </div>
                                </td>
                            </tr>

                            <tr className='tr-type1'>
                                <td colSpan={2} align='center'>
                                    <FormControl style={{ maxWidth: 450 }}>
                                        <Select id="withdrawBank" value={seletedBankIndex} onChange={handleBankAccountChange} className="input-item-label">
                                            <MenuItem value={-1}>
                                                <em>{translate('Select-BankAccount-Message')}</em>
                                            </MenuItem>
                                            {props?.userBankAccounts?.map((bankAccount, index) => {
                                                return <MenuItem value={index}>{bankAccount.BankName + '-' + bankAccount.AccountNo}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('WithdrawAccountName')}</td>
                                <td className='td-type1-col2'>{seletedBank?.AccountName}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('IBAN')}</td>
                                <td className='td-type1-col2'>{seletedBank?.IBAN}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('AccountNo')}</td>
                                <td className='td-type1-col2'>{seletedBank?.AccountNo}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('BankName')}</td>
                                <td className='td-type1-col2'>{seletedBank?.BankName}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('BankAddress')}</td>
                                <td className='td-type1-col2'>{seletedBank?.BankAddress}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('SwiftCode')}</td>
                                <td className='td-type1-col2'>{seletedBank?.SwiftCode}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('WithdrawDeclaration')}</td>
                                <td className='td-type1-col2'>
                                    {language === LOCALES.JAPANESE &&
                                        <span>
                                            {translate('WithdrawDeclare-Message')}<br />
                                            <FormControlLabel control={<Checkbox
                                                onChange={handleIsAgreeChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                checked={isAgree ? true : false}
                                            />} label={translate('WithdrawDeclareButton')} />
                                        </span>
                                    }
                                    {language !== LOCALES.JAPANESE &&
                                        <span>
                                            <FormControlLabel control={<Checkbox
                                                onChange={handleIsAgreeChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                checked={isAgree ? true : false}
                                            />} label={translate('WithdrawDeclareButton')} />
                                            <br />{translate('WithdrawDeclare-Message')}
                                        </span>
                                    }
                                </td>
                            </tr>
                        </table>

                        <div className="gaps-3x" />
                        <button className="btn btn-primary" onClick={goToConfirmationPage}>{translate('To-Confirm-Page')}</button>
                    </div>
                }

                {pageId === 1 &&
                    <div>
                        <table width='100%'>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('WithdrawAmount')}</td>
                                <td className='td-type1-col2'>{withdrawRequestAmount}&nbsp;{translate('Euro')}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('WithdrawAccountName')}</td>
                                <td className='td-type1-col2'>{seletedBank?.AccountName}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('IBAN')}</td>
                                <td className='td-type1-col2'>{seletedBank?.IBAN}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('AccountNo')}</td>
                                <td className='td-type1-col2'>{seletedBank?.AccountNo}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('BankName')}</td>
                                <td className='td-type1-col2'>{seletedBank?.BankName}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('BankAddress')}</td>
                                <td className='td-type1-col2'>{seletedBank?.BankAddress}</td>
                            </tr>
                            <tr className='tr-type1'>
                                <td className='td-type1-col1'>{translate('SwiftCode')}</td>
                                <td className='td-type1-col2'>{seletedBank?.SwiftCode}</td>
                            </tr>
                        </table>
                        {/* .row */}
                        <div className="gaps-3x" />
                        <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => goToPrevPage(event)}>{translate('Back-Btn')}</button>&nbsp;&nbsp;
                        <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={submitWithdrawRequest}>{translate('WithdrawRequest')}</button>
                        <div className="gaps-2x" />
                        {submitted === 200 ? <Alert severity="success">{translate('WithdrawSuccess')}</Alert> : submitted !== 0 ? <Alert severity="error">{translate('ServerError')}</Alert> : ''}
                        <div className="gaps-2x" />
                    </div>
                }

            </div>
            {/* form */}
        </div >

    );
};

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
        userBalances: state.remote.userBalances,
        userBankAccounts: state.remote.userBankAccounts
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
    actionGetCashBalance,
    actionGetBankAccount
})(WithdrawRequest);
