import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from '@mui/material';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { resetTimout } from "./common/Firebase";

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const WalletAddress = (props) => {
    const [isWalletAddressCopied, setIsWalletAddressCopied] = useState(false);

    const [data, setData] = useState({
        'UserNameFirst': '',
        'UserNameLast': '',
        'UserNameLast_DByte': '',
        'UserNameFirst_DByte': '',
        'MailID': '',
        'DOB': '',
        'Nationality': '',
        'PhoneNumber1': '',
        'WalletID': '',
        'BtcWalletID': '',
    });

    const copyWalletAddress = () => {
        resetTimout();
        setIsWalletAddressCopied(false);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(data?.WalletID);
            setIsWalletAddressCopied(true);
        }
        else {
            alert(data?.WalletID);
        }
    };

    const copyBtcWalletAddress = () => {
        resetTimout();
        setIsWalletAddressCopied(false);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(data?.BtcWalletID);
            setIsWalletAddressCopied(true);
        }
        else {
            alert(data?.BtcWalletID);
        }
    };

    useEffect(() => {
        //actionGetUserProfile request send from Navbar
    }, []);

    useEffect(() => {
        //console.log('Hello v '+props?.users[0]?.WalletId);
        if (props?.users[0]?.KycStatus === null || props?.users[0]?.KycStatus.length === 0) {
            props.users[0].KycStatus = "In-Progress";
        }
        setData(props?.users[0]);
    }, [props.users]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-WalletAddress')}</h2>

            {/* .tab-panel wallet */}
            <div className="tab-pane" id="wallet-address">
                <form action="#">
                    {/* .row */}
                    <div className="gaps-1x" />
                    <div className="input-item input-with-label">
                        <TextField defaultValue={data?.WalletID} value={data?.WalletID} id="tocken-address" label={translate('wallet-address')} className='input-bordered' />
                    </div>
                    <div align='right'>
                        <button className="btn-primary" onClick={() => copyWalletAddress()}>{translate('Copy-to-clipboard')}</button>
                    </div>
                    <p>
                        {translate('wallet-address-message')}
                    </p>
                    <div className="gaps-1x" />

                    {data?.BtcWalletID &&
                        <>
                            <div className="gaps-1x" />
                            <div className="input-item input-with-label">
                                <TextField defaultValue={data?.BtcWalletID} value={data?.BtcWalletID} id="tocken-address" label={translate('bitcoin-wallet-address')} className='input-bordered' />
                            </div>
                            <div align='right'>
                                <button className="btn-primary" onClick={() => copyBtcWalletAddress()}>{translate('Copy-to-clipboard')}</button>
                            </div>
                            <p>
                                {translate('bitcoin-wallet-address-message')}
                            </p>
                            <div className="gaps-1x" />
                        </>
                    }

                    {/* .input-item */}
                    <div className="gaps-1x" />
                    {/* 10px gap */}
                    <div className="d-sm-flex justify-content-between align-items-center">
                        <div className="gaps-2x d-sm-none" />
                        <span className="color-success">
                            {isWalletAddressCopied &&
                                <div>
                                    <em className="ti ti-check-box" /> {translate('wallet-copied-message')}
                                </div>
                            }
                        </span>
                    </div>
                </form>
                {/* form */}
            </div>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        users: state.remote.users
    };
};

export default connect(mapStateToProps, {
})(WalletAddress);
