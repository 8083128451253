import React from 'react';
import translate from "../i18n/translate";

const App_Environment = process.env.REACT_APP_ENVIRONMENT;

const Kyc = () => {
    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Identify-Verification-KYC')}</h2>
            <p>
                {translate('Identify-Verification-KYC-description')}
            </p>
            <div className="gaps-2x" />
            <div className="status status-empty">
                <div className="status-icon">
                    <em className="ti ti-files" />
                    <div className="status-icon-sm">
                        <em className="ti ti-close" />
                    </div>
                </div>
                <span className="status-text">
                    {translate('Not-submitted-message')}
                </span>
                {App_Environment === "1" &&
                    <a href="/kyc-application" className="btn btn-primary">
                        {translate('CLICK-TO-PROCEED')}
                    </a>
                }
                {App_Environment === "2" &&
                    <a href="/demo-kyc-application" className="btn btn-primary">
                        {translate('CLICK-TO-PROCEED')}
                    </a>
                }
            </div>
            <div className="note note-md note-info note-plane">
                <em className="fas fa-info-circle" />
                <p>
                    {translate('footer-message')}
                </p>
            </div>
        </div>
    );
};

export default Kyc;
