import { LOCALES } from '../constants';

export default {
    [LOCALES.JAPANESE]: {
        //Menu
        'Menu-Top': 'ホーム',
        'Menu-BuyTokens': 'トークンを買う',
        'Menu-IVAFO': 'IVFAO',
        'Menu-OTCBuy-Open': 'OTC 買',
        'Menu-OTCBuy-Settlement': 'OTC 買',
        'Menu-OTCSell-Open': 'OTC 売',
        'Menu-OTCSell-Settlement': 'OTC 売',
        'Menu-OTCList': 'OTC注文一覧',
        'Menu-Transactions': '取引履歴',
        'Menu-DepositWithdraw': '入出金',
        'Menu-Deposit': '入金',
        'Menu-Withdraw': '出金依頼',
        'Menu-Statement': '入出金履歴',
        'Menu-Account': 'アカウント管理',
        'Menu-KYCApplication': '本人確認情報提出',
        'Menu-AccountDetail': '登録情報確認・変更',
        'Menu-WithdrawBankAccount': '出金先口座確認・変更',
        'Menu-ChangePassword': 'ログインパスワード変更',
        'Menu-Notification': '通知設定',
        'Menu-WalletAddress': 'ウォレットアドレス',
        'Menu-ReferralCode': '紹介コード',
        'Menu-Executions': '決済取引履歴',
        'Title-Deposit': '入金先口座',
        'Menu-Bitcoin-Transfer': 'ビットコイン送金',
        'Menu-BitCoinApplication': 'ビットコインウォレット申請',
        'Title-WithdrawAccount': '出金先口座情報',
        'Title-PromoterApplication': 'プログレス アソシエーター申請フォーム',
        'Title-PromoterCoupon': '紹介コード登録フォーム',
        'Title-SellTokens-Settlement': 'OTC買う注文一覧',

        //Signup
        'Signup-Title': '新しいアカウント',
        'Name': '名前',
        'EmailAddress': 'Eメール',
        'Password': 'パスワード',
        'RepeatPassword': '確認用パスワード',
        'PromotionalCode': 'プロモーションコード',
        'SignUp': 'サインアップ',
        'Login': 'ログイン',
        'AlreadyMember': 'Already a member?',
        'SignUpSuccess-Message1': 'お申込みいただきありがとうございます。',
        'SignUpSuccess-Message2': 'ご入力いただきましたメールアドレスにアカウント登録用URLを送信いたしました。登録用URLをクリックしメールアドレスの認証をしてください。',

        //home.js
        'Current-Transaction-Price': '現在の取引価格',
        'The-Day-Before': '前日比',
        'Last-Value-At': '前日終値',
        'AOS-Token-Balance': '保有AOS数量',
        'BTC-Token-Balance': '保有ビットコイン数量',
        'bids-list': 'OTC売り注文一覧',
        'OtcOrder-List': 'OTC注文一覧',
        'Go-to-main-site': 'ホーム',
        'Your-Contribution': '売出価格',
        'Confirm-Email': 'e-mail \n 確認',
        'KYC-Pending': '本人確認 \n ペンディング中 ',
        'KYC-Success': 'KYC 完了',
        'KYC-Application': '本人確認資料提出手順',
        'Contributions': 'AOS 購入',
        'Contributions-Sell': 'AOS 売却',
        'OTC-Sell': 'OTC 売却',
        'Transactions': 'トランザクション',
        'Account': 'アカウント',
        'Security': 'セキュリティ',
        'Referral': '紹介',
        'Account-Security': 'セキュリティ',
        'How-to-Buy?': '購入方法',
        'FAQs': 'よくある質問',
        'Whitepaper': 'ホワイトペーパー',
        'Contact-support': 'お問い合わせ',
        'START-DATE': '開始日',
        'END-DATE': '終了日',
        'max-aos': 'AOS 最大割り当て数量',
        'AOS-Crypto-Projects-message': 'AOS 暗号資産プロジェクトに関心をお持ち頂きありがとうございます。',
        'Contributions-section-message': ' ',
        'Contributions-section-message-1': 'AOS購入画面 ',
        'Contributions-section-message-2': 'からご購入頂くことができます。',
        'details-message': 'ご質問は ',
        'details-message-1': 'こちら',
        'marketing-message': ' ',
        'THE-AOS-ICO-ENDS-IN': 'AOS ICO 終了日',
        'Token-Sale-Progress': '募集進捗状況',
        'Bids': 'OTC売り注文一覧',
        'Balance': '残高',
        'My-Profile': 'プロフィール',
        'activity': '活動',
        'Logout': 'ログアウト',

        //kyc.js
        'Identify-Verification-KYC': '本人確認資料',
        'Identify-Verification-KYC-description': '当局規制に基づき、各参加者は本人確認手続き（KYC）を行う必要があります。トークンを購入するには、以下の検証プロセスを完了してください。ここから、本人確認手続きを実行できます。また、すでに本人確認資料を提出済の場合は、進捗状況を確認することもできます。',
        'Not-submitted-message': '本人確認資料未提出',
        'CLICK-TO-PROCEED': '本人確認資料を提出',
        'footer-message': '一部の国や地域の本人確認資料の場合、KYCプロセスに合格できずトークン購入が制限される場合があります。',

        //KycApplication.js
        'Kyc-PersonalDetails-Edit': '登録情報確認・変更',
        'Kyc-PersonalDetails': '基本情報',
        'UserID': 'ユーザーID',
        'First-Name': '名（ローマ字）',
        'Last-Name': '姓（ローマ字）',
        'Kanji-First-Name': '名（漢字）',
        'Kanji-Last-Name': '姓（漢字）',
        'Email-Address': 'メールアドレス',
        'Phone-Number': '電話番号',
        'Date-of-birth': '生年月日',
        'Nationality': '国籍',
        'Address-Line-1': '住所　ローマ字　（都道府県、市区町村）',
        'Address-Line-2': '住所　ローマ字　（町名番地、建物名、号室）',
        'Kanji-Address-Line-1': '漢字住所（都道府県、市区町村）',
        'Kanji-Address-Line-2': '漢字住所（町名番地、建物名、号室）',
        'Zip-Code': '郵便番号',
        'country-of-residence': '居住国',
        'Kyc-VerificationID-Edit': '本人確認資料・変更',
        'documents-details': '以下のいずれかの本人確認資料をアップロードしてください',
        'Passport': 'パスポート',
        'Passport-Back': 'パスポート裏面',
        'National-Card': 'マイナンバーカード',
        'National-Card-Back': 'マイナンバーカード裏面',
        'Drivers-License': '運転免許証',
        'Drivers-License-Back': '運転免許証裏面',
        'Photo-1': '正面からの写真',
        'Photo-2': '斜め前からの写真',
        'Photos': 'Photo',
        'Photo-message': '正面からの写真と、斜め前からの写真をアップロードしてください。',
        'documents-details-1': 'スムーズに本人確認を進める為、以下にご注意ください。',
        'documents-details-2': '書類が有効期限内であること',
        'documents-details-3': '書類がはっきり見えやすいこと',
        'documents-details-4': '光の反射がないこと',
        'documents-details-5': 'マイナンバーカード表面',
        'documents-details-6': 'マイナンバーカード裏面',
        'documents-details-7': '顔写真をアップロードしてください。正面から1枚、斜め横から1枚の２枚必要です。いずれも、6ヵ月以内に撮影され、無帽で背景なし、目の周辺に髪の毛や眼鏡などの影のない写真をお願いします。',
        'submit-details': '提出',
        'Change-Request': '変更依頼',
        'Change-Request-Message1': '登録情報の変更後は、再度ご本人様確認の審査が必要です。',
        'Change-Request-Message2': '審査が完了するまで全ての取引機能が停止しますのでご注意ください。',
        'Agree-Message': '上記内容に同意します',

        //Buttons
        'kyc-button-next': '次へ',
        'kyc-button-prev': '戻る',
        'kyc-button-revise': '修正する',
        'button-All': 'すべて',
        'button-Clear': 'クリア',
        'button-AddAccount': '口座を追加',
        'button-DepositRequest': '入金依頼',
        'button-Deposit': '入金する',
        'button-Cancel': 'キャンセル',
        'button-Apply': '申請',
        'button-Register': '登録',
        'button-Ok': 'Ok',

        //transactions.js
        'TransactionNo': '取引番号',
        'TransactionStatus': '取引状況',
        'Buy-Sell': '売買',
        'Tokens': '数量',
        'UnitPrice': 'レート',
        'Amount': '金額',
        'Transaction-Buy': '買',
        'Transaction-Sell': '売',
        'Transaction-Gift': '譲渡',
        'Transaction-Bonus': 'ボーナス',
        'From': '相手方 or カウンターパーティー',
        'To': '相手方',
        'From-bids': '販売者',
        'Txn-Completed': '取引完了',
        'Txn-Pending': '保留中',
        'Txn-Progress': '取引中',
        'Txn-Cancelled': 'キャンセル',
        'Txn-Declined': '取引不成立',
        'Txn-Expired': '期限切れ',
        'Txn-Error': 'エラー',
        'Quantity': '数量',
        'buy-quantity': '購入希望数量',
        'buy-price': '購入希望価格',
        'Buy-Order': '買い注文',
        'Sell-Order': '売り注文',
        'Buy': '買い',
        'Sell': '売り',
        'DateTime': '日時（MLT）',
        'Date': '日付',
        'TransferNote': '備考',
        'Euro': 'EUR',
        'Bitcoin': 'BTC',
        'Euro-Aos': 'EUR／AOS',
        'Bitcoin-Aos': 'BTC／AOS',
        'Sell-Amount': '売出価額',
        'Sell-Quantity': '売出枚数',
        'Commission-Amount': '手数料',
        'Commission': '手数料',
        'Currency-Euro': 'ユーロ',
        'GasFee': 'ガス料金',
        'Currency-Bitcoin': 'ビットコイン',
        'Commission-Payment-Type': '手数料支払方法',

        //Account.js
        'Account-Information': 'アカウント情報',
        'Confirm-message': 'メールアドレス確認',
        'Personal-Data': '個人情報',
        'Full-Name': 'Full Name',
        'Surname': '姓',
        'Mobile-Number': '携帯電話番号',
        'Date-of-Birth': '生年月日',
        'Resend-Email': 'e-mail 再送',
        'Update': '更新',
        'Copy-to-clipboard': 'コピー',
        'Wallet-Address': 'ウォレットアドレス',
        'Notification': '通知',
        'notification-header': '受信メールの制御',
        'Notify-latest': '最新のニュースメールを受信する。',
        'notification-footer-note': 'この画面の設定に関らず、セキュリティ関連の通知は常に送信されます。',
        'saved-message': 'ウォレットアドレスを保存完了',
        'wallet-copied-message': 'ウォレットアドレスをクリップボードにコピーされました',
        'update-notification-settings': '通知設定の更新完了',
        'wallet-address': 'AOSウォレットアドレス',
        'wallet-address-message': 'AOSトークンを受け取るためのウォレットアドレスです。',
        'bitcoin-wallet-address': 'ビットコインウォレットアドレス',
        'bitcoin-wallet-address-message': 'ビットコインを受け取るためのウォレットアドレスです。',
        'coupon-address': '紹介URL',
        'coupon-code': '紹介コード',
        'coupon-code-copied-message': '紹介コードをクリップボードにコピーされました',
        'coupon-address-copied-message': '紹介URLをクリップボードにコピーされました',
        'wallet-message': 'AOSトークンを受け取るウォレットのアドレスを入力してください。トークン販売が終了した時点で、下記に指定されたアドレスでICOXトークンを受け取ることができます。',
        'wallet-warning': 'Kraken, Bitfinex, Bithumb, Binanceなどの交換ワレットアドレスは使用しないでください。MetaMask, MayEtherWallet, Mist walletsなどは使用可能です。プライベートキーをお持ちでないアドレスは使用しないでください。ICOXトークンが受け取れず出資した資金を失うことになります。',
        'passport-expirydate': 'パスポート有効期限',
        'national-expirydate': 'マイナンバーカード有効期限',
        'license-expirydate': '運転免許証有効期限',
        'passport-Number': 'パスポート番号',
        'national-Number': 'マイナンバー番号',
        'license-Number': '運転免許証番号',
        'referralcode-message': '紹介する方へ紹介コードまたは紹介URLを共有してください。',
        'referralcode-title': '【紹介プログラムに係る注意点】',
        'referralcode-details': '注意点の文章は要検討',
        'referralbonus-title': '【ボーナス付与に係る注意点】',
        'referralbonus-details': '注意点の文章は要検討',

        //HowTo.js
        'How-to-Buy-token': 'トークン購入方法',
        'how-to-step-1': 'ステップ 1 : まず「本人確認資料提出手順」画面から個人情報を入力し本人確認資料をご提出ください。',
        'how-to-step-2': 'ステップ 2 : 当方で本人確認資料の確認が終わると、ステータス表示（PCの場合は画面左上のボタン、スマートフォ ンの場合は右上隅のメニューから表示されるステータスボタン）が「本人確認ペンディング中」から「 本人確認完了」に変わります。本人確認資料のご提出から確認作業完了までには、通常〇〇日程度かか ります。←',
        'how-to-step-3': 'ステップ 3 : 本人確認手続き完了次第、ご登録メールアドレス宛にメールでご連絡します。本人確認手続きが完了すると、「拠出」画面に、拠出金の送金先口座の詳細が表示されます。',
        'how-to-step-4': 'ステップ 4 : 拠出をご希望されるAOSのめやす数量を入力してください。めやすとなるEUR金額が計算されます。為 替相場の変動や、送金銀行・中継銀行・受取人取引銀行によって手数料が差し引かれるため、送金金額 全額は送金先口座に届きません。従って、表示されたAOS数量はあくまでもめやすにすぎず、拠出は、 当方に実際に到着したEUR金額相当のAOS数量となりますのでご承知おきください。',
        'how-to-step-5': 'ステップ 5 : 画面に表示されたEUR金額を、お取引銀行から外国送金としてご送金ください。その他、お引き落とし口座など、お取引銀行の書式に従ってください。外国送金依頼書をお取引銀行に提出後、銀行から返却された送金依頼人控のPDFまたは画像ファイルを 撮り、「拠出」画面内の所定のボックスからアップロードしてください。',
        'how-to-step-6': 'ステップ 6 : クレジットカードによるお支払いをご希望の場合は、「クレジットカードでトークンを購入」ボタンを クリックしてください。カード決済会社の画面にジャンプします。',
        'how-to-step-7': 'ステップ 7 : 送金到着次第、拠出手続きを行います。拠出状況についてはダッシュボード画面からご確認頂けます。 同時に、ご登録メールアドレス宛にメールでご連絡します。',

        //SecuritySettings
        'password-eligibility-message': 'パスワードは最低8文字以上で、アルファベット小文字、大文字、数字、特殊文字が最低1つ以上含まれる必要があります。',
        'settings-message': 'パスワードを変更することによりアカウントを保護できます。',
        'Change-Password': 'パスワード変更',
        'Old-Password': '旧パスワード',
        'New-Password': '新パスワード',
        'Confirm-Password': '新パスワード再入力',
        'Password': 'パスワード',
        'OTP': 'ワンタイムパスワード',

        //token
        'howtomessage': '画面に表示されたEUR金額を、お取引銀行から外国送金としてご送金ください。 外国送金依頼書の各項目には以下のとおりご記載ください。（送金銀行によって多少書式が異なりますが、以下をご参照ください。）',
        'token-line-1': 'ご依頼人の英文名 :',
        'token-line-2': 'ご依頼人の英文住所 :',
        'token-line-3': '受取人の英文名　：Gatsby Malta, Ltd.',
        'token-line-4': '受取人の英文住所：Piketree Business Centre, Suite 3, Industry Street, Zone 5,',
        'token-line-5': 'Central Business District, Santa Vanera CBD 5030, Malta',
        'token-line-6': '受取人口座番号＋IBAN：XXXXXXXX',
        'token-line-7': '受取人取引銀行：XXX',
        'token-line-8': '受取人取引銀行住所：Address',
        'token-line-9': '受取人取引銀行銀行コード(SWIFT Code)：XXXXXXXX',
        'token-line-10': 'ご送金目的：ICO contribution of Crypto-asset',
        'token-line-11': 'ご送金通貨：EUR',
        'token-line-12': 'ご送金金額：',
        'token-line-13': '受取人宛てメッセージ：',
        'token-line-14': '支払銀行手数料：スムーズな送金の為、送金受取人負担としてください。その他、お引き落とし口座など、お取引銀行の書式に従ってください。',
        'Buy-Tokens': 'トークンの購入',
        'Sell-Tokens': 'トークンを売る',
        'Gift-Tokens': 'トークンを送る',
        'token-step-01': '01. 支払い方法の選択とトークン価格の計算',
        'token-step-02': '02. 購入を希望される AOS トークンの数量を設定してください',
        'token-step-02-sell': '02. 売却を希望される AOS トークンの数量を設定してください',
        'token-step-02-desc': 'AOS暗号資産プロジェクトに参加してAOSトークンを購入するには、購入資金の支払と承認が必要です。私たちのプロジェクトへの参加を希望される場合は、支払方法を選択し、購入ご希望のAOSトークン数量を入力してください。AOSトークンの購入はEURO建てとなります。',
        'token-step-02-desc-1': 'AOSトークン数量とEURO金額の換算',
        'token-step-03': '03. お支払い方法を選択',
        'Gift': '譲渡',
        'Tokens-to-Purchase': '購入する トークン',
        'Order-Quantity': '注文数',
        'Payment-Amount': '支払額',
        'Select-Wallet': 'ウォレット選択',
        'Enter-Address': '受け取り先ウォレットアドレスをご記入ください',
        'Enter-Bitcoin-Address': '受け取り先ビットコインアドレスをご記入ください',
        'Payment-Qty': 'Payment Qty',
        'insufficient-aos-balance': '入力したトークン数は、現在のIVFAOで購入可能な数を超えています。現在のIVFAOで残っているAOSトークンのみが付与され、残りの注文は次回のIVFAOで付与されます。',
        'Partial-Order': '分割購入',
        'public-purchase-price': '公開買取価格',
        'To-Confirm-Page': '確認画面へ',
        'Rate': 'レート',
        'Payment-Type': '支払方法',
        'WalletAddress': '受取先ウォレットアドレス',
        'Back-Btn': '戻る',
        'SenderAccount': '送金元金融機関',
        'Select-SenderAccount': '送金元口座を選択',

        //Cash Balance
        'Payment-By-WalletBalance': 'ウォレット残高',
        'Payment-By-Cash': '残高',
        'Payment-By-CreditCard': 'クレジットカード',
        'Cash-Balance': 'AOSウォレット残高',
        'Bitcoin-Balance': 'ビットコイン残高',
        'WithdrawRequest': '出金依頼',
        'WithdrawSuccess': '出金依頼が正常に受付ました',
        'CashTransactions': '入出金一覧',
        'TransactionType': '入出金区分',
        'CreatedDate': '依頼日',
        'ProcessedDate': '処理日',
        'Status': 'ステータス',
        'Created': '受付',
        'Cancelled': '取消',
        'Processed': '完了',
        'Deposit': '入金',
        'Withdraw': '出金',
        'Filters': 'フィルター',
        'Search': '検索',
        'Currency': '通貨',
        'WithdrawAccount': '出金先口座',
        'WithdrawAmount': '出金額',
        'MinimumAmount': '最小数量',
        'WithdrawPossibleAmount': '出金可能額',
        'WithdrawBankAccount': '出金先口座',
        'Select-BankAccount-Message': '出金先口座を選択してください',
        'WithdrawDeclaration': '同意事項',
        'WithdrawDeclareButton': '確約します',
        'WithdrawDeclare-Message': 'EUの法令に基づく制裁措置の対象の出金に該当しません',
        'DepositRequest': '入金依頼',
        'DepositAmount': '入金額',

        //Bank Account
        'BankAccount': '銀行口座',
        'BankName': '銀行名',
        'BranchName': '支店名',
        'IBAN': 'IBAN',
        'BankAddress': '銀行住所',
        'AccountNo': '口座番号',
        'AccountName': '口座名',
        'WithdrawAccountName': '口座名義人',
        'AccountAddress': '口座住所',
        'SwiftCode': 'SWIFT',
        'SenderName': '振込依頼人名',
        'SenderName-Note': '必ずご本人様名義の金融機関口座をご利用ください。',
        'Deposit-Message1': '振込依頼人名は、必ず当社ご登録口座のお客様名義でお振込みをお願いいたします。',
        'Deposit-Message2': 'ご本人様名義以外の金融機関口座からのご入金は承れません。',
        'Deposit-Message3': '振込手数料は、お客様のご負担となりますのでご了承ください。',
        'Submit': '確定',
        'LastDeposit': '最終入金日',
        'WithdrawBankAccount_Message1': 'ウォレット残高は、入金履歴がある口座にのみ出金可能です。',
        'WithdrawBankAccount_Message2': '入金があった口座は、一覧で表示され希望の出金先口座を選択できます。',
        'WithdrawBankAccount_Message3': 'クレジットカードでトークンを購入された方も、一度１ユーロ以上の入金がないと金融機関への出金はできません。',

        'PartialOrder-Question': '一部約定(注文の一部のみ約定)を了承しますか？',
        'Radio-Option-Yes': 'はい',
        'Radio-Option-No': 'いいえ',
        'order-options-note': '入力したトークン数は、現在のIVFAOで購入可能な数を超えています。 以下のいずれかを選択してください。',
        'cancel-order': '注文をキャンセル',

        //Credit-Card
        'CreditCardPayment': 'クレジットカード支払',
        'CardNo': 'カード番号',
        'CardSecretCode': 'CVVコード',
        'ExpiryMonth': '有効期間',
        'CardHolderName': 'クレジットカード名義人氏名',

        //Promoter
        'Recommender': '推薦者',
        'Promoter-Agree-Message': '利用規約に同意する',
        'Promoter-Application-Message1': '本プログラムを利用するにあたり以下の利用規約をご確認ください。',
        'Promoter-Application-Message2': '【本プログラムに係る利用規約】',
        'Promoter-Application-Message3': '改訂中',
        'Promoter-Application-Submitted-Message': 'プログレス アソシエーター申請が正常に送信されました。',
        'Promoter-Application-AlreadySubmitted-Message': 'プログレス アソシエーター申請はすでに提出されています。',
        'Promoter-Coupon-Rule': '紹介コードは4～6文字でアルファベットと数字の両方を含む必要があります',
        'Promoter-Application-Approved-Message': 'プログレス アソシエーター申請が承認されました。',
        'Promoter-Application-Rejected-Message': 'プログレス アソシエーター申請が却下されました。',
        'Promoter-Coupon-Setup-Message': 'こちらからプログレス アソシエーターの紹介コードを設定してください。',
        'Promoter-Coupon-Registered-Message': '紹介コード登録は正常に登録されました。',
        'DonotShowAgain-Message': '今後は表示しない',
        'Promoter-DontShowAgain-Message': '今後はログイン時にこの画面は表示されません。プログレス アソシエーターコードは、紹介コードのメニューから設定できます。',

        //BitCoin
        'BitCoin-Agree-Message': '利用規約に同意する',
        'BitCoin-Application-Message1': 'ビットコインウォレットを利用するにあたり以下の利用規約をご確認ください。',
        'BitCoin-Application-Submitted-Message': 'ビットコインウォレット申請が正常に送信されました。',
        'BitCoin-Application-AlreadySubmitted-Message': 'ビットコインウォレット申請はすでに提出されています。',
        'BitCoin-Wallet-Created-Message': 'ビットコインウォレット登録は正常に登録されました。',

        //Demo
        'Demo-KYC-Application': '本人確認資料提出手順',
        'kyc-demo-message1': 'この入力画面はデモ用です。',
        'kyc-demo-message2': '実際の取引にはすべての項目を入力していただきます。',
        'CreditCard-DemoMessage': '以下のカード詳細のいずれかを使用してください。',
        'Demo-DepositAccount-message': 'この口座はデモ用の表記です。実際のお振込先ではございません。',

        //Error
        'ServerError': 'エラー！ カスタマーサポートに連絡してください'
    },
};
