import React from 'react';

const KycStatus = () => {
    return (
        <div className="user-panel">
            <h2 className="user-panel-title">Status - KYC</h2>
            <p>
                To comply with regulation each participant will have to go through indentity
                verification (KYC). So please complete our fast and secure verification
                process to participate in our token sale. You can proceed from here to
                verify your indentity and also you can check your application status if you
                submit already.{" "}
            </p>
            <div className="gaps-2x"/>
            <div className="status status-thank">
                <div className="status-icon">
                    <em className="ti ti-files"/>
                    <div className="status-icon-sm">
                        <em className="ti ti-alarm-clock"/>
                    </div>
                </div>
                <span className="status-text">
      Thank you! You have completed the process for your Identity verification.
    </span>
                <p>
                    We are still waiting for your identity verification. Once our team
                    verified your indentity, you will be whitelisted and notified by email.
                </p>
            </div>
            {/* .status */}
            <div className="status status-process">
                <div className="status-icon">
                    <em className="ti ti-files"/>
                    <div className="status-icon-sm">
                        <em className="ti ti-alarm-clock"/>
                    </div>
                </div>
                <span className="status-text">
      Your Application under Process for Varification.
    </span>
                <p>
                    We are still working on your identity verification. Once our team verified
                    your indentity, you will be whitelisted and notified by email.
                </p>
            </div>
            {/* .status */}
            <div className="status status-canceled">
                <div className="status-icon">
                    <em className="ti ti-files"/>
                    <div className="status-icon-sm">
                        <em className="ti ti-close"/>
                    </div>
                </div>
                <span className="status-text">Your application rejected by admin.</span>
                <p>
                    In our verification process, we found information incurrect. It would
                    great if you resubmit the form. If face problem in submission please
                    contact us with support team.
                </p>
                <a href="kyc-application.html" className="btn btn-primary">
                    Resubmit
                </a>
            </div>
            {/* .status */}
            <div className="status status-warnning">
                <div className="status-icon">
                    <em className="ti ti-files"/>
                    <div className="status-icon-sm">
                        <em className="ti ti-alert"/>
                    </div>
                </div>
                <span className="status-text">
      We found some information missing in application.
    </span>
                <p>
                    In our verification process, we found information are missing. It would
                    great if you resubmit the form. If face problem in submission please
                    contact us with support team.
                </p>
                <a href="kyc-application.html" className="btn btn-primary">
                    Submit Again
                </a>
            </div>
            {/* .status */}
            <div className="status status-verified">
                <div className="status-icon">
                    <em className="ti ti-files"/>
                    <div className="status-icon-sm">
                        <em className="ti ti-check"/>
                    </div>
                </div>
                <span className="status-text">Your Identity Verified.</span>
                <p>
                    One fo our team verified your indentity.{" "}
                    <br className="d-none d-md-block"/>
                    You are now in whitelisted for token sale.
                </p>
                <div className="gaps-2x"/>
                <a href="kyc-application.html" className="ucap">
                    Resubmit the application
                </a>
            </div>
            {/* .status */}
            <div className="note note-md note-info note-plane">
                <em className="fas fa-info-circle"/>
                <p>
                    Some of contries and regions will not able to pass KYC process and
                    therefore are restricted from token sale.
                </p>
            </div>
        </div>
    );
};

export default KycStatus;
