import React, { useState } from 'react';
import { updateUserPassword } from "./common/Firebase";
import { TextField } from '@mui/material';
import translate from "../i18n/translate";
import Button from '@mui/material/Button';
import connect from "react-redux/es/components/connect";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const SecuritySetting = (props) => {
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Change-Password')}</h2>
            <p>
                {translate('settings-message')}
            </p>
            <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#password-opt">
                        {translate('Change-Password')}
                    </a>
                </li>
            </ul>
            {/* .nav-tabs-line */}
            <div className="tab-content" id="security-opt-tab">
                {/* .tab-pane */}
                <div className="tab-pane active show" id="password-opt">
                    <form action="#">
                        {/* .row */}
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="input-item input-with-label">
                                    <TextField id="old-password" type={'password'} label={translate('Old-Password')} className='input-bordered' onChange={(e) => setOldPassword(e.target.value)} />
                                </div>
                                {/* .input-item */}
                            </div>
                            {/* .col */}
                        </div>

                        {/* .row */}
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="input-item input-with-label">
                                    <TextField id="new-password" type={'password'} label={translate('New-Password')} className='input-bordered' onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                {/* .input-item */}
                            </div>
                            {/* .col */}
                        </div>

                        {/* .row */}
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="input-item input-with-label">
                                    <TextField id="confirm-password" type={'password'} label={translate('Confirm-Password')} onChange={(e) => setPasswordConfirm(e.target.value)} className='input-bordered' />
                                </div>
                            </div>
                        </div>

                        <div className="note note-plane note-info">
                            <em className="fas fa-info-circle" />
                            <p>
                                {translate('password-eligibility-message')}
                            </p>
                        </div>
                        <div className="gaps-3x" />
                        <div className="gaps-1x" />
                        {/* 10px gap */}
                        <div className="d-sm-flex justify-content-between align-items-center">
                            <Button variant="contained" size='large' onClick={() => (password !== oldPassword && password === passwordConfirm) ? updateUserPassword(password) : alert('Password does not match.')}>
                                {translate('Update')}</Button>
                            <div className="gaps-2x d-sm-none" />
                        </div>
                    </form>
                    {/* form */}
                </div>
                {/* .tab-pane */}
            </div>
            {/* .tab-content */}
        </div>
    );
};
const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
    };
};
export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter
})(SecuritySetting);
